import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className='d-flex justify-content-center mt-5' style={{width:'100%'}}>
        
        ACCESSS DINIED
        <Link to='/login'><div className='btn btn-primary'>Go to Login Page</div></Link>
    </div>
  )
}

export default NotFound