import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import CoverDetail from "./CoverDetail";
const DeepDetail = () => {
  const [clicked, setClicked] = useState(false);
  const messageboxRef = useRef(null);
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (messageboxRef.current && !messageboxRef.current.contains(event.target)) {
        setClicked(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleAddEmoji = (emoji) => {
    setTextAreaValue(textAreaValue + emoji);
  };

  const emojis = ['😊', '😂', '😍', '🎉', '👍', "✌", "😂", "😝", "😁", "😱", "👉", "🙌", "🍻", "🔥", "🌈", "☀", "🎈", "🌹", "💄", "🎀", "⚽", "🎾", "🏁", "😡", "👿", "🐻", "🐶", "🐬", "🐟", "🍀", "👀", "🚗", "🍎", "💝", "💙", "👌", "❤", "😍", "😉", "😓", "😳", "💪", "💩", "🍸", "🔑", "💖", "🌟", "🎉", "🌺", "🎶", "👠", "🏈", "⚾", "🏆", "👽", "💀", "🐵", "🐮", "🐩", "🐎", "💣", "👃", "👂", "🍓", "💘", "💜", "👊", "💋", "😘", "😜", "😵", "🙏", "👋", "🚽", "💃", "💎", "🚀", "🌙", "🎁", "⛄", "🌊", "⛵", "🏀", "🎱", "💰", "👶", "👸", "🐰", "🐷", "🐍", "🐫", "🔫", "👄", "🚲", "🍉", "💛", "💚"]; // Add more emojis as needed

  const handleEmojiClick = (emoji) => {
    setSelectedEmoji(emoji);
    setTextAreaValue(textAreaValue + emoji);
    setShowModal(false);
  };
  const [comment, setComment] = useState([
    { name: "Matheus", date: "12/03/2023", mention: "Lucas", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis " },
    { name: "Matheus", date: "12/03/2023", mention: "Lucas", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis " },
    { name: "Matheus", date: "12/03/2023", mention: "Lucas", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis " },
    { name: "Matheus", date: "12/03/2023", mention: "Lucas", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis " },
    { name: "Matheus", date: "12/03/2023", mention: "Lucas", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis " },
  ]);
  const handleDelete = (index) => {
    const updatedComment = [...comment];
    updatedComment.splice(index, 1);
    setComment(updatedComment);
  };

  const handlePublish = () => {
    const currentDate = new Date().toLocaleDateString();
    const newComment = {
      name: "Saad",
      date: currentDate,
      mention: "Mirza",
      text: textAreaValue
    };
    setComment([...comment, newComment]);
    setTextAreaValue(''); // Clear textarea after publishing
  };




  return (
    <>
     <CoverDetail/>

      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Comments</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div
            ref={messageboxRef}
            className="messagebox"
            style={{
              border: clicked ? '2px solid #0C94E4' : '2px solid #DFE1E2',
              cursor: 'pointer'
            }}
            onClick={handleClick}
          >
            <div className='greyarea'></div>
            <textarea
              onChange={(e) => setTextAreaValue(e.target.value)}
              className='textareaforcomment'
              placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
              cols="46"
              rows="3"
              value={textAreaValue} // Set the value of textarea
            ></textarea>
            <div className='attachitems'>
              <div className='attachicons'>
                <div className='addfile'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.10923 5.36768L7.10923 11.3357C7.10923 11.5618 7.19905 11.7787 7.35894 11.9386C7.51883 12.0985 7.73568 12.1883 7.9618 12.1883C8.18792 12.1883 8.40477 12.0985 8.56466 11.9386C8.72455 11.7787 8.81438 11.5618 8.81438 11.3357L8.81438 3.66252C8.81438 2.746 8.05239 2 7.10923 2C6.16606 2 5.40407 2.746 5.40407 3.66252L5.40407 11.4423C5.40407 12.1206 5.67355 12.7712 6.15321 13.2509C6.63288 13.7305 7.28345 14 7.9618 14C8.64015 14 9.29072 13.7305 9.77039 13.2509C10.2501 12.7712 10.5195 12.1206 10.5195 11.4423L10.5195 5.47425" stroke="#374147" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> Add file
                </div>
                <div className='addfile' onClick={() => setShowModal(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.75 7.5C6.16421 7.5 6.5 7.16421 6.5 6.75C6.5 6.33579 6.16421 6 5.75 6C5.33579 6 5 6.33579 5 6.75C5 7.16421 5.33579 7.5 5.75 7.5Z" fill="#374147" />
                    <path d="M10.25 7.5C10.6642 7.5 11 7.16421 11 6.75C11 6.33579 10.6642 6 10.25 6C9.83579 6 9.5 6.33579 9.5 6.75C9.5 7.16421 9.83579 7.5 10.25 7.5Z" fill="#374147" />
                    <path d="M10.6004 9.5C10.3361 9.95537 9.95679 10.3333 9.5005 10.5961C9.04421 10.8588 8.52692 10.9971 8.00039 10.9971C7.47386 10.9971 6.95657 10.8588 6.50028 10.5961C6.04399 10.3333 5.66471 9.95537 5.40039 9.5" stroke="#374147" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> Emoji

                </div>
                <div className='addfile'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.10923 5.36768L7.10923 11.3357C7.10923 11.5618 7.19905 11.7787 7.35894 11.9386C7.51883 12.0985 7.73568 12.1883 7.9618 12.1883C8.18792 12.1883 8.40477 12.0985 8.56466 11.9386C8.72455 11.7787 8.81438 11.5618 8.81438 11.3357L8.81438 3.66252C8.81438 2.746 8.05239 2 7.10923 2C6.16606 2 5.40407 2.746 5.40407 3.66252L5.40407 11.4423C5.40407 12.1206 5.67355 12.7712 6.15321 13.2509C6.63288 13.7305 7.28345 14 7.9618 14C8.64015 14 9.29072 13.7305 9.77039 13.2509C10.2501 12.7712 10.5195 12.1206 10.5195 11.4423L10.5195 5.47425" stroke="#374147" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> Mention
                </div>

              </div>
              <div className='publish' onClick={handlePublish}>
                Publish
              </div>

            </div>
            <hr className='margintop'></hr>
            {
              comment.slice().reverse().map((coment, index) => {
                const reversedIndex = comment.length - index - 1;
                return (
                  <>
                    <div className='namecontent'>
                      <div className='coverofname'>
                        <div className='profileimage'>
                          M
                        </div><span className='nameofperson'>
                          {coment.name} </span>
                      </div>
                      <div className='deletesection'>
                        <div className='dateofcomment'>
                          {coment.date}
                        </div>

                        <div className='deletebutton' onClick={() => handleDelete(reversedIndex)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.5 3.5H2.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>   Delete
                        </div>
                      </div>


                    </div>
                    <div className='mentionsection'>
                      {coment.mention}
                    </div>
                    <p className='comentdesc'>
                      {coment.text}<span className='mentionattest'>@{coment.mention}</span>
                    </p>
                    <hr></hr>
                  </>
                )

              })
            }
          </div>
        </div>
      </div>
      <Modal
        title={null}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        getContainer={() => document.getElementById('offcanvasRight')} // Mount modal inside offcanvas
      >
        <div className='card-header'>
          Select Emoji
        </div>
        <div className='emoji-popup p-3'>
          {emojis.map((emoji, index) => (
            <span
              key={index}
              onClick={() => handleEmojiClick(emoji)}
              className='emoji-item'
            >
              {emoji}
            </span>
          ))}
        </div>
      </Modal>
      <div className="offcanvas2">

      </div>

    </>
  )
}
export default DeepDetail;