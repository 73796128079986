import React from 'react'
import "./Client.css";
const ClientTable = ({clients,opendelete,handleEditClient,currentDate}) => {
    return (
        <>
            {
                clients.map((client, index) => {
                    return (
                        <>
                            <div className="allofall mt-2">
                                <div className="induvidual2">
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Lead:</span>
                                        <span className="tabledesc2"><u>{client.lead}</u></span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Comments:</span>
                                        <span className="tabledesc2"><u>0</u></span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Quote PDF:</span>
                                        <span className="tabledesc2">{client.lead}</span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">State:</span>
                                        <span className="bluebubble">{client.state}</span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Status:</span>
                                        <span className="bluebubble">{client.status}</span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Renewal date:</span>
                                        <span className="tabledesc2">{client.renewaldate}</span>
                                    </div>
                                    <div className="coverofclass">
                                        <span className="tabletitle2">Date added</span>
                                        <span className="tabledesc2">{currentDate}</span>
                                    </div>
                                    <div className="coverofclass mt-2">

                                        <div class="dropdown">
                                            <div style={{ cursor: "pointer" }} class="custom-dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z" fill="#374147" />
                                                    <path d="M16 9.5C16.8284 9.5 17.5 8.82843 17.5 8C17.5 7.17157 16.8284 6.5 16 6.5C15.1716 6.5 14.5 7.17157 14.5 8C14.5 8.82843 15.1716 9.5 16 9.5Z" fill="#374147" />
                                                    <path d="M16 25.5C16.8284 25.5 17.5 24.8284 17.5 24C17.5 23.1716 16.8284 22.5 16 22.5C15.1716 22.5 14.5 23.1716 14.5 24C14.5 24.8284 15.1716 25.5 16 25.5Z" fill="#374147" />
                                                </svg>
                                            </div>
                                            <ul class="dropdown-menu menuoptions" aria-labelledby="dropdownMenuButton1">
                                                <li><span class="dropdown-item dropitem"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M12 5.25C4.5 5.25 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C19.5 18.75 22.5 12 22.5 12C22.5 12 19.5 5.25 12 5.25Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>  View details</span></li>
                                                <li><span class="dropdown-item dropitem" onClick={() => handleEditClient(index)}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M9.00001 20.25H4.5C4.30109 20.25 4.11033 20.171 3.96967 20.0304C3.82902 19.8897 3.75 19.699 3.75 19.5V15.3094C3.74966 15.212 3.76853 15.1155 3.80553 15.0254C3.84253 14.9354 3.89694 14.8534 3.96563 14.7844L15.2156 3.53441C15.2854 3.46355 15.3686 3.40728 15.4603 3.36886C15.5521 3.33045 15.6505 3.31067 15.75 3.31067C15.8495 3.31067 15.9479 3.33045 16.0397 3.36886C16.1314 3.40728 16.2146 3.46355 16.2844 3.53441L20.4656 7.71566C20.5365 7.78545 20.5928 7.86864 20.6312 7.96038C20.6696 8.05212 20.6894 8.15058 20.6894 8.25004C20.6894 8.3495 20.6696 8.44796 20.6312 8.5397C20.5928 8.63144 20.5365 8.71463 20.4656 8.78441L9.00001 20.25Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M20.25 20.25H9" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.75 6L18 11.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>Edit client</span></li>
                                                <li><span class="dropdown-item dropitem" onClick={() => opendelete(index)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M20.25 5.25H3.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.75 9.75V15.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.25 9.75V15.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.75 5.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H9.75C9.35218 2.25 8.97064 2.40804 8.68934 2.68934C8.40804 2.97064 8.25 3.35218 8.25 3.75V5.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>Delete client</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )
                })
            }



        </>
    )
}

export default ClientTable