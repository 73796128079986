import "./Client.css";
import clientimage from "../../Images/Create-client.png";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { Radio } from 'antd';
import PortfolioModal from "./PortfoliosModal";
import NewClientModal from "./NewClientModal";
import NewSubPortfolio from "./NewSubPortfolio";
import DeleteClientModel from "./DeleteClientModel";
import ClientTable from "./ClientTable";
function Client() {
    const [showModal, setShowModal] = useState(false);
    const [showSubModal, setShowSubModal] = useState(false);
    const [portfolioTitle, setPortfolioTitle] = useState('');
    const [portfolioTitles, setPortfolioTitles] = useState([]);
    const [mainPortfolioTitle, setMainPortfolioTitle] = useState('');
    const [subPortfolioTitle, setSubPortfolioTitle] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [dotnumber, setDotnumber] = useState("");
    const [lead, setLead] = useState("");
    const [state, setState] = useState("");
    const [status, setStatus] = useState('');
    const [renewaldate, setRenewaldate] = useState("");
    const [showclient, setShowclient] = useState(false);
    const [deltemodal, setDeltemodal] = useState(false);

    const [currentDate, setCurrentDate] = useState('');
    const [deleteindex, setDeleteindex] = useState('');
    const opendelete = (index) => {
        setDeleteindex(index)
        setDeltemodal(true)
    }
    useEffect(() => {
        // Function to get the current date
        const getCurrentDate = () => {
            const dateObj = new Date();
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObj.getDate()).slice(-2);
            const year = dateObj.getFullYear();
            const dateString = year + '-' + month + '-' + day;
            return dateString;
        };

        // Update the state with the current date
        setCurrentDate(getCurrentDate());
    }, []);
    const openModal = () => {
        setShowModal(true);
    };

    const openclient = () => {
        setShowclient(true);
    };

    const openSubModal = (title) => {
        setShowSubModal(true);
        setMainPortfolioTitle(title);
    };

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };

    const handleSavePortfolio = () => {
        if (selectedValue === 'Yes') {
            setPortfolioTitles([...portfolioTitles, { title: portfolioTitle, subtitles: [] }]);
        } else {
            setPortfolioTitles([...portfolioTitles, { title: portfolioTitle, subtitles: null }]);
        }
        setPortfolioTitle('');
        setShowModal(false);
    };

    const handleSaveSubPortfolio = () => {
        const updatedTitles = portfolioTitles.map(titleObj => {
            if (titleObj.title === mainPortfolioTitle) {
                if (titleObj.subtitles === null) {
                    titleObj.subtitles = [subPortfolioTitle];
                } else {
                    titleObj.subtitles.push(subPortfolioTitle);
                }
            }
            return titleObj;
        });
        setPortfolioTitles(updatedTitles);
        setSubPortfolioTitle('');
        setShowSubModal(false);
    };
    const [clients, setClients] = useState([]);
    const [editingClientIndex, setEditingClientIndex] = useState(null); // Track the index of the client being edited

    // Function to save or update a client
    const handleSaveClient = () => {
        const newClient = {
            dotnumber: dotnumber,
            lead: lead,
            state: state,
            status: status,
            renewaldate: renewaldate
        };

        if (editingClientIndex !== null) {
            // If editing an existing client, update the client at the specified index
            const updatedClients = [...clients];
            updatedClients[editingClientIndex] = newClient;
            setClients(updatedClients);
        } else {
            // If saving a new client, add the new client to the clients array
            setClients([...clients, newClient]);
        }

        // Reset the client input fields and close the modal
        resetClientFields();
    };

    // Function to delete a client
    const handleDeleteClient = (clientIndex) => {
        const updatedClients = [...clients];
        updatedClients.splice(clientIndex, 1); // Remove the client at clientIndex
        setClients(updatedClients);
        setDeltemodal(false)
    };

    // Function to edit a client
    const handleEditClient = (clientIndex) => {
        // Set the client fields to edit
        const clientToEdit = clients[clientIndex];
        setDotnumber(clientToEdit.dotnumber);
        setLead(clientToEdit.lead);
        setState(clientToEdit.state);
        setStatus(clientToEdit.status);
        setRenewaldate(clientToEdit.renewaldate);

        // Set the editing client index
        setEditingClientIndex(clientIndex);
        setShowclient(true); // Open the client modal for editing
    };

    // Function to reset client input fields and editing index
    const resetClientFields = () => {
        setDotnumber('');
        setLead('');
        setState('');
        setStatus('');
        setRenewaldate('');
        setEditingClientIndex(null); // Reset the editing index
        setShowclient(false); // Close the client modal
    };

    return (
        <>
            {portfolioTitles.length === 0 ? (<>
                <div className="boxofclient">
                    <div className="clientside">
                        <span className="clienttitle">Client portfolio</span>
                        <p className="text-word">The "Client Portfolio" feature allows users to create a portfolio of clients to store information about their customers. The client portfolio can be customized with information such as name, address, phone number, email, date of birth, etc.</p>
                        <div className="create-portfolio" onClick={openModal}>
                            Create New Portfolio
                        </div>
                    </div>
                    <div className="image-side">
                        <img src={clientimage} alt="client" />
                    </div>
                </div>
            </>) : (<>
                <div className="coverofportfolio">
                    <div className="create-portfolio" onClick={openModal}>
                        Create New Portfolio
                    </div>

                    {portfolioTitles.map((titleObject, index) => {
                        const title = titleObject.title; // Extract the title from the object
                        const subtitles = titleObject.subtitles; // Extract the array of subtitles

                        return (
                            <div className="coverofclientspace" key={index}>
                                <div className="headerofmodal2 d-flex justify-content-between">
                                    <span className="headertext">{title}'s customer base</span>
                                    <div className="mainwork" onClick={openclient}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        <span>Add new client</span>
                                                                    </div>
                                </div>
                                
                                <div className="coverofsubtitle">
                                    {
                                       subtitles?.length === 0 || subtitles===null ? (<>
                                            <div className="containerofsubtitle" onClick={() => openSubModal(title)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span>Add sub-portfolios</span>
                                            </div>
                                        </>) :
                                            (<>
                                                {
                                                    clients.length === 0 ? (<>   {
                                                        subtitles.map((title, index) => (
                                                            <div className="coverofclientspace" key={index}>
                                                                <div className="headerofmodal2 subtitlediv">
                                                                    <span className="headertext">{title}</span>
                                                                    <div className="mainwork mt-4" onClick={openclient}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        <span>Add new client</span>
                                                                    </div>
                                                                </div>
                                                                <div className="coverofsubtitle subtitles">

                                                                    <div className="boxofpeople">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                                                                            <path d="M16.5 22.5C19.2614 22.5 21.5 20.2614 21.5 17.5C21.5 14.7386 19.2614 12.5 16.5 12.5C13.7386 12.5 11.5 14.7386 11.5 17.5C11.5 20.2614 13.7386 22.5 16.5 22.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M25 14.5C26.1647 14.4981 27.3137 14.7683 28.3554 15.2892C29.3972 15.81 30.3028 16.5671 31 17.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M2 17.5C2.69725 16.5671 3.60285 15.81 4.64457 15.2892C5.6863 14.7683 6.83532 14.4981 8 14.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M9.2998 27C9.95833 25.6513 10.9824 24.5148 12.2554 23.7197C13.5283 22.9247 14.999 22.5032 16.4998 22.5032C18.0006 22.5032 19.4713 22.9247 20.7442 23.7197C22.0172 24.5148 23.0413 25.6513 23.6998 27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M8.00015 14.5C7.24096 14.5008 6.49722 14.2855 5.85584 13.8792C5.21446 13.473 4.70193 12.8927 4.37814 12.206C4.05434 11.5193 3.93266 10.7546 4.0273 10.0014C4.12194 9.24809 4.429 8.5373 4.9126 7.95206C5.39619 7.36681 6.03636 6.93127 6.75829 6.69634C7.48022 6.4614 8.25411 6.43677 8.98952 6.62532C9.72493 6.81388 10.3915 7.20782 10.9113 7.76113C11.4312 8.31444 11.7828 9.00427 11.9252 9.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M21.0752 9.75C21.2175 9.00427 21.5692 8.31444 22.089 7.76113C22.6089 7.20782 23.2754 6.81388 24.0108 6.62532C24.7462 6.43677 25.5201 6.4614 26.2421 6.69634C26.964 6.93127 27.6042 7.36681 28.0878 7.95206C28.5714 8.5373 28.8784 9.24809 28.9731 10.0014C29.0677 10.7546 28.946 11.5193 28.6222 12.206C28.2984 12.8927 27.7859 13.473 27.1445 13.8792C26.5031 14.2855 25.7594 14.5008 25.0002 14.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                    <span className="contentofbus mt-2">You have no clients in your portfolio.</span>
                                                                    <span className="contentof">Add your first client now.</span>
                                                                    <div className="mainwork mt-4" onClick={openclient}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        <span>Add new client</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }</>) : (<>
                                                        <div className="coverofclient">
                                                        <ClientTable   clients={clients}  handleEditClient={handleEditClient} opendelete={opendelete} currentDate={currentDate}/>

                                                        </div>
                                                    </>)
                                                }

                                            </>)
                                    }

                                </div>
                                {subtitles?.length === 0 || subtitles===null ? (<> </>) : (<> <div className="mainwork mt-4 mx-3" onClick={() => openSubModal(title)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span>Add sub-portfolios</span>
                                </div></>)}

                            </div>
                        );
                    })}
                </div>

            </>)}
            <PortfolioModal setShowModal={setShowModal} showModal={showModal} portfolioTitle={portfolioTitle} setPortfolioTitle={setPortfolioTitle} selectedValue={selectedValue} handleChange={handleChange} handleSavePortfolio={handleSavePortfolio} />
            <NewClientModal showclient={showclient} setShowclient={setShowclient} dotnumber={dotnumber} setDotnumber={setDotnumber} lead={lead} setLead={setLead} state={state} setState={setState} setStatus={setStatus} renewaldate={renewaldate} setRenewaldate={setRenewaldate} handleSaveClient={handleSaveClient} status={status} />
            <NewSubPortfolio setShowSubModal={setShowSubModal} showSubModal={showSubModal} setSubPortfolioTitle={setSubPortfolioTitle} subPortfolioTitle={subPortfolioTitle} handleSaveSubPortfolio={handleSaveSubPortfolio} />
            <DeleteClientModel deltemodal={deltemodal} setDeltemodal={setDeltemodal} deleteindex={deleteindex} handleDeleteClient={handleDeleteClient} />
        </>
    );
}

export default Client;
