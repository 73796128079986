import React from 'react'

const VerifyVinNumber = ({ doneNewModal, closeNewModal, vinresponse, Modal, isNewModalVisible, loading, Skeleton, Vin }) => {
    return (
        <>
            <Modal
                title={null}
                closeIcon={null}
                open={isNewModalVisible}
                onOk={doneNewModal}
                onCancel={closeNewModal}
                width={650}
                footer={
                    <div style={{ padding: '16px' }}>
                        <div className="footerofmodal">
                            <span className="cancelbutton" onClick={closeNewModal}>Cancel</span>
                            <div className="submitbutton" onClick={doneNewModal}>
                                Save vehicle
                            </div>
                        </div>

                    </div>
                }
            >

                {
                    loading ? (<>
                        <Skeleton active />
                    </>) : (
                        <>
                            <div className="headerofmodal">
                                <span className="headertext">VIN</span>
                                <svg style={{ cursor: "pointer" }} onClick={closeNewModal} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="modal-contents">
                                <div className="mainheading">
                                    {vinresponse[10]?.Value} {vinresponse[9]?.Value} {vinresponse[7]?.Value}
                                </div>
                                <div className="cont mt-4 " style={{ display: 'flex', gap: '10px', flexWrap: "wrap" }} >
                                    <div className="infoblock">
                                        <div className="title">
                                            Vin
                                        </div>
                                        <div className="value">
                                            {Vin}
                                        </div>
                                    </div>

                                    <div className="infoblock">
                                        <div className="titleofinfo">
                                            Make
                                        </div>
                                        <div className="descofinfo">
                                            {vinresponse[7]?.Value}
                                        </div>
                                    </div>
                                    <div className="infoblock">
                                        <div className="titleofinfo">
                                            Model
                                        </div>
                                        <div className="descofinfo">
                                            {vinresponse[9]?.Value}
                                        </div>
                                    </div>
                                    <div className="infoblock">
                                        <div className="titleofinfo">
                                            Year
                                        </div>
                                        <div className="descofinfo">
                                            {vinresponse[10]?.Value}
                                        </div>
                                    </div><div className="infoblock">
                                        <div className="titleofinfo">
                                            Driver Type
                                        </div>
                                        <div className="descofinfo">
                                            {vinresponse[51]?.Value}
                                        </div>
                                    </div><div className="infoblock">
                                        <div className="titleofinfo">
                                            Manufactured in        </div>
                                        <div className="descofinfo">
                                            {vinresponse[8]?.Value}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </>

                    )
                }

            </Modal>

        </>
    )
}

export default VerifyVinNumber