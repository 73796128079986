import { useState } from "react";


const InsprctionDetail = () => {
    const [opendetail, setOpendetail] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };
    ;
    const opendetails = () => {
        setOpendetail(true);
    };

    const closeDetail = () => {
        setOpendetail(false);
    };
    const [Vehicles, setVehicles] = useState([
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
    ]);
    return (
        <>
            <div className="operationalclassification">
                <div className="card-header">
                    Inspections/ Vehicle details
                </div>
                <div className="classificationwrapper2">
                    <div className="inboxcontain">
                        US inspection results for 24 months prior to:<strong> 12/19/2023</strong>
                    </div>
                </div>
                <div className="tablecontent">
                    <div className="coveraround">


                        <div className="headeroftable">
                            <div className="contentareafortable">
                                <span className="tabletitle">Date:</span>
                                <span className="tabledesc">9/22/2023</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">Report number:</span>
                                <span className="tabledesc">5DY2900</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">State:</span>
                                <span className="tabledesc">PA</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">Vehicle:</span>
                                <span className="tabledesc">Truck tractor</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">Vin:</span>
                                <span className="tabledesc">3C7WRSCL1MG648341</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">Type:</span>
                                <span className="tabledesc">Straight Truck</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle">Time weight (TW):</span>
                                <span className="tabledesc">3</span>
                            </div>
                        </div>




                        <div className="headeroftable2">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Unit:</span>
                                <span className="tabledesc2">1</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Type:</span>
                                <span className="tabledesc2">Straight Truck</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Make:</span>
                                <span className="tabledesc2">RAM</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Plate state:</span>
                                <span className="tabledesc2">MD</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Plate Number:</span>
                                <span className="tabledesc2">7EY0572</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Vin:</span>
                                <span className="tabledesc2">3C7WRSCL1MG648341</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Severtiy weight</span>
                                <span className="tabletitle2">(SW)</span>
                            </div>

                        </div>
                        <span className="tabledesc2" style={{ paddingTop: "0px", paddingLeft: "30px" }}>2</span>
                        <div className="alltextdocument">
                            <span className="tabletitle2">Vehicle maint:</span>
                            <div className="coveroftext">
                                <span className="vehicalmaint">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                <span className="vehicalmaint1">3 (OOS)</span>

                            </div>
                            <div className="coveroftext">
                                <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                <span className="vehicalmaint3">2 + 2 (005)</span>

                            </div>
                            <div className="coveroftext">
                                <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                <span className="vehicalmaint3">4 (OOS)</span>

                            </div>
                            <div className="coveroftext">
                                <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                <span className="vehicalmaint3">2 (OOS)</span>

                            </div>
                            <div className="coveroftext">
                                <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                <span className="vehicalmaint3">2 (OOS)</span>

                            </div>
                            <div className="buttonfordetail">
                                More Details
                            </div>

                        </div>
                        <div className="secondpart">
                            <div className="headeroftable2">
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Unit:</span>
                                    <span className="tabledesc2">1</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Type:</span>
                                    <span className="tabledesc2">Straight Truck</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Make:</span>
                                    <span className="tabledesc2">RAM</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Plate state:</span>
                                    <span className="tabledesc2">MD</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Plate Number:</span>
                                    <span className="tabledesc2">7EY0572</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Vin:</span>
                                    <span className="tabledesc2">3C7WRSCL1MG648341</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Severtiy weight</span>
                                    <span className="tabletitle2">(SW)</span>
                                </div>

                            </div>
                            <div className="alltextdocument">
                                <span className="tabletitle2">Vehicle maint:</span>

                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">2 + 2 (005)</span>

                                </div>
                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">4 (OOS)</span>

                                </div>

                                <div className="buttonfordetail" onClick={opendetails}>
                                    More Details
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="headeroftableunder">
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Date:</span>
                            <span className="tabledesc">9/22/2023</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Report number:</span>
                            <span className="tabledesc">5DY2900</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">State:</span>
                            <span className="tabledesc">PA</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Vehicle:</span>
                            <span className="tabledesc">Truck tractor</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Vin:</span>
                            <span className="tabledesc">3C7WRSCL1MG648341</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Type:</span>
                            <span className="tabledesc">Straight Truck</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Time weight (TW):</span>
                            <span className="tabledesc">3</span>
                        </div>
                    </div>
                    <div className="headeroftableundererror">

                        <div className="contentareafortableunder d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                            </svg><div className="placement mx-1">
                                <span className="tabletitle">Date:</span>
                                <span className="tabledesc">9/22/2023</span>
                            </div>

                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Report number:</span>
                            <span className="tabledesc">5DY2900</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">State:</span>
                            <span className="tabledesc">PA</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Vehicle:</span>
                            <span className="tabledesc">Truck tractor</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Vin:</span>
                            <span className="tabledesc">3C7WRSCL1MG648341</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Type:</span>
                            <span className="tabledesc">Straight Truck</span>
                        </div>
                        <div className="contentareafortableunder">
                            <span className="tabletitle">Time weight (TW):</span>
                            <span className="tabledesc">3</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modalfordetail">

            </div>

            {opendetail && (
                <div className="custom-modal">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="headerofmodal">
                            <span className="headertext">Detailed inspection report as of 11/24/2023</span>
                            <svg style={{ cursor: "pointer" }} onClick={closeDetail} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        {/* Modal Body */}
                        <div className="modal-body">




                            <div className="wraperofcrash">
                                <div className="operationalclassification">
                                    <div className="card-header">
                                        Carrier information
                                    </div>
                                    <div className="coverofcrash">
                                        <div className="induvidual">
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Carrier name:</span>
                                                <span className="tabledesc2">Light Towing Llc</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2" style={{ width: "154px" }}>Carrier address:</span>
                                                <span className="tabledesc2">22600 Woodfield Road
                                                    Gaithersburg, MD 20882</span>
                                            </div>
                                        </div>
                                        <div className="induvidual">
                                            <div className="coverofclass">
                                                <span className="tabletitle2">U.S. DOT#:</span>
                                                <span className="tabledesc2">2284379</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">State Census#:</span>
                                                <span className="tabledesc2">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="operationalclassification">
                                    <div className="card-header">
                                        Time/ Location
                                    </div>
                                    <div className="coverofcrash">
                                        <div className="induvidual">
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Report #:</span>
                                                <span className="tabledesc2">IA000TT8CJ4C</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">State:</span>
                                                <span className="tabledesc2">IA</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Level:</span>
                                                <span className="tabledesc2">I. Full</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Start time:</span>
                                                <span className="tabledesc2">IA</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Post crash inspection:</span>
                                                <span className="tabledesc2">No</span>
                                            </div>

                                        </div>
                                        <div className="induvidual">
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Report state:</span>
                                                <span className="tabledesc2">IA</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Date:</span>
                                                <span className="tabledesc2">5/3/2023</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">End time:</span>
                                                <span className="tabledesc2">1005</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Facility:</span>
                                                <span className="tabledesc2">Fixed site</span>
                                            </div>
                                            <div className="coverofclass">
                                                <span className="tabletitle2">Hazmat placard required:</span>
                                                <span className="tabledesc2">No</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="classifica2">



                            <div className="operationalclassification">
                                <div className="card-header">
                                    Vehicle information
                                </div>
                                <div className="coverofcrash">
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Unit:</span>
                                            <span className="tabledesc2">1</span>
                                        </div>

                                    </div>

                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Type:</span>
                                            <span className="tabledesc2">Straight truck</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Make:</span>
                                            <span className="tabledesc2">RAM</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate state:</span>
                                            <span className="tabledesc2">MD</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate number:</span>
                                            <span className="tabledesc2">5EX5463</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">VIN number:</span>
                                            <span className="tabledesc2">3C7WRSCL3MG648342</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="coverofcrash borderabove">
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Unit:</span>
                                            <span className="tabledesc2">1</span>
                                        </div>

                                    </div>

                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Type:</span>
                                            <span className="tabledesc2">Straight truck</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Make:</span>
                                            <span className="tabledesc2">RAM</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate state:</span>
                                            <span className="tabledesc2">MD</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate number:</span>
                                            <span className="tabledesc2">5EX5463</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">VIN number:</span>
                                            <span className="tabledesc2">3C7WRSCL3MG648342</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Carrier violations
                                </div>
                                <div className="coverofcrash">
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Vio code:</span>
                                            <span className="tabledesc2">383.23A2</span>
                                        </div>

                                    </div>

                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Section:</span>
                                            <span className="tabledesc2">383.23(a)(2)</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Unit:</span>
                                            <span className="tabledesc2">Driver</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate state:</span>
                                            <span className="tabledesc2">MD</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">OOS:</span>
                                            <span className="tabledesc2">YES</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Description:</span>
                                            <span className="tabledesc2">Operating A cmv without a cdl</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Included in SMS</span>
                                            <span className="tabledesc2">Yes</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Basic</span>
                                            <span className="tabledesc2">Driver fitness</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Reason not included in SMS</span>
                                            <span className="tabledesc2">-</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="coverofcrash borderabove">
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Vio code:</span>
                                            <span className="tabledesc2">383.23A2</span>
                                        </div>

                                    </div>

                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Section:</span>
                                            <span className="tabledesc2">383.23(a)(2)</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Unit:</span>
                                            <span className="tabledesc2">Driver</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Plate state:</span>
                                            <span className="tabledesc2">MD</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">OOS:</span>
                                            <span className="tabledesc2">YES</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Description:</span>
                                            <span className="tabledesc2">Operating A cmv without a cdl</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Included in SMS</span>
                                            <span className="tabledesc2">Yes</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Basic</span>
                                            <span className="tabledesc2">Driver fitness</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Reason not included in SMS</span>
                                            <span className="tabledesc2">-</span>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                </div>


            )}

        </>
    )
}
export default InsprctionDetail