import { Route, Routes } from 'react-router-dom';
import Allcategories from '../Pages/Allcategories';
import Chameleon from '../Pages/Chameleon';
import Usdot from '../Pages/Usdot';
import Marketing from '../Pages/Marketing/Marketing';
import BlogDetail from '../Pages/Marketing/BlogDetail';
import Carrier from '../Pages/Carier';
import CarrierData from '../Pages/Carierdata';
import TableofCarrier from '../Pages/Tableofcarier';
import ComentArea from '../Pages/ComentArea';
import Client from '../Pages/Client/Client';
import Deepdive from '../Pages/Deep/Deepdive';
import MailPage from '../Mailbox/Mailpage';
import Preview from '../Mailbox/Preview';
import ByVin from '../Pages/ByVin';
import DeepDetail from '../Pages/Deep/DeepDetail';
import Scroller from '../Pages/Scroller';
import LeadHome from '../Pages/Leads/LeadHome';
import LeadsResults from '../Pages/LeadsResults';
import Myinfo from '../UserSection/Myinfo';
import Agencyinfo from '../UserSection/Agencyinfo';
import Collaborators from '../UserSection/Collaborators';
import Login from '../Authentication/Login';
import AddCollab from '../UserSection/AddCollab';
import ProfileCollab from '../UserSection/ProfileCollab';
import Finances from '../UserSection/Finances';
import NotFound from './NotFound'; // Make sure you have a NotFound component
import PrivateRoute from './PrivateRoute '; // Import the PrivateRoute component
import Logout from './Logout';
import { SERVER_URL } from '../Constants/BaseUrl';
import { useEffect, useState } from 'react';
import axios from 'axios';
import StateSelection from '../Pages/StateSelection';

function AppRoutes  () {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [statesAllowed, setStatesAllowed]=useState(null)

  useEffect(() => {
    const userId = localStorage.getItem('userId');
  
    const fetchUserData = async () => {
      try {
        if (!userId) {
          console.error('User ID not found in localStorage');
          setLoading(false);
          return;
        }
  
        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        const userData = userResponse.data.data;
  
        setUser(userData);
        setLoading(false); // Set loading to false after successful data fetch
  
        if (userData.purchasedcategory === 'Starter' || userData.purchasedcategory === 'Business') {
          setStatesAllowed(userData.purchasedcategory === 'Starter' ? 1 : 3);
  
          const stateInfoResponse = await axios.get(`${SERVER_URL}/userdata/${userId}`);
          const stateInfoData = stateInfoResponse.data.user.states;
  
          // Check if states is an array
          if (Array.isArray(stateInfoData)) {
            if (stateInfoData.length === 0) {
              setVisible(true);
            }
          } else {
            console.log('states is not an array or is undefined');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false); // Set loading to false on error
        // Handle additional error logic if needed
      }
    };
  
    fetchUserData();
  }, []);
  return(
    <>
    {
      visible ?(<>
     <StateSelection/>

      </>):(
        <Routes>
    <Route path="/" element={<Allcategories />} />
    <Route path="/login" element={<Login />} />
    <Route path="/not-found" element={<NotFound />} />
    <Route path="/logout" element={<Logout />} />

    <Route path="/:sales" element={<PrivateRoute><Allcategories /></PrivateRoute>} />
    <Route path="/:sales/chameleon" element={<PrivateRoute><Chameleon /></PrivateRoute>} />
    <Route path="/:sales/usdot" element={<PrivateRoute><Usdot /></PrivateRoute>} />
    <Route path="/:sales/handbook" element={<PrivateRoute><Marketing /></PrivateRoute>} />
    <Route path="/:sales/detail" element={<PrivateRoute><BlogDetail /></PrivateRoute>} />
    <Route path="/:sales/carier" element={<PrivateRoute><Carrier /></PrivateRoute>} />
    <Route path="/:sales/LeadsResults" element={<PrivateRoute><LeadsResults /></PrivateRoute>} />
    <Route path="/:sales/leadResults" element={<PrivateRoute><Carrier /></PrivateRoute>} />
    <Route path="/:sales/carrierdata" element={<PrivateRoute><CarrierData /></PrivateRoute>} />
    <Route path="/:sales/carrierpage" element={<PrivateRoute><TableofCarrier /></PrivateRoute>} />
    <Route path="/:sales/commentarea" element={<PrivateRoute><ComentArea /></PrivateRoute>} />
    <Route path="/:sales/client" element={<PrivateRoute><Client /></PrivateRoute>} />
    <Route path="/:sales/deep" element={<PrivateRoute><Deepdive /></PrivateRoute>} />
    <Route path="/:sales/mail" element={<PrivateRoute><MailPage /></PrivateRoute>} />
    <Route path="/:sales/preview" element={<PrivateRoute><Preview /></PrivateRoute>} />
    <Route path="/:sales/vin" element={<PrivateRoute><ByVin /></PrivateRoute>} />
    <Route path="/:sales/detail" element={<PrivateRoute><DeepDetail /></PrivateRoute>} />
    <Route path="/scroller" element={<PrivateRoute><Scroller /></PrivateRoute>} />
    <Route path="/sales/leads" element={<PrivateRoute><LeadHome /></PrivateRoute>} />
    <Route path="/home/myinfo" element={<PrivateRoute><Myinfo /></PrivateRoute>} />
    <Route path="/home/agencyinfo" element={<PrivateRoute><Agencyinfo /></PrivateRoute>} />
    <Route path="/home/collaborators" element={<PrivateRoute><Collaborators /></PrivateRoute>} />
    <Route path="/home/add-collaborators" element={<PrivateRoute><AddCollab /></PrivateRoute>} />
    <Route path="/home/collaborator/:id" element={<PrivateRoute><ProfileCollab /></PrivateRoute>} />
    <Route path="/home/finances" element={<PrivateRoute><Finances /></PrivateRoute>} />
  </Routes>
      )
    }
     
    </>
  )
};

export default AppRoutes;
