const AuthorityHistory = () => {
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Authority history
                </div>
                <div className="compinfo">
                    <div className="coverofcompinfo  ">
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Sub:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>


                        </div>
                        <div className="infosection">

                            <div className="target">
                                <span className="titleofinfo">
                                    Auth type:
                                </span>
                                <span className="descofinfo" style={{ width: "262px" }}>
                                    MOTOR PROPERTY COMMON CARRIER
                                </span>
                            </div>


                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Original action:
                                </span>
                                <span className="descofinfo">
                                    Granted  |  12/07/2022
                                </span>
                            </div>


                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Disposition:
                                </span>
                                <span className="descofinfo">
                                    -  |  -
                                </span>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default AuthorityHistory;