

export const initialItems = [
  { id: 1, name: 'DOT', source: 'b.dot_number', table: 'fis_uat.dbo.census_master', checked: false},
  { id: 2, name: 'Docket', source: `case when concat(ICC_DOCKET_1_PREFIX,ICC1) <> '' then concat(ICC_DOCKET_1_PREFIX,ICC1) when concat(ICC_DOCKET_2_PREFIX,ICC2)<> '' then concat(ICC_DOCKET_2_PREFIX,ICC2)  when concat(ICC_DOCKET_3_PREFIX,ICC_3) <> '' then concat(ICC_DOCKET_3_PREFIX,ICC_3) when a.docket_number  <> '' then replace(a.docket_number,'-','') else null end`, table: `census_master`, checked: false },
  { id: 3, name: 'Phone', source: 'TelNum_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.telnum_ref', join_column: 'd.telnum_id', join_value: 'd.telnum' },
  { id: 4, name: 'Mail Phone', source: 'mailing_telnum_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.telnum_ref', join_column: 's.telnum_id', join_value: 's.telnum' },
  { id: 6, name: 'Mailling Address', source: 'mailing_street_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.streetaddress_ref', join_column: 'k.streetaddress_id', join_value: 'k.streetaddress' },
  { id: 7, name: 'Mailling State', source: 'mailing_state_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.states_lkp', join_column: 'l.state_id', join_value: 'l.state_code' },
  { id: 8, name: 'Company Rep 1', source: 'company_officer_1_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.person', join_column: 'o.person_id', join_value: 'o.person_name' },
  { id: 9, name: 'Add Date', source: 'a.record_add_date', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 10, name: 'State ID', source: 'c.state_code', table: 'fis_uat.dbo.census_master', checked: false},
  { id: 11, name: 'Legal Name', source: 'a.Legal_Name', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 12, name: 'Cell Num', source: 'cellphone_id', table: 'fis_uat.dbo.census_master', checked: false, join_table: 'fis_uat.dbo.telnum_ref', join_column: 'e.telnum_id', join_value: 'e.telnum' },
];
export const initialItems3 = [
  { id: 1, name: 'Beverages', source: 'a.Carries_BEVRAGES', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 2, name: 'Coal Coke', source: 'a.Carries_COALCOKE', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 3, name: 'Drive Away Towaway', source: 'a.Carries_DRIVETOW', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 4, name: 'Grain Feed Hay', source: 'a.Carries_GRAINFEED', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 5, name: 'Household Goods', source: 'a.Carries_HOUSEHOLD', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 6, name: 'Livestock', source: 'a.Carries_LIVESTOCK', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 7, name: 'Meat', source: 'a.Carries_MEAT', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 8, name: 'Motor Vehicle', source: 'a.Carries_MOTOVEH', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 9, name: 'Paper Product', source: 'a.Carries_PAPERPROD', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 10, name: 'US Mail', source: 'a.Carries_USMAIL', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 11, name: 'Building Materials', source: 'a.carries_bldgmat', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 12, name: 'Commodities Dry Bulk', source: 'a.carries_drybulk', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 13, name: 'Farm Supplies', source: 'a.Carries_FARMSUPP', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 14, name: 'Garbage Refuse Trash', source: 'a.Carries_GARBAGE', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 15, name: 'Intermodal Cont', source: 'a.Carries_INTERMODAL', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 16, name: 'Logs Poles Beams Lumber', source: 'a.Carries_LOGPOLE', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 17, name: 'Metal Sheets Coil Rolls', source: 'a.Carries_METALSHEET', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 18, name: 'Oil Field Equipment', source: 'a.Carries_OILFIELD', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 19, name: 'Passengers', source: 'a.Carries_PASSENGERS', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 20, name: 'Utility', source: 'a.Carries_UTILITY', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 21, name: 'Chemicals', source: 'a.Carries_CHEM', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 22, name: 'Construction', source: 'a.Carries_CONSTRUCT', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 23, name: 'Fresh Produce', source: 'a.Carries_PRODUCE', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 24, name: 'General Freight', source: 'a.Carries_GENFREIGHT', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 25, name: 'Liquid Gas', source: 'a.Carries_LIQGAS', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 26, name: 'Machinery Large Objects', source: 'a.Carries_MACHLRG', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 27, name: 'Mobile Homes', source: 'a.Carries_MOBILEHOME', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 28, name: 'Cargo Other', source: 'a.Carries_CARGOOTHER', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 29, name: 'Refrigerated Food', source: 'a.Carries_COLDFOOD', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 30, name: 'Water Well', source: 'a.Carries_WATERWELL', table: 'fis_uat.dbo.census_master', checked: false },
];
export const initialItems1 = [
  { id: 1, name: 'Power Units', source: '', table: 'fis_uat.dbo.census_master', checked: false }, //--neds to be fixed
  { id: 2, name: 'Buses', source: 'a.BUS_UNITS', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 3, name: 'BusOwn', source: 'a.OWNBUS_16', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 4, name: 'BusLease', source: '', table: 'fis_uat.dbo.census_master', checked: false },//--LOGIC REQUIRED
  { id: 5, name: 'Trucks', source: 'a.TRUCK_UNITS', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 6, name: 'TruckOwn', source: 'a.OWNTRUCK', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 7, name: 'TruckLease', source: '', table: 'fis_uat.dbo.census_master', checked: false },// --LOGIC REQUIRED
  { id: 8, name: 'Drivers', source: 'a.TOTAL_DRIVERS', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 9, name: 'CDL Drivers', source: 'a.TOTAL_CDL', table: 'fis_uat.dbo.census_master', checked: false }
];
export const initialItems2 = [
  { id: 1, name: 'BIPD Required', source: '', table: '', checked: false }, //--need logic
  { id: 2, name: 'Insurer', source: 'q.insurance_company_name', table: 'fis_uat.dbo.actpendinsur_allwithhistory', checked: false },
  { id: 3, name: 'Insurance Type', source: 'q.insurance_type_description', table: 'fis_uat.dbo.actpendinsur_allwithhistory', checked: false },
  { id: 4, name: 'Filing Locale', source: '', table: '', checked: false }, //--need logic
  { id: 5, name: 'Policy Number', source: 'q.policy_number', table: 'fis_uat.dbo.actpendinsur_allwithhistory', checked: false },
  { id: 6, name: 'Policy Effective Date', source: 'q.effective_date', table: 'fis_uat.dbo.actpendinsur_allwithhistory', checked: false },
  { id: 7, name: 'Policy Cancellation Date', source: 'q.cancel_effective_date', table: 'fis_uat.dbo.actpendinsur_allwithhistory', checked: false },
  { id: 8, name: 'Policy Expiration Month', source: 'MONTH(q.expiration_date)', table: '', checked: false }, //--self calculation
  { id: 9, name: 'Policy Expiration Day', source: 'DAY(q.expiration_date)', table: '', checked: false }, //--self calculation
  { id: 10, name: 'Policy Expiration Date', source: 'q.expiration_date', table: '', checked: false }, //--self calculation
  { id: 11, name: 'BOC3', source: '', table: '', checked: false }, //--need logic
  { id: 12, name: 'Common Auth', source: 'a.common_authority', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 13, name: 'Contract Auth', source: 'a.contract_authority', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 14, name: 'Contract Auth First G', source: '', table: '', checked: false }, //--need logic
  { id: 15, name: 'Broker Auth', source: 'a.broker_authority', table: 'fis_uat.dbo.census_master', checked: false },
  { id: 16, name: 'Common Auth First', source: '', table: '', checked: false } ////--need logic
];
export const initialAllItems = [
  { id: 1, name: 'DOT', source: 'dot_number_id', table: 'census_master', checked: false, join_table: 'fis_uat.dbo.dot_ref', join_column: 'dot_id', join_value: 'dot_number' },
  { id: 2, name: 'Docket', source: `case when concat(ICC_DOCKET_1_PREFIX,ICC1) <> '' then concat(ICC_DOCKET_1_PREFIX,ICC1) when concat(ICC_DOCKET_2_PREFIX,ICC2)<> '' then concat(ICC_DOCKET_2_PREFIX,ICC2)  when concat(ICC_DOCKET_3_PREFIX,ICC_3) <> '' then concat(ICC_DOCKET_3_PREFIX,ICC_3) when docket_number  <> '' then replace(docket_number,'-','') else null end`, table: 'census_master', checked: false },
  { id: 3, name: 'Phone', source: 'TelNum_id', table: 'census_master', checked: false, join_table: 'dot_ref', join_column: 'telnum_id', join_value: 'telnum' },
  { id: 4, name: 'Mail Phone', source: 'mailing_telnum_id', table: 'census_master', checked: false, join_table: 'dot_ref', join_column: 'telnum_id', join_value: 'telnum' },
  { id: 5, name: 'Business County', checked: false },// --DISCUSSION REQUIRED
  { id: 6, name: 'Mailling Address', source: 'mailing_street_id', table: 'census_master', checked: false, join_table: 'streetaddress_ref', join_column: 'streetaddress_id', join_value: 'streetaddress' },
  { id: 7, name: 'Mailling State', source: 'mailing_state_id', table: 'census_master', checked: false, join_table: 'states_lkp', join_column: 'state_id', join_value: 'state_code' },
  { id: 8, name: 'Company Rep 1', source: 'company_officer_1_id', table: 'census_master', checked: false, join_table: 'person', join_column: 'person_id', join_value: 'person_name' },
  { id: 9, name: 'Add Date', source: 'record_add_date', table: 'census_master', checked: false },
  { id: 10, name: 'State ID', source: 'physical_state_id', table: 'census_master', checked: false, join_table: 'states_lkp', join_column: 'state_id' },
  { id: 11, name: 'Legal Name', source: 'Legal_Name', table: 'census_master', checked: false },
  { id: 12, name: 'Cell Num', source: 'cellphone_id', table: 'census_master', checked: false, join_table: 'dot_ref', join_column: 'telnum_id', join_value: 'telnum' },
  { id: 13, name: 'Mail Phone', checked: false }, //--REPEATED
  { id: 14, name: 'Business County', checked: false }, //--REPEATED
  { id: 15, name: 'Mailling Address', checked: false }, //--REPEATD
  { id: 16, name: 'Mailling State', checked: false }, //--REPEATED
  { id: 17, name: 'Company Rep 1', checked: false }, //--REPEATED
  { id: 18, name: 'Add Date', checked: false }, //--REPEATED
  { id: 19, name: 'State ID', checked: false }, //--REPEATED
  { id: 20, name: 'Legal Name', checked: false }, //--REPEATED
  { id: 21, name: 'Cell Num', checked: false }, //--REPEATED
  { id: 22, name: 'Beverages', source: 'Carries_BEVRAGES', table: 'census_master', checked: false },
  { id: 23, name: 'Coal Coke', source: 'Carries_COALCOKE', table: 'census_master', checked: false },
  { id: 24, name: 'Drive Away Towaway', source: 'Carries_DRIVETOW', table: 'census_master', checked: false },
  { id: 25, name: 'Grain Feed Hay', source: 'Carries_GRAINFEED', table: 'census_master', checked: false },
  { id: 26, name: 'Household Goods', source: 'Carries_HOUSEHOLD', table: 'census_master', checked: false },
  { id: 27, name: 'Livestock', source: 'Carries_LIVESTOCK', table: 'census_master', checked: false },
  { id: 28, name: 'Meat', source: 'Carries_MEAT', table: 'census_master', checked: false },
  { id: 29, name: 'Motor Vehicle', source: 'Carries_MOTOVEH', table: 'census_master', checked: false },
  { id: 30, name: 'Paper Product', source: 'Carries_PAPERPROD', table: 'census_master', checked: false },
  { id: 31, name: 'US Mail', source: 'Carries_USMAIL', table: 'census_master', checked: false },
  { id: 32, name: 'Building Materials', source: 'carries_bldgmat', table: 'census_master', checked: false },
  { id: 33, name: 'Commodities Dry Bulk', source: 'carries_drybulk', table: 'census_master', checked: false },
  { id: 34, name: 'Farm Supplies', source: 'Carries_FARMSUPP', table: 'census_master', checked: false },
  { id: 35, name: 'Garbage Refuse Trash', source: 'Carries_GARBAGE', table: 'census_master', checked: false },
  { id: 36, name: 'Intermodal Cont', source: 'Carries_INTERMODAL', table: 'census_master', checked: false },
  { id: 37, Name: 'Logs Poles Beams Lumber', source: 'Carries_LOGPOLE', table: 'census_master', checked: false },
  { id: 38, name: 'Metal Sheets Coil Rolls', source: 'Carries_METALSHEET', table: 'census_master', checked: false },
  { id: 39, name: 'Oil Field Equipment', source: 'Carries_OILFIELD', table: 'census_master', checked: false },
  { id: 40, name: 'Passengers', source: 'Carries_PASSENGERS', table: 'census_master', checked: false },
  { id: 41, name: 'Utility', source: 'Carries_UTILITY', table: 'census_master', checked: false },
  { id: 42, name: 'Chemicals', source: 'Carries_CHEM', table: 'census_master', checked: false },
  { id: 43, name: 'Construction', source: 'Carries_CONSTRUCT', table: 'census_master', checked: false },
  { id: 44, name: 'Fresh Produce', source: 'Carries_PRODUCE', table: 'census_master', checked: false },
  { id: 45, name: 'General Freight', source: 'Carries_GENFREIGHT', table: 'census_master', checked: false },
  { id: 46, name: 'Liquid Gas', source: 'Carries_LIQGAS', table: 'census_master', checked: false },
  { id: 47, name: 'Machinery Large Objects', source: 'Carries_MACHLRG', table: 'census_master', checked: false },
  { id: 48, name: 'Mobile Homes', source: 'Carries_MOBILEHOME', table: 'census_master', checked: false },
  { id: 49, name: 'Cargo Other', source: 'Carries_CARGOOTHER', table: 'census_master', checked: false },
  { id: 50, name: 'Refrigerated Food', source: 'Carries_COLDFOOD', table: 'census_master', checked: false },
  { id: 51, name: 'Water Well', source: 'Carries_WATERWELL', table: 'census_master', checked: false },
  { id: 52, name: 'Power Units', checked: false },// --NEED TABLE UPDATE
  { id: 53, name: 'Buses', source: 'BUS_UNITS', table: 'census_master', checked: false },
  { id: 54, name: 'BusOwn', source: 'OWNBUS_16', table: 'census_master', checked: false },
  { id: 55, name: 'BusLease', checked: false },//--LOGIC REQUIRED
  { id: 56, name: 'Trucks', source: 'TRUCK_UNITS', table: 'census_master', checked: false },
  { id: 57, name: 'TruckOwn', source: 'OWNTRUCK', table: 'census_master', checked: false },
  { id: 58, name: 'TruckLease', checked: false },// --LOGIC REQUIRED
  { id: 59, name: 'Drivers', source: 'TOTAL_DRIVERS', table: 'census_master', checked: false },
  { id: 60, name: 'CDL Drivers', source: 'TOTAL_CDL', table: 'census_master', checked: false },
  { id: 61, name: '', checked: false },// --WOT IZZ THIS BEHAVIOR 
  { id: 62, name: 'BIPD Required', source: '', table: '', checked: false }, ////--need logic
  { id: 63, name: 'Insurer', source: 'insurance_company_name', table: 'actpendinsur_allwithhistory', checked: false },
  { id: 64, name: 'Insurance Type', source: 'insurance_type_description', table: 'actpendinsur_allwithhistory', checked: false },
  { id: 65, name: 'Filing Locale', source: '', table: '', checked: false }, ////--need logic
  { id: 66, name: 'Policy Number', source: 'policy_number', table: 'actpendinsur_allwithhistory', checked: false },
  { id: 67, name: 'Policy Effective Date', source: 'effective_date', table: 'actpendinsur_allwithhistory', checked: false },
  { id: 68, name: 'Policy Cancellation Date', source: 'cancel_effective_date', table: 'actpendinsur_allwithhistory', checked: false },
  { id: 69, name: 'Policy Expiration Month', source: '', table: '', checked: false },// --self calculation
  { id: 70, name: 'Policy Expiration Day', source: '', table: '', checked: false }, //--self calculation
  { id: 71, name: 'Policy Expiration Date', source: '', table: '', checked: false }, //--self calculation
  { id: 72, name: 'BOC3', source: '', table: '', checked: false }, ////--need logic
  { id: 73, name: 'Common Auth', source: 'common_authority', table: 'census_master', checked: false },
  { id: 74, name: 'Contract Auth', source: 'contract_authority', table: 'census_master', checked: false },
  { id: 75, name: 'Contract Auth First G', source: '', table: '', checked: false }, ////--need logic
  { id: 76, name: 'Broker Auth', source: 'broker_authority', table: 'census_master', checked: false },
  { id: 77, name: 'Common Auth First', source: '', table: '', checked: false }, ////--need logic
  { id: 78, name: 'Interstate', checked: false },
  { id: 79, name: 'Intrastate Hazmat', checked: false },
  { id: 80, name: 'Intrastate Non Hazmat', checked: false },
  { id: 81, name: 'Authorized For Hire', checked: false },
  { id: 82, name: 'Exempt For Hire', checked: false },
  { id: 83, name: 'Private Property', checked: false },
  { id: 84, name: 'Private Passenger Business', checked: false },
  { id: 85, name: 'Private Passenger Non Business', checked: false },
  { id: 86, name: 'Migrant', checked: false },
  { id: 87, name: 'US Mail Class', checked: false },
  { id: 88, name: 'Federal Gov', checked: false },
  { id: 89, name: 'Stante Gov', checked: false },
  { id: 90, name: 'Local Gov', checked: false },
  { id: 91, name: 'Other', checked: false },
  { id: 92, name: 'Passanger Auth', checked: false },
  { id: 93, name: 'Household Auth', checked: false },
  { id: 94, name: 'Hazmat', checked: false },
  { id: 95, name: 'Inspection Radius', checked: false },
  { id: 96, name: 'OSS', checked: false }
];



