import React from 'react'

const VehicleModal = ({ setShowclient, setYear, setMake, setModel, setVin, setTruckType, setStatedValue, handleSaveVehicle, showclient, Modal, year, make, model, Vin, lookupvinnumber, truckType, statedValue }) => {
    return (
        <>

            <Modal
                open={showclient}
                onCancel={() => setShowclient(false)}
                footer={null}
                closeIcon={null}
                width={600}
            >
                <div className="headerofmodal">
                    <span className="headertext">New vehicle shedule</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setShowclient(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Year:</span>
                            <input
                                type="text"
                                placeholder="Ex. 2000"
                                className="usdotinput"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Make:</span>
                            <input
                                type="text"
                                placeholder="Make"
                                className="usdotinput"
                                value={make}
                                onChange={(e) => setMake(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Model:</span>
                            <input
                                type="text"
                                placeholder="Model"
                                className="usdotinput"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                            />
                        </div>
                        <div className="leftsideinput lefter">
                            <span className="boderheader">VIN number:</span>
                            <div className="inputtext">

                                <input
                                    type="text"
                                    placeholder="Ex. 1FUJA6CK17PY50909"
                                    className="vinnumber"

                                    value={Vin} onChange={(e) => { setVin(e.target.value) }}
                                /> <button className="lookup" onClick={lookupvinnumber} >Verify</button>
                            </div>

                        </div>


                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Truck type:</span>
                            <input

                                placeholder="Truck"
                                className="usdotinput"
                                value={truckType}
                                onChange={(e) => setTruckType(e.target.value)}
                            />
                        </div>
                        <div className="rightsideinput">
                            <span className="boderheader">Stated value:</span>
                            <input

                                placeholder="Ex. 22000"
                                className="usdotinput"
                                value={statedValue}
                                onChange={(e) => setStatedValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => { setShowclient(false) }}>Cancel</span>
                        <div className="submitbutton" onClick={handleSaveVehicle}>
                            Save vehicle
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default VehicleModal