import { Modal } from "antd";
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
import axios from 'axios';
import { BASE_URL } from "../../Constants/BaseUrl";
import fedralAuthoritytore from "../../Redux/Stores/Leads/fedralAuthority"
const FedaralAuthority = ({ isauthority, setIsauthority, usdots, setUsdots }) => {
    const {checkedBoxes,handleChange,toggleValue,toggleActive}=fedralAuthoritytore()
    const getData = async (q) => {
        try {
            const response = await axios.post(`${BASE_URL}/getData`, { query: q });
            if (response.data.hasOwnProperty('result')) {
                const list = response.data['result']
                return list
            }
        } catch (error) {
            return []
        }
    }
    // const handleApply = () => {
    //     const mc = checkedBoxes.mc.checked ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) = 'MC')` : checkedBoxes.mc.active ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) != 'MC')` : ``
    //     const ff = checkedBoxes.ff.checked ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) = 'FF')` : checkedBoxes.ff.active ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) != 'FF')` : ``
    //     const mx = checkedBoxes.mx.checked ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) = 'MX')` : checkedBoxes.mx.active ? `AND ((b.common_authority = 'A' or b.contract_authority = 'A' or b.broker_authority ='A') and Left(b.docket_number,2) != 'MX')` : ``
    //     const hhg = checkedBoxes.hhg.checked ? `AND (b.household_goods = 'Y')` : checkedBoxes.hhg.active ? `AND (b.household_goods != 'Y')` : ``
    //     const passenger = checkedBoxes.passenger.checked ? `AND (b.passenger = 'Y')` : checkedBoxes.passenger.active ? `AND (b.passenger != 'Y')` : ``
    //     const property = checkedBoxes.property.checked ? `AND (b.private_check = 'Y')` : checkedBoxes.property.active ? `AND (b.private_check != 'Y')` : ``
    //     const enterprise = checkedBoxes.enterprise.checked ? `ANd (b.enterprise_check = 'Y')` : checkedBoxes.enterprise.active ? `AND (b.enterprise_check != 'Y')` : ``
    //     const common = checkedBoxes.common == '1' ? `AND (b.common_authority = 'A' or b.pending_common_authority = 'Y')` : checkedBoxes.common == '2' ? `AND b.common_authority = 'I'` : checkedBoxes.common == '3' ? `AND b.common_authority = 'N'` : ``
    //     const contract = checkedBoxes.contract == '1' ? `AND (b.Contract_authority = 'A' or b.pending_contract_authority = 'Y')` : checkedBoxes.contract == '2' ? `AND b.Contract_authority = 'I'` : checkedBoxes.contract == '3' ? `AND b.Contract_authority = 'N'` : ``
    //     const broker = checkedBoxes.broker == '1' ? `AND (b.broker_authority 'A' or b.pending_broker_authority = 'Y')` : checkedBoxes.broker == '2' ? `AND b.broker_authority = 'I'` : checkedBoxes.broker == '3' ? `AND b.broker_authority = 'N'` : ``
    //     const revoc = revocationDays == 0 ? `` : `AND (c.orignal_authority_action_description = 'INVOLUNTARY REVOCATION' and cast(datediff(day, c.orignal_authority_action_served_date, getdate()) as int) <= `+revocationDays+`)`
    //     const reins = reinstatedDays == 0 ? `` : `AND (c.orignal_authority_action_description = 'REINSTATED' and cast(datediff(day, c.orignal_authority_action_served_date, getdate()) as int) <= `+reinstatedDays+`)`
    //     let query = mc+' '+ff+' '+mx+' '+hhg+' '+passenger+' '+property+' '+enterprise+' '+common+' '+contract+' '+broker+' '+revoc+' '+reins
    //     setUsdots({query: query})
    //     setIsauthority(false)
    // }
    const handleApply = () => {
        const mc = checkedBoxes.mc.checked ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) = 'MC')` : checkedBoxes.mc.active ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) != 'MC')` : ``
        const ff = checkedBoxes.ff.checked ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) = 'FF')` : checkedBoxes.ff.active ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) != 'FF')` : ``
        const mx = checkedBoxes.mx.checked ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) = 'MX')` : checkedBoxes.mx.active ? `AND ((a.common_authority = 'A' or a.contract_authority = 'A' or a.broker_authority ='A') and Left(a.docket_number,2) != 'MX')` : ``
        const hhg = checkedBoxes.hhg.checked ? `AND (a.Carries_HOUSEHOLD = 'Y')` : checkedBoxes.hhg.active ? `AND (a.Carries_HOUSEHOLD != 'Y')` : ``
        const passenger = checkedBoxes.passenger.checked ? `AND (a.passenger = 'Y')` : checkedBoxes.passenger.active ? `AND (a.passenger != 'Y')` : ``
        const property = checkedBoxes.property.checked ? `AND (a.private_check = 'Y')` : checkedBoxes.property.active ? `AND (a.private_check != 'Y')` : ``
        const enterprise = checkedBoxes.enterprise.checked ? `ANd (a.enterprise_check = 'Y')` : checkedBoxes.enterprise.active ? `AND (a.enterprise_check != 'Y')` : ``
        const common = checkedBoxes.common == '1' ? `AND (a.common_authority = 'A' or a.pending_common_authority = 'Y')` : checkedBoxes.common == '2' ? `AND a.common_authority = 'I'` : checkedBoxes.common == '3' ? `AND a.common_authority = 'N'` : ``
        const contract = checkedBoxes.contract == '1' ? `AND (a.Contract_authority = 'A' or a.pending_contract_authority = 'Y')` : checkedBoxes.contract == '2' ? `AND a.Contract_authority = 'I'` : checkedBoxes.contract == '3' ? `AND a.Contract_authority = 'N'` : ``
        const broker = checkedBoxes.broker == '1' ? `AND (a.broker_authority 'A' or a.pending_broker_authority = 'Y')` : checkedBoxes.broker == '2' ? `AND a.broker_authority = 'I'` : checkedBoxes.broker == '3' ? `AND a.broker_authority = 'N'` : ``
        const revoc = revocationDays == 0 ? `` : `AND (r.orignal_authority_action_description = 'INVOLUNTARY REVOCATION' and cast(datediff(day, r.orignal_authority_action_served_date, getdate()) as int) <= `+revocationDays+`)`
        const reins = reinstatedDays == 0 ? `` : `AND (r.orignal_authority_action_description = 'REINSTATED' and cast(datediff(day, r.orignal_authority_action_served_date, getdate()) as int) <= `+reinstatedDays+`)`
        let query = mc+' '+ff+' '+mx+' '+hhg+' '+passenger+' '+property+' '+enterprise+' '+common+' '+contract+' '+broker+' '+revoc+' '+reins
        setUsdots({query: query})
        setIsauthority(false)
    }

const [revocationDays, setRevocationDays]=useState(0)
const [reinstatedDays, setReinstatedDays]=useState(0)
    return (
        <>
            <Modal
                wrapClassName="modalofall"
                open={isauthority}
                onCancel={() => setIsauthority(false)}
                footer={null}
                width={1104}
                closeIcon={null}
                centered={false}
            >
                <div className="headerofmodal">
                    <span className="headertext">Federal Authority</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setIsauthority(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="WraperofModal wrapperof">
                    <div className="coverfor_bottom_section">
                        <div className="coverof_Authority">
                            <div className="First_item">
                                <div className="cardcomponent">
                                    <div className="card-header">
                                        Operation authority
                                    </div>
                                    <div className="row_wrapper_COVER">
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                MC
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.mc?.checked}
                                                    onChange={(event) => handleChange("mc")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.mc?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("mc")}
                                                >
                                                    {checkedBoxes?.mc?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                FF
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.ff?.checked}
                                                    onChange={(event) => handleChange("ff")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.ff?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("ff")}
                                                >
                                                    {checkedBoxes?.ff?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="itemof_covers_Authority_end">
                                            <div className="tag_coverside">
                                                MX
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.mx?.checked}
                                                    onChange={(event) => handleChange("mx")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.mx?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("mx")}
                                                >
                                                    {checkedBoxes?.mx?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="First_item">
                                <div className="cardcomponent">
                                    <div className="card-header">
                                        Operation type
                                    </div>
                                    <div className="row_wrapper_COVER">
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                HHG
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.hhg?.checked}
                                                    onChange={() => handleChange("hhg")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.hhg?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("hhg")}
                                                >
                                                    {checkedBoxes?.hhg?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                Passenger
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.passenger?.checked}
                                                    onChange={() => handleChange("passenger")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.passenger?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("passenger")}
                                                >
                                                    {checkedBoxes?.passenger?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                Property
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.property?.checked}
                                                    onChange={() => handleChange("property")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.property?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("property")}
                                                >
                                                    {checkedBoxes?.property?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="itemof_covers_Authority">
                                            <div className="tag_coverside">
                                                Enterprise
                                            </div>
                                            <div className="tag_cover">
                                                <Checkbox
                                                    checked={checkedBoxes?.enterprise?.checked}
                                                    onChange={(event) => handleChange("enterprise")}
                                                />

                                                <button
                                                    className={`toggle-btn ${checkedBoxes?.enterprise?.active ? 'active' : ''}`}
                                                    onClick={() => toggleActive("enterprise")}
                                                >
                                                    {checkedBoxes?.enterprise?.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : null}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cardcomponent">
                            <div className="card-header">
                                Revocation/ Reinstatement actions
                            </div>
                            <div className="individual_cover newind">
                                Include carriers that have had a reported revocation action in the last   <input className="daysinput" onChange={(e)=>{setRevocationDays(e.target.value)}} type="number"/>  days.
                            </div>
                            <div className="individual_cover newind">
                                Include carriers that have had their authority reinstated in the last   <input className="daysinput" onChange={(e)=>{setReinstatedDays(e.target.value)}} type="number"/>   days.
                            </div>
                        </div>
                    </div>
                    <div className="cardcomponent" style={{ width: "100%" }}>
                        <div className="card-header">
                            Authorities
                        </div>
                        <div className="Wrapper_Authority">
                            <div className="titleof_span">
                                Common
                            </div>
                            <div className="licontainer">
                                <ul>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.common == '1'}
                                            onChange={() => toggleValue('common', '1')}
                                            style={{ padding: '0px' }}
                                        />
                                        Active/ Pending
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.common == '2'}
                                            onChange={() => toggleValue('common', '2')}
                                            style={{ padding: '0px' }}
                                        />
                                        Inactive
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.common == '3'}
                                            onChange={() => toggleValue('common', '3')}
                                            style={{ padding: '0px' }}
                                        />
                                        None
                                    </li>
                                </ul>
                            </div>
                            <div className="titleof_span">
                                Contract
                            </div>
                            <div className="licontainer">
                                <ul>
                                <li>
                                        <Checkbox
                                            checked={checkedBoxes.contract == '1'}
                                            onChange={() => toggleValue('contract', '1')}
                                            style={{ padding: '0px' }}
                                        />
                                        Active/ Pending
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.contract == '2'}
                                            onChange={() => toggleValue('contract', '2')}
                                            style={{ padding: '0px' }}
                                        />
                                        Inactive
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.contract == '3'}
                                            onChange={() => toggleValue('contract', '3')}
                                            style={{ padding: '0px' }}
                                        />
                                        None
                                    </li>
                                </ul>
                            </div>
                            <div className="titleof_span">
                                Broker
                            </div>
                            <div className="licontainer">
                                <ul>
                                <li>
                                        <Checkbox
                                            checked={checkedBoxes.broker == '1'}
                                            onChange={() => toggleValue('broker', '1')}
                                            style={{ padding: '0px' }}
                                        />
                                        Active/ Pending
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.broker == '2'}
                                            onChange={() => toggleValue('broker', '2')}
                                            style={{ padding: '0px' }}
                                        />
                                        Inactive
                                    </li>
                                    <li>
                                        <Checkbox
                                            checked={checkedBoxes.broker == '3'}
                                            onChange={() => toggleValue('broker', '3')}
                                            style={{ padding: '0px' }}
                                        />
                                        None
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: 'pointer' }} onClick={() => setIsauthority(false)}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={handleApply}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FedaralAuthority;
