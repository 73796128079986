import "./nav.css";
import { useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import SalesNav from "./SalesNav";
import HomeNav from "./HomeNav";
import AppRoutes from "../Routes/AppRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';
import { SERVER_URL } from "../Constants/BaseUrl";
import axios from "axios";

const Navbar = () => {
  const [activeNav, setActiveNav] = useState("All Categories");
  const [isHovered, setIsHovered] = useState(false);
  const [navbarLocation, setNavbarLocation] = useState("");
  const { sales } = useParams();
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setNavbarLocation(sales);
  }, [sales]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [freetrail, setFreetrail] = useState({
    istrue: false,
    dateofexpiry: ""
  });
  
  useEffect(() => {
    const userId = localStorage.getItem('userId');
  
    const fetchUserData = async () => {
      try {
        if (!userId) {
          console.error('User ID not found in localStorage');
          return;
        }
  
        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        const userData = userResponse.data.data;
  
        if (userData.freeTrial) {
          const expiryDate = new Date(userData.freeTrialEndDate);
          const currentDate = new Date();
          
          // Calculate the difference in time and convert to days
          const timeDifference = expiryDate - currentDate;
          const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
          // Update freetrail state with days remaining
          setFreetrail({
            istrue: true,
            dateofexpiry: daysRemaining > 0 ? `${daysRemaining}` : "Expired"
          });
        } else {
          setFreetrail({
            istrue: false,
            dateofexpiry: ""
          });
        }
  
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle additional error logic if needed
      }
    };
  
    fetchUserData();
  }, []);
  
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsHovered(false);
    }
  };

  const handleNavClick = (navItem) => {
    setActiveNav(navItem);
  };

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const showDefaultDrawer = () => {
    setOpen(true);
  };

  const dropClick = () => {
    setIsHovered((prev) => !prev);
  };

  // Check if user is on login route
  const isLoginRoute = location.pathname === "/login";

  return (
    <>
    
    {!isLoginRoute ? (<>
    
    
   
  
    <div className="navcover">
     
        <>
      
  <>
    {navbarLocation === "home" ? (
      <HomeNav
        setNavbarLocation={setNavbarLocation}
        open={open}
        onClose={onClose}
      />
    ) : (
      <SalesNav
        activeNav={activeNav}
        handleNavClick={handleNavClick}
        setNavbarLocation={setNavbarLocation}
        open={open}
        onClose={onClose}
      />
    )}
  </>


          <div className="allroutings">
          
            <>
            <div className="topnav">
              <div className="pagelocation">
                <div className="primarybuttonfornav" onClick={showDefaultDrawer}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.25 20H33.75"
                      stroke="#374147"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.25 10H33.75"
                      stroke="#374147"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.25 30H33.75"
                      stroke="#374147"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {activeNav === "All Categories" ? (
                  <div className="sales">Sales</div>
                ) : (
                  <>
                    <span className="salessub">Sales</span>{" "}
                    <div className="sales">{activeNav}</div>
                  </>
                )}
              </div>

              <div className="right_nav_profile">
                {
freetrail.istrue ? (     <div className="forfreetrail">
  You have {freetrail.dateofexpiry} days left in your trial
  </div>):(null)
                }
           
                <div className="bellpart">
                  <div className="bell">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.39038 8.75C4.39038 8.01337 4.53547 7.28395 4.81737 6.60339C5.09926 5.92282 5.51245 5.30445 6.03333 4.78357C6.5542 4.26269 7.17258 3.84951 7.85314 3.56761C8.5337 3.28572 9.26312 3.14062 9.99975 3.14062C10.7364 3.14062 11.4658 3.28572 12.1464 3.56761C12.8269 3.84951 13.4453 4.26269 13.9662 4.78357C14.4871 5.30445 14.9002 5.92282 15.1821 6.60339C15.464 7.28395 15.6091 8.01337 15.6091 8.75V8.75C15.6091 11.5469 16.1951 13.1719 16.7107 14.0625C16.7655 14.1574 16.7943 14.2649 16.7944 14.3745C16.7945 14.484 16.7658 14.5916 16.7112 14.6865C16.6566 14.7815 16.5781 14.8604 16.4834 14.9154C16.3886 14.9705 16.2811 14.9996 16.1716 15H3.82788C3.71835 14.9996 3.61085 14.9705 3.51615 14.9154C3.42144 14.8604 3.34286 14.7815 3.28826 14.6865C3.23367 14.5916 3.20498 14.484 3.20508 14.3745C3.20518 14.2649 3.23405 14.1574 3.28881 14.0625C3.80444 13.1719 4.39038 11.5469 4.39038 8.75Z"
                        stroke="#374147"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 15V15.625C7.5 16.288 7.76339 16.9239 8.23223 17.3928C8.70107 17.8616 9.33696 18.125 10 18.125C10.663 18.125 11.2989 17.8616 11.7678 17.3928C12.2366 16.9239 12.5 16.288 12.5 15.625V15"
                        stroke="#374147"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.3281 1.875C15.6007 2.67829 16.6277 3.81615 17.2969 5.16406"
                        stroke="#374147"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.70312 5.16406C3.37228 3.81615 4.39933 2.67829 5.67188 1.875"
                        stroke="#374147"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                  </div>
                  <div className="content_bell mt-1">What’s new?</div>
                  <div className="reddot">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle cx="6" cy="6" r="6" fill="#FE2727" />
                    </svg>
                  </div>
                  <div className="reddot2"></div>
                </div>
                <div className="bellpart" onClick={dropClick} ref={dropdownRef}>
                  <div className="bell">
                  <div className="dropdown">
      <div
        className="dropdown-toggle content_bell "

        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Profile
      </div>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><Link className="dropdown-item linkdro" to="/home/myinfo"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V15.625C2.5 15.9702 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 15.9702 17.5 15.625V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.69531 16.25C5.08248 15.1524 5.80061 14.2019 6.7507 13.5295C7.7008 12.8572 8.83607 12.4961 10 12.4961C11.1639 12.4961 12.2992 12.8572 13.2493 13.5295C14.1994 14.2019 14.9175 15.1524 15.3047 16.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> My informations</Link></li>
        <li><Link className="dropdown-item linkdro" to="#"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> My emails</Link></li>
        <li><Link className="dropdown-item linkdro" to="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.422 6.30469C15.61 6.57396 15.7747 6.85886 15.9141 7.15625L17.9376 8.28125C18.1902 9.41287 18.1929 10.586 17.9454 11.7187L15.9141 12.8438C15.7747 13.1411 15.61 13.426 15.422 13.6953L15.461 16.0156C14.6035 16.7971 13.5885 17.3858 12.4845 17.7422L10.4923 16.5469C10.1646 16.5703 9.8356 16.5703 9.5079 16.5469L7.52352 17.7344C6.41595 17.3845 5.39766 16.798 4.53915 16.0156L4.57821 13.7031C4.3917 13.4301 4.22712 13.1428 4.08602 12.8438L2.06258 11.7187C1.80993 10.5871 1.80726 9.41401 2.05477 8.28125L4.08602 7.15625C4.22546 6.85886 4.39013 6.57396 4.57821 6.30469L4.53915 3.98438C5.39669 3.20294 6.41163 2.61422 7.51571 2.25781L9.5079 3.45313C9.8356 3.42968 10.1646 3.42968 10.4923 3.45313L12.4766 2.26563C13.5842 2.61554 14.6025 3.20199 15.461 3.98438L15.422 6.30469Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Settings</Link></li>
        <li><Link className="dropdown-item linkdro" to="/logout"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M13.5938 6.71875L16.875 10L13.5938 13.2812" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.125 10H16.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.125 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H8.125" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Logoff</Link></li>

      </ul>
    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </>
      
           

      
  
  <div className="routers">
    <AppRoutes />
  </div>



           
          </div>
        </>
    
    </div> 
    </>):(<>
    <AppRoutes/>
    </>)}
     </>
  );
};

export default Navbar;
