const RejectedInsurance = () => {
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Active/ Pending insurance
                </div>
                <div className="compinfo">
                    <div className="coverofcompinfo  ">
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Form:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Coverage from:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>

                        </div>
                        <div className="infosection">

                            <div className="target">
                                <span className="titleofinfo">
                                    Type:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Coverage to:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>

                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Insurance carrier:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Received date:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>

                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Policy/ Surety:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Rejected date:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default RejectedInsurance;