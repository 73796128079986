import React from 'react';
import { Tag } from 'antd';
import businessLengthstore from "../../Redux/Stores/Leads/businessLength";

const TagList = ({ 
  path, 
  insuracne, 
  date, 
  filteredKeys, 
  filteredKeys1, 
  filteredKeys2, 
  checkboxes, 
  checkboxes1, 
  checkedBoxes, 
  handleRemove, 
  handleRemoveInsurance, 
  removeInsuranceDates1, 
  removeFieldState, 
  removeFieldState1, 
  removeFieldState2 
}) => {
  const { from, to, input, input1, clearStates } = businessLengthstore();

  return (
    <div className='tags'>
      {path.map((state) => (
        <Tag
          key={state}
          className="selectedStateTag"
          closable
          onClose={() => handleRemove(state)}
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          <b>State:</b> {state}
        </Tag>
      ))}

      {insuracne?.map((insurance) => (
        <Tag
          key={insurance}
          className="selectedStateTag"
          closable
          onClose={() => handleRemoveInsurance(insurance)}
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          <b>Insurance:</b> {insurance}
        </Tag>
      ))}

      {date !== "" && (
        <Tag
          key={date}
          closable
          onClose={removeInsuranceDates1}
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          {date}
        </Tag>
      )}

      {/* Display from and to dates */}
      {(from && to) && (
        <Tag
          key="date-range"
          closable
          onClose={clearStates}
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          <b>BusinessLength:</b> From {from} To {to}
        </Tag>
      )}

      {/* Conditionally render input and input1 */}
      {input && !input1 && (
        <Tag
          key="input-range"
          closable
          onClose={clearStates} // Clear states if needed
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          <b>BusinessLength:</b> {input} days
        </Tag>
      )}

      {input1 && !input && (
        <Tag
          key="input1-range"
          closable
          onClose={clearStates} // Clear states if needed
          style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
        >
          <b>BusinessLength:</b> Authorized activated {input1} days
        </Tag>
      )}

      {filteredKeys?.map((key) => (
        <Tag
          key={key}
          closable
          onClose={() => removeFieldState(key)}
          style={{
            backgroundColor: checkboxes[key]?.checked ? "rgba(12, 148, 228, 0.1)" : checkboxes[key]?.active ? "#FE27271A" : "initial",
            border: checkboxes[key]?.checked ? "1px solid #0C94E4" : checkboxes[key].active ? "1px solid #FE2727" : "initial",
          }}
        >
          <b>Commodities:</b> {key}
        </Tag>
      ))}

      {filteredKeys1?.map((key) => (
        <Tag
          key={key}
          closable
          onClose={() => removeFieldState1(key)}
          style={{
            backgroundColor: checkboxes1[key]?.checked ? "rgba(12, 148, 228, 0.1)" : checkboxes1[key]?.active ? "#FE27271A" : "initial",
            border: checkboxes1[key]?.checked ? "1px solid #0C94E4" : checkboxes1[key].active ? "1px solid #FE2727" : "initial",
          }}
        >
          <b>Operations:</b> {key}
        </Tag>
      ))}

      {filteredKeys2?.map((key) => (
        <Tag
          key={key}
          closable
          onClose={() => removeFieldState2(key)}
          style={{
            backgroundColor: checkedBoxes[key]?.checked ||
            (key === 'common' && checkedBoxes['common'] === '1') ||
            (key === 'contract' && checkedBoxes['contract'] === '1') ||
            (key === 'broker' && checkedBoxes['broker'] === '1')
            ? "rgba(12, 148, 228, 0.1)" : checkedBoxes[key]?.active ||
            (key === 'common' && checkedBoxes['common'] === '2') ||
            (key === 'contract' && checkedBoxes['contract'] === '2') ||
            (key === 'broker' && checkedBoxes['broker'] === '2') ? "#FE27271A" : "initial",
            border: checkedBoxes[key]?.checked ||
            (key === 'common' && checkedBoxes['common'] === '1') ||
            (key === 'contract' && checkedBoxes['contract'] === '1') ||
            (key === 'broker' && checkedBoxes['broker'] === '1') ? "1px solid #0C94E4" : checkedBoxes[key]?.active ||
            (key === 'common' && checkedBoxes['common'] === '2') ||
            (key === 'contract' && checkedBoxes['contract'] === '2') ||
            (key === 'broker' && checkedBoxes['broker'] === '2') ? "1px solid #FE2727" : "initial",
          }}
        >
          <b>fedralAuthority:</b> {key}
        </Tag>
      ))}
    </div>
  );
};

export default TagList;
