import { Link } from "react-router-dom"
import "./categories.css"
import CarrierMGA from "./Images/Group 16473.svg"
import ChameleonJs from "./Images/Group 17.svg"
import DeepDiveImage from "./Images/Group 17 (1).svg"
import ProspectTracker from "./Images/Group 18.svg"
import LeadsDatabase from "./Images/Group 17 (2).svg"
import TrainingManuals from "./Images/Group 17 (3).svg"
const Allcategories = () => {
    return (
        <>

            <div className="card-container " >
                <div className="cards-content">
                    <div>
                        <img className="mb-2" src={LeadsDatabase} alt="allproject" />
                    </div>
                    <span className="title-content">
                        Leads Database
                    </span>
                    <p className="desccontent">
                    Leads database boosts annual lead closures by hundreds. Tailor searches to your exact criteria and access cutting-edge leads.                     </p>
                    <Link to="/sales/leads">
                        <div className="marginabove buttonofcards">
                            Use now
                        </div>
                    </Link>
                </div>

                <div className="cards-content">
                    <div>
                        <img className="mb-2" src={ChameleonJs} alt="chamelon" />
                    </div>
                    <span className="title-content">
                        Chameleon Carrier
                    </span>
                    <p className="desccontent">
                    "Chameleon Carriers": Find companies dodging rules, ensuring compliance for profitable underwriting with advanced tech.                    </p>
                    <Link to="/sales/chameleon">
                        <div className="marginabove buttonofcards">
                            Use now
                        </div>
                    </Link>
                </div>
                <div className="cards-content">
                    <div>
                        <img className="mb-2" src={DeepDiveImage} alt="Deepdiv" />
                    </div>
                    <span className="title-content">
                        Deep drive
                    </span>
                    <p className="desccontent">
                    Access thorough motor carrier data from multiple sources for streamlined underwriting
                    &nbsp;&nbsp; experiences.                    </p>
                    <div className="marginabove diabledbutton">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5.19444V8.75L10.7778 10.5278M9 0.75C4.58172 0.75 1 4.33172 1 8.75C1 13.1683 4.58172 16.75 9 16.75C13.4183 16.75 17 13.1683 17 8.75C17 7.11436 16.5092 5.59335 15.6667 4.32628" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>         Coming Soon
                    </div>
                </div>
                <div className="cards-content">
                    <div>
                        <img className="mb-2" src={ProspectTracker} alt="prospect" />
                    </div>
                    <span className="title-content">
                        Prospect Tracker
                    </span>
                    <p className="desccontent">
                    Increase new business premium sales by hundreds. Combine other features and Prospect Tracker for streamlined sales management.                    </p>

                    <div className="marginabove diabledbutton">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5.19444V8.75L10.7778 10.5278M9 0.75C4.58172 0.75 1 4.33172 1 8.75C1 13.1683 4.58172 16.75 9 16.75C13.4183 16.75 17 13.1683 17 8.75C17 7.11436 16.5092 5.59335 15.6667 4.32628" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>         Coming Soon
                    </div>

                </div>
                <div className="cards-content">
                    <div><img className="mb-2" src={CarrierMGA} alt="carrier" />
                    </div>
                    <span className="title-content">
                    Insurance Carrier Database
                    </span>
                    <p className="desccontent">
                    Enhance data analytics with our insurance carrier database. Customize searches and identify top-used insurance carriers for optimal sales.                    </p>
                    <div className="marginabove diabledbutton">

                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5.19444V8.75L10.7778 10.5278M9 0.75C4.58172 0.75 1 4.33172 1 8.75C1 13.1683 4.58172 16.75 9 16.75C13.4183 16.75 17 13.1683 17 8.75C17 7.11436 16.5092 5.59335 15.6667 4.32628" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Coming Soon
                    </div>
                </div>
                <div className="cards-content">
                    <div>
                        <img className="mb-2" src={TrainingManuals} alt="training" />
                    </div>
                    <span className="title-content">
                    Commission Tracker
                    </span>
                    <p className="desccontent">
                    Effortlessly track and increase your agency's commissions. Our new commission tracker transforms tasks that took hours into just minutes.                    </p>
                    <div className="marginabove diabledbutton">

                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5.19444V8.75L10.7778 10.5278M9 0.75C4.58172 0.75 1 4.33172 1 8.75C1 13.1683 4.58172 16.75 9 16.75C13.4183 16.75 17 13.1683 17 8.75C17 7.11436 16.5092 5.59335 15.6667 4.32628" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Coming Soon
                    </div>
                </div>

            </div>
        </>
    )
}
export default Allcategories