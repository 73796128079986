import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import "./commentarea.css"
import { Link } from "react-router-dom";


const ComentArea=()=>{
    const [clicked, setClicked] = useState(false);
    const messageboxRef = useRef(null);
    const [selectedEmoji, setSelectedEmoji] = useState('');
    const [textAreaValue, setTextAreaValue] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      function handleClickOutside(event) {
        if (messageboxRef.current && !messageboxRef.current.contains(event.target)) {
          setClicked(false);
        }
      }
  
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);
  
    const handleClick = () => {
      setClicked(!clicked);
    };

    const handleAddEmoji = (emoji) => {
      setTextAreaValue(textAreaValue + emoji);
    };
    
    const emojis = ['😊', '😂', '😍', '🎉', '👍',"✌","😂","😝","😁","😱","👉","🙌","🍻","🔥","🌈","☀","🎈","🌹","💄","🎀","⚽","🎾","🏁","😡","👿","🐻","🐶","🐬","🐟","🍀","👀","🚗","🍎","💝","💙","👌","❤","😍","😉","😓","😳","💪","💩","🍸","🔑","💖","🌟","🎉","🌺","🎶","👠","🏈","⚾","🏆","👽","💀","🐵","🐮","🐩","🐎","💣","👃","👂","🍓","💘","💜","👊","💋","😘","😜","😵","🙏","👋","🚽","💃","💎","🚀","🌙","🎁","⛄","🌊","⛵","🏀","🎱","💰","👶","👸","🐰","🐷","🐍","🐫","🔫","👄","🚲","🍉","💛","💚"]; // Add more emojis as needed

    const handleEmojiClick = (emoji) => {
      setSelectedEmoji(emoji);
      setTextAreaValue(textAreaValue + emoji);
      setShowModal(false);
    };
    const [comment, setComment] = useState([
        {name:"Matheus",date:"12/03/2023",mention:"Lucas",text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "},
        {name:"Matheus",date:"12/03/2023",mention:"Lucas",text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "},
        {name:"Matheus",date:"12/03/2023",mention:"Lucas",text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "},
        {name:"Matheus",date:"12/03/2023",mention:"Lucas",text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "},
        {name:"Matheus",date:"12/03/2023",mention:"Lucas",text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "},       
        ]);
        const handleDelete = (index) => {
            const updatedComment = [...comment];
            updatedComment.splice(index, 1);
            setComment(updatedComment);
        };
    
        const handlePublish = () => {
            const currentDate = new Date().toLocaleDateString();
            const newComment = {
                name: "Saad",
                date: currentDate,
                mention: "Mirza",
                text: textAreaValue
            };
            setComment([...comment, newComment]);
            setTextAreaValue(''); // Clear textarea after publishing
        };
    return (
      <>
        <div className="commentareacontain">
          <div className="headercontain">
            <Link to="/carierpage">
              <span className="backtopost">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M6.25 8.125L3.125 5L6.25 1.875" stroke="#666D72" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> Back to List
              </span>
            </Link>
            <div className="iconsofcarier">
                        <div className="svgcontaindownload">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M6.71875 8.59375L10 11.875L13.2812 8.59375" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10 3.125V11.875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="svgcontaindownload2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M5 6.25V3.125H15V6.25" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 11.875H5V17.1875H15V11.875Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 13.75H2.1875V7.5C2.1875 6.8125 2.79687 6.25 3.53906 6.25H16.4609C17.2031 6.25 17.8125 6.8125 17.8125 7.5V13.75H15" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#374147"/>
                            </svg>
                        </div>
                        <div className="svgcontaindownload3  position-relative " data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M11.6953 15.3047L10.5391 17.2344C10.4824 17.3265 10.4032 17.4025 10.3088 17.4553C10.2144 17.508 10.1081 17.5357 10 17.5357C9.89189 17.5357 9.78558 17.508 9.69121 17.4553C9.59684 17.4025 9.51756 17.3265 9.46094 17.2344L8.30469 15.3047C8.2503 15.2121 8.17269 15.1352 8.07952 15.0818C7.98635 15.0284 7.88084 15.0002 7.77344 15H3.125C2.95924 15 2.80027 14.9342 2.68306 14.8169C2.56585 14.6997 2.5 14.5408 2.5 14.375V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V14.375C17.5 14.5408 17.4342 14.6997 17.3169 14.8169C17.1997 14.9342 17.0408 15 16.875 15H12.2266C12.1192 15.0002 12.0137 15.0284 11.9205 15.0818C11.8273 15.1352 11.7497 15.2121 11.6953 15.3047V15.3047Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10 10.3125C10.5178 10.3125 10.9375 9.89277 10.9375 9.375C10.9375 8.85723 10.5178 8.4375 10 8.4375C9.48223 8.4375 9.0625 8.85723 9.0625 9.375C9.0625 9.89277 9.48223 10.3125 10 10.3125Z" fill="#374147"/>
                                <path d="M6.25 10.3125C6.76777 10.3125 7.1875 9.89277 7.1875 9.375C7.1875 8.85723 6.76777 8.4375 6.25 8.4375C5.73223 8.4375 5.3125 8.85723 5.3125 9.375C5.3125 9.89277 5.73223 10.3125 6.25 10.3125Z" fill="#374147"/>
                                <path d="M13.75 10.3125C14.2678 10.3125 14.6875 9.89277 14.6875 9.375C14.6875 8.85723 14.2678 8.4375 13.75 8.4375C13.2322 8.4375 12.8125 8.85723 12.8125 9.375C12.8125 9.89277 13.2322 10.3125 13.75 10.3125Z" fill="#374147"/>
                            </svg>  <span class="position-absolute top-1 start-100 translate-middle reddoted">
                                <span class="visually-hidden">New alerts</span>
                            </span>
                        </div>
                    </div>
                </div>

          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
              <h5 id="offcanvasRightLabel">Comments</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <div 
                ref={messageboxRef}
                className="messagebox" 
                style={{ 
                  border: clicked ? '2px solid #0C94E4' : '2px solid #DFE1E2',
                  cursor: 'pointer'
                }} 
                onClick={handleClick}
              >
                <div className='greyarea'></div>
                <textarea 
                  onChange={(e) => setTextAreaValue(e.target.value)}
                  className='textareaforcomment'
                  placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
                  cols="46" 
                  rows="3"
                  value={textAreaValue} // Set the value of textarea
                ></textarea>
                <div className='attachitems'>
                  <div className='attachicons'>
                    <div className='addfile'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M7.10923 5.36768L7.10923 11.3357C7.10923 11.5618 7.19905 11.7787 7.35894 11.9386C7.51883 12.0985 7.73568 12.1883 7.9618 12.1883C8.18792 12.1883 8.40477 12.0985 8.56466 11.9386C8.72455 11.7787 8.81438 11.5618 8.81438 11.3357L8.81438 3.66252C8.81438 2.746 8.05239 2 7.10923 2C6.16606 2 5.40407 2.746 5.40407 3.66252L5.40407 11.4423C5.40407 12.1206 5.67355 12.7712 6.15321 13.2509C6.63288 13.7305 7.28345 14 7.9618 14C8.64015 14 9.29072 13.7305 9.77039 13.2509C10.2501 12.7712 10.5195 12.1206 10.5195 11.4423L10.5195 5.47425" stroke="#374147" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> Add file
                    </div>
                    <div className='addfile' onClick={() => setShowModal(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.75 7.5C6.16421 7.5 6.5 7.16421 6.5 6.75C6.5 6.33579 6.16421 6 5.75 6C5.33579 6 5 6.33579 5 6.75C5 7.16421 5.33579 7.5 5.75 7.5Z" fill="#374147"/>
                                            <path d="M10.25 7.5C10.6642 7.5 11 7.16421 11 6.75C11 6.33579 10.6642 6 10.25 6C9.83579 6 9.5 6.33579 9.5 6.75C9.5 7.16421 9.83579 7.5 10.25 7.5Z" fill="#374147"/>
                                            <path d="M10.6004 9.5C10.3361 9.95537 9.95679 10.3333 9.5005 10.5961C9.04421 10.8588 8.52692 10.9971 8.00039 10.9971C7.47386 10.9971 6.95657 10.8588 6.50028 10.5961C6.04399 10.3333 5.66471 9.95537 5.40039 9.5" stroke="#374147" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> Emoji
                                        
                    </div>
                    <div className='addfile'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M7.10923 5.36768L7.10923 11.3357C7.10923 11.5618 7.19905 11.7787 7.35894 11.9386C7.51883 12.0985 7.73568 12.1883 7.9618 12.1883C8.18792 12.1883 8.40477 12.0985 8.56466 11.9386C8.72455 11.7787 8.81438 11.5618 8.81438 11.3357L8.81438 3.66252C8.81438 2.746 8.05239 2 7.10923 2C6.16606 2 5.40407 2.746 5.40407 3.66252L5.40407 11.4423C5.40407 12.1206 5.67355 12.7712 6.15321 13.2509C6.63288 13.7305 7.28345 14 7.9618 14C8.64015 14 9.29072 13.7305 9.77039 13.2509C10.2501 12.7712 10.5195 12.1206 10.5195 11.4423L10.5195 5.47425" stroke="#374147" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> Mention
                    </div>
                 
                  </div>
                  <div className='publish' onClick={handlePublish}>
    Publish
    </div>
    
                </div>
                <hr className='margintop'></hr>
                {
                    comment.slice().reverse().map((coment,index)=>{
                        const reversedIndex = comment.length - index - 1;
                        return(
                            <>
                               <div className='namecontent'>
                                <div className='coverofname'>
                                     <div className='profileimage'>
                                    M
                                </div><span className='nameofperson'>
                                    {coment.name} </span>
                                </div>
                                <div className='deletesection'>
                                    <div className='dateofcomment'>
                                        {coment.date}
                                    </div>
                                    
                                    <div className='deletebutton' onClick={() => handleDelete(reversedIndex)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M13.5 3.5H2.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg>   Delete
                                    </div>
                                </div>
                               
                                   
                                </div>
                                <div className='mentionsection'>
                                    {coment.mention}
                                </div>
                                <p className='comentdesc'>
                                    {coment.text}<span className='mentionattest'>@{coment.mention}</span>
                                </p>
                                <hr></hr>
                            </>
                        )
                     
                    })
                }
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Emoji Picker */}
        <Modal
          title={null}
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          getContainer={() => document.getElementById('offcanvasRight')} // Mount modal inside offcanvas
        >
          <div className='card-header'>
            Select Emoji
          </div>
          <div className='emoji-popup p-3'>
            {emojis.map((emoji, index) => (
              <span
                key={index}
                onClick={() => handleEmojiClick(emoji)}
                className='emoji-item'
              >
                {emoji}
              </span>
            ))}
          </div>
        </Modal>
      </>
    );
}

export default ComentArea;
