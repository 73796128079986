import { Modal } from "antd";
import { useState, useRef } from "react";

const LossrunsModal = (
    {
        setCommodal,
        coverage,
        commodal,
        setCoverage,
        effectivedate,
        setEffectivedate,
        valueddate,
        setValueddate,
        numberofaccidents,
        setnumberofaccidents,
        priceofaccident,
        setPriceofaccident,
        handleButtonClick,
        fileInputRef,
        handleFileChange,
        editIndex,
        updateDriver,
        addDriver

    }

) => {
    return (
        <>

            <Modal
                open={commodal}
                onCancel={() => setCommodal(false)}
                footer={null}
                closeIcon={null}
                width={600}
            >
                <div className="headerofmodal">
                    <span className="headertext">New loss runs</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setCommodal(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Coverage:</span>
                            <input
                                type="text"
                                placeholder="Ex. 2000"
                                className="usdotinput"
                                value={coverage}
                                onChange={(e) => { setCoverage(e.target.value) }}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Effective date:</span>


                            <input
                                type="date"
                                placeholder="Make"
                                className="usdotinput"
                                value={effectivedate}
                                onChange={(e) => { setEffectivedate(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Valued date:</span>
                            <input
                                type="date"
                                placeholder="Ex. 2000"
                                className="usdotinput"
                                value={valueddate}
                                onChange={(e) => { setValueddate(e.target.value) }}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Number of accidents:</span>


                            <input
                                type="text"
                                placeholder="Make"
                                className="usdotinput"
                                value={numberofaccidents}
                                onChange={(e) => { setnumberofaccidents(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Price of accident</span>
                            <input
                                type="text"
                                placeholder="Ex. 2000"
                                className="usdotinput"
                                value={priceofaccident}
                                onChange={(e) => { setPriceofaccident(e.target.value) }}
                            />
                        </div>
                        <div className="rightsideinput leftsideinput">
                            <span className="boderheader">Loss Run PDF:</span>
                            <div className="usdotinput fileinput">
                                <button className="custom-upload-button" onClick={handleButtonClick}>
                                    Upload
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => { setCommodal(false) }}>Cancel</span>
                        <div className="submitbutton" onClick={editIndex === -1 ? addDriver : updateDriver}>
                            {editIndex === -1 ? 'Save driver' : 'Update driver'}
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}
export default LossrunsModal;