const CarrierDetails = () => {
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Carrier details
                </div>
                <div className="compinfo">
                    <div className="coverofcompinfo  ">
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    USDOT Number:
                                </span>
                                <span className="descofinfo">
                                    3986854
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Business address:
                                </span>
                                <span className="descofinfo">
                                    12733 Starfood RD STE 300
                                    Stafford, TX 27477
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Undeliverable mail:                                            </span>
                                <span className="descofinfo">
                                    No
                                </span>
                            </div>
                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Docket number:
                                </span>
                                <span className="descofinfo">
                                    MC1494472
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Business telephone and fax:
                                </span>
                                <span className="descofinfo">
                                    6267318878
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Fillial:
                                </span>
                                <span className="descofinfo">
                                    4622 Shipmans Cove BlVD
                                    Fresno, TX 77545-2173
                                </span>
                            </div>
                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Legal name:
                                </span>
                                <span className="descofinfo">
                                    Golden eagle USA INC
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Mail address:
                                </span>
                                <span className="descofinfo">
                                    12733 Starfood RD STE 300
                                    Stafford, TX 27477
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    <br></br>
                                </span>
                                <span className="descofinfo">
                                    <br></br><br></br>
                                </span>
                            </div>
                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Doing-Business-As name
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Mail telephone and fax:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    <br></br>
                                </span>
                                <span className="descofinfo">
                                    <br></br><br></br>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="borderdivider"></div>
                    <div className="coverforall inspectionborder ">
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Authority type:</span>
                                <span className="tabledesc2">Common</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Autorithy status:</span>
                                <span className="tabledesc2">Active</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Application pending:</span>
                                <span className="tabledesc2">No</span>
                            </div>
                        </div>
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Authority type:</span>
                                <span className="tabledesc2">Common</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Autorithy status:</span>
                                <span className="tabledesc2">Active</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Application pending:</span>
                                <span className="tabledesc2">No</span>
                            </div>
                        </div>
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Authority type:</span>
                                <span className="tabledesc2">Common</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Autorithy status:</span>
                                <span className="tabledesc2">Active</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Application pending:</span>
                                <span className="tabledesc2">No</span>
                            </div>
                        </div>

                    </div>
                    <div className="borderdivider"></div>
                    <div className="inspection">
                        <div className="contentareafortable">
                            <span className="tabletitle2">Property:</span>
                            <span className="tabledesc2">Yes</span>
                        </div>
                        <div className="contentareafortable">
                            <span className="tabletitle2">Passenger:</span>
                            <span className="tabledesc2">No</span>
                        </div>

                        <div className="contentareafortable">
                            <span className="tabletitle2">Household goods:</span>
                            <span className="tabledesc2">No</span>
                        </div>

                        <div className="contentareafortable">
                            <span className="tabletitle2">Private:</span>
                            <span className="tabledesc2">Yes</span>
                        </div>
                        <div className="contentareafortable">
                            <span className="tabletitle2">Enterprise:</span>
                            <span className="tabledesc2">No</span>
                        </div>
                    </div>
                    <div className="borderdivider"></div>
                    <div className="coverforall inspectionborder ">
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance type:</span>
                                <span className="tabledesc2">BIPD</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance required:</span>
                                <span className="tabledesc2">$750,000</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance on File:</span>
                                <span className="tabledesc2">$750,000</span>
                            </div>
                        </div>
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance type:</span>
                                <span className="tabledesc2">Cargo</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance required:</span>
                                <span className="tabledesc2">No</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance on File:</span>
                                <span className="tabledesc2">No</span>
                            </div>
                        </div>
                        <div className="coverforinspection">
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance type:</span>
                                <span className="tabledesc2">Bond</span>
                            </div>
                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance required:</span>
                                <span className="tabledesc2">No</span>
                            </div>

                            <div className="contentareafortable">
                                <span className="tabletitle2">Insurance on File:</span>
                                <span className="tabledesc2">No</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CarrierDetails;