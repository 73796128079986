import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Table } from 'antd';
import { MailOutlined, PhoneOutlined, GlobalOutlined, FileTextOutlined } from '@ant-design/icons';
import "./carier.css";

const LeadsResults = () => {
  const location = useLocation();
  const { reportData } = location.state || { reportData: [] };

  // Generate unique keys for each record if they don't already exist
  const reportDataWithKeys = useMemo(() => {
    return reportData.map((item, index) => ({
      ...item,
      key: item.email || item.phone || index.toString(), // Use a suitable identifier or index
    }));
  }, [reportData]);

  // State to track currently expanded row keys
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // Get column keys from reportData dynamically
  const allColumnKeys = useMemo(() => {
    if (reportDataWithKeys.length === 0) return [];
    return Object.keys(reportDataWithKeys[0]);
  }, [reportDataWithKeys]);

  // Create columns dynamically from keys
  const allColumns = allColumnKeys.map(key => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Format title
    dataIndex: key,
    key,
  }));

  // Display first 4 columns initially
  const mainColumns = allColumns.slice(0, 4);

  // Additional columns to be shown in the expanded row
  const additionalColumns = allColumns.slice(4);

  // Icon mapping for different keys
  const iconMapping = {
    email: <MailOutlined style={{ fontSize: '24px', color: 'white' }} />,
    phone: <PhoneOutlined style={{ fontSize: '24px', color: 'white' }} />,
    website: <GlobalOutlined style={{ fontSize: '24px', color: 'white' }} />,
    documents: <FileTextOutlined style={{ fontSize: '24px', color: 'white' }} />,
    // Add more mappings as needed
  };

  // Expanded row renderer
  const expandedRowRender = (record) => {
    const additionalData = additionalColumns.map(col => (
      <div key={col.key} style={{ marginBottom: '10px', flexBasis: 'calc(50% - 20px)' }}>
        <div className="iconBackground">
          {iconMapping[col.dataIndex] || <FileTextOutlined style={{ fontSize: '24px', color: 'white' }} />}
        </div>
        <p className="inlineBlock"><b>{col.title}:</b> {record[col.dataIndex]}</p>
      </div>
    ));

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {additionalData}
      </div>
    );
  };

  // Function to handle row expand change
  const handleExpandChange = (expanded, record) => {
    const newExpandedRowKeys = expanded ? [record.key] : [];
    console.log(newExpandedRowKeys, "newExpanded");
    setExpandedRowKeys(newExpandedRowKeys);
  };

  return (
    <>
      <div className="carriercontain">
        <Link to="/sales/leads">
          <div className="button_of_search">
            New search
          </div>
        </Link>
        <div className="carierCover">
          <div className="carrierTable">
            <Table
              columns={mainColumns}
              dataSource={reportDataWithKeys}
              pagination={{ pageSize: 10 }}
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                onExpand: handleExpandChange,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsResults;
