import { useState } from "react";
import "./Usdot.css"
import Chameleon from "./Chameleon";
import InspectionCrash from "./CrashInspection/InspectionCrash";
import InsprctionDetail from "./InsprctionDetail";
import DetailModelBody from "./Components/InspectionDetailPage"
import { Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Hourglass } from 'react-loader-spinner';
import { BASE_URL } from "../Constants/BaseUrl";
import { displayData, toPascalCase } from "../Utility/Services"
const ByVin = () => {
    const [detailModal, setDetailModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { data, inspection_details, vehicles, vin_number } = location.state;
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState(null);
    const [report, setReport] = useState(null);
    const [vehicleDetails, setVehicleDetails] = useState(null);
    const [violations, setViolations] = useState(null);

    const showModal = () => {
        setIsModalOpened(true);
    };

    const handleOk = () => {
        setIsModalOpened(false);
    };

    const handleCancel = () => {
        setIsModalOpened(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const handleSubmit = async (option, inspection_id) => {
        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/getReportDetailsByInspectionID`, { inspection_id: inspection_id });
            console.log(response.data)
            if (response.data.hasOwnProperty('result')) {
                const report = response.data['result']['report'];
                const vehicles = response.data['result']['vehicle'];
                const violations = response.data['result']['violation'].hasOwnProperty('error') ? [] : response.data['result']['violation'];
                setLoading(false);
                setCompany(option)
                setViolations(violations)
                setVehicleDetails(vehicles)
                setReport(report[0])
                setDetailModal(true);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error submitting ID:', error);
        }
    }
    const openDetail = (option, inspection_id) => {
        handleSubmit(option, inspection_id)
    };
    const closeDetail = () => {
        setDetailModal(false);
    }

    const [submit, setSubmit] = useState(true)

    return (
        <>
            {
                (
                    <>
                        <div className="cover_usdot">
                            <div className="topofusdot">
                                <div className="button_of_search" onClick={() => { navigate("/sales/chameleon") }}>
                                    New search
                                </div>
                            
                            </div>
                            <div className="usdottitled">
                                VIN Number: {vin_number}
                            </div>
                            <div className="red_information">
                                <span className="contentarea">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                    </svg>
                                    <span className="mx-2">
                                        This VIN number has inspections with {data?.length} USDOT number(s). <u><strong onClick={() => { setIsModalOpened(true) }}>Click here</strong></u> to view more details.

                                    </span>
                                </span>

                            </div>

                            <div className="operationalclassification">
                                <div className="card-header">
                                    Vehicle information
                                </div>
                                <div className="coverofcrash">
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Year:</span>
                                            <span className="tabledesc2">{vehicles['Model Year']}</span>
                                        </div>

                                    </div>

                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Make:</span>
                                            <span className="tabledesc2">{vehicles['Make']}</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Model:</span>
                                            <span className="tabledesc2">{vehicles['Model']}</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">GVWR:</span>
                                            <span className="tabledesc2">{vehicles['Gross Vehicle Weight Rating From']}</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">Type:</span>
                                            <span className="tabledesc2">{toPascalCase(displayData(vehicles['Body Class'] + ' ' + vehicles['Vehicle Type']))}</span>
                                        </div>

                                    </div>
                                    <div className="induvidual">
                                        <div className="coverofclass">
                                            <span className="tabletitle2">VIN:</span>
                                            <span className="tabledesc2">#{vin_number}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            {data.map((option, index) => (
                                <div className="cardcomponent">
                                    <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                        Carrier #{index + 1}: {toPascalCase(displayData(option?.LEGAL_NAME))}
                                        <span>({option?.INSPECTION_COUNT}) Inspections</span>
                                    </div>
                                    <div className="row_wrapper">
                                        <div className="row_content">
                                            <div className="contentofindual">
                                                <div className="data_title">USDOT Number:</div>
                                                <div className="data_desc mt-1">{option?.DOT_NUMBER}</div>
                                            </div>
                                            <div className="contentofindual">
                                                <div className="data_title">MC Number:</div>
                                                <div className="data_desc mt-1">{option?.mc_mx_ff_number}</div>
                                            </div>
                                            <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Legal name:</div>
                                                <div className="data_desc mt-1">{toPascalCase(displayData(option?.LEGAL_NAME))}</div>
                                            </div>
                                            <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Physical Address:</div>
                                                <div className="data_desc mt-1">{toPascalCase(displayData(option?.PHYSICAL_ADDRESS))}</div>
                                            </div>

                                        </div>
                                        <div className="row_content">
                                            <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Company Rep:</div>
                                                <div className="data_desc mt-1">{toPascalCase(displayData(option?.COMPANY_REPRESENTATIVE))}</div>
                                            </div>
                                            <div className="contentofindual">
                                                <div className="data_title">Phone:</div>
                                                <div className="data_desc  align-item-center">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                                    <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                                </svg> */}
                                                    <span className="mx-1">{option?.PHONE}</span></div>
                                            </div>
                                            <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Email:</div>
                                                <div className="data_desc mt-1">{toPascalCase(displayData(option?.EMAIL))}</div>
                                            </div>
                                            <div className="contentofindual">
                                                <div className="data_title">Inspection Dates: </div>
                                                <div className="data_desc mt-1" style={{ maxWidth: "146px" }}>{option?.inspection_date}</div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            ))}

                        </div>
                        <br></br>
                        <br></br>
                    </>
                )
            }
            <Modal
               wrapClassName="modalofall"
               closeIcon={null}
               open={isModalOpened}
               onOk={handleOk}
               onCancel={handleCancel}
               width={1100}
               centered={false}
               style={{  margin:"0 auto" }}
               footer={null}

            >
                <div className="headerofmodal">
                    <span className="headertext">Comparison of truck</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => setIsModalOpened(false)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {/* Modal Body */}
                <div className="modal-body p-3">
                    <div className="operationalclassification">
                        <div className="card-header">
                            Vehicle information
                        </div>
                        <div className="coverofcrash">
                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">Year:</span>
                                    <span className="tabledesc2">{vehicles['Model Year']}</span>
                                </div>

                            </div>

                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">Make:</span>
                                    <span className="tabledesc2">{vehicles['Make']}</span>
                                </div>

                            </div>
                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">Model:</span>
                                    <span className="tabledesc2">{vehicles['Model']}</span>
                                </div>

                            </div>
                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">GVWR:</span>
                                    <span className="tabledesc2">{vehicles['Gross Vehicle Weight Rating From']}</span>
                                </div>

                            </div>
                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">Type:</span>
                                    <span
                                        className="tabledesc2">{vehicles['Body Class'] + ' ' + vehicles['Vehicle Type']}</span>
                                </div>

                            </div>
                            <div className="induvidual">
                                <div className="coverofclass">
                                    <span className="tabletitle2">VIN:</span>
                                    <span className="tabledesc2">#{vin_number}</span>
                                </div>

                            </div>

                        </div>

                    </div>
                    {data.map((option, index) => (

                        <div className="cardcomponent">

                            <div className="card-header">
                                Company #{index + 1}
                            </div>
                            <div className="row_wrapper">
                                <div className="row_content">
                                    <div className="contentofindual">
                                        <div className="data_title">USDOT Number:</div>
                                        <div className="data_desc mt-1">{displayData(option?.DOT_NUMBER)}</div>
                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">MC Number:</div>
                                        <div className="data_desc mt-1">{displayData(option?.mc_mx_ff_number)}</div>
                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">Carrier name:</div>
                                        <div className="data_desc mt-1">{toPascalCase(displayData(option?.LEGAL_NAME))}</div>
                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">Company Rep:</div>
                                        <div className="data_desc mt-1">{toPascalCase(displayData(option?.COMPANY_REPRESENTATIVE))}</div>
                                    </div>

                                </div>
                                <div className="row_content">
                                    <div className="contentofindual">
                                        <div className="data_title">Phone number:</div>
                                        <div className="data_desc mt-1">{displayData(option?.PHONE)}</div>
                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">Email:</div>
                                        <div className="data_desc mt-1">{toPascalCase(displayData(option?.EMAIL))}</div>

                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">Physical address:</div>
                                        <div className="data_desc mt-1" style={{ width: "140px" }}>{toPascalCase(displayData(option?.PHYSICAL_ADDRESS))}</div>
                                    </div>
                                    <div className="contentofindual">
                                        <div className="data_title">Mailing address:</div>
                                        <div className="data_desc mt-1" style={{ width: "140px" }}>{toPascalCase(displayData(option?.mailing_address))}</div>

                                    </div>

                                </div>
                            </div>
                            <div className="coverofcards">
                                <div className="operationalclassification">
                                    <div className="card-header">
                                        Carrier inspections
                                    </div>
                                    <div class="coverofcrash" >
                                        <table>
                                            <thead>
                                                <tr >
                                                    <th>
                                                        <div className="coverofclass" ><span
                                                            className="tabletitle2" >Date:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">Report number:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">State:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">Plate number:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">Type:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">Time Weight:</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="coverofclass"><span
                                                            className="tabletitle2">Actions:</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inspection_details.filter(item => item.DOT_NUMBER === option.DOT_NUMBER).map((inspection, index) => (
                                                    <tr >
                                                        <td>{inspection?.inspection_date}</td>
                                                        <td>{inspection?.REPORT_NUMBER}</td>
                                                        <td>{inspection?.COUNTY_CODE_STATE}</td>
                                                        <td>{inspection?.INSP_UNIT_LICENSE}</td>
                                                        <td>{inspection?.vehicle_Tyle}</td>
                                                        <td>-</td>
                                                        <td>
                                                            <div className="coverofclass" style={{ cursor: 'pointer' }} onClick={() => { openDetail(option, inspection['INSPECTION_ID']) }}>
                                                                <span className="tabledesc2 mt-2" style={{ textDecoration: 'none' }} onMouseEnter={(e) => e.target.style.textDecoration = 'underline'} onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>View More</span>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}
                </div>

            </Modal>

            <Modal
                open={detailModal}
                onCancel={() => { setDetailModal(false) }}
                closeIcon={null}
                width={1100}
                centered={false}
                style={{  margin:"0 auto" }}
                footer={null}
            >
                {detailModal && <DetailModelBody company={company} report={report} vehicles={vehicleDetails} violations={violations} closeDetails={closeDetail} />}
            </Modal>




            {loading && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </div>
            )}
        </>


    )
}

export default ByVin;