import { useState } from "react";
import "./Crash.css"
const InspectionCrash = () => {
        const [activeIndex, setActiveIndex] = useState(0);

        const handleButtonClick = (index) => {
                setActiveIndex(index);
        };
        return (
                <>
                        <div className="operationalclassification">
                                <div className="card-header">
                                        Inspections/ Crashes in US
                                </div>
                                <div className="classificationwrapper2">
                                        <div className="buttonovercover">
                                                <div className={activeIndex === 0 ? "btnCrash activecrach" : "btnCrash"} onClick={() => handleButtonClick(0)}>
                                                        Inspections
                                                </div>
                                                <div className={activeIndex === 1 ? "btnCrash activecrach" : "btnCrash"} onClick={() => handleButtonClick(1)}>
                                                        Inspections: Crash details
                                                </div>
                                        </div>
                                        <div className="inboxcontain " style={{ marginTop: "40px" }}>
                                                Detailed crash report as of:<strong> 12/19/2023</strong>
                                        </div>
                                        <div className="wraperofcrash">
                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Crash information
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Carrier name:</span>
                                                                                <span className="tabledesc2">Light Towing Llc</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>Carrier address:</span>
                                                                                <span className="tabledesc2">22600 Woodfield Road
                                                                                        Gaithersburg, MD 20882</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">U.S. DOT#:</span>
                                                                                <span className="tabledesc2">2284379</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">State Census#:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Crash information
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of Fatalities:</span>
                                                                                <span className="tabledesc2">0</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Towaway:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of injuries:</span>
                                                                                <span className="tabledesc2">1</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of vehicles in crash:</span>
                                                                                <span className="tabledesc2">3</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="wraperofcrash">
                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Location
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Crash date & Time:</span>
                                                                                <span className="tabledesc2">11/25/2022 1241</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>City:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Crash state:</span>
                                                                                <span className="tabledesc2">MD</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Location:</span>
                                                                                <span className="tabledesc2">Capital Beltway</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Country:</span>
                                                                                <span className="tabledesc2">Prince Georges</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"></span>
                                                                                <span className="tabledesc2"></span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Reporting data
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Report state:</span>
                                                                                <span className="tabledesc2">MD</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>Reporting agency:</span>
                                                                                <span className="tabledesc2">66</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Federally recordable:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Report #:</span>
                                                                                <span className="tabledesc2">MD22A0974383</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Officer Badge #:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">State recordable:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                </div>
                                <div className="classificationwrapper2">
                                        <div className="buttonovercover">
                                                <div className={activeIndex === 0 ? "btnCrash activecrach" : "btnCrash"} onClick={() => handleButtonClick(0)}>
                                                        Inspections
                                                </div>
                                                <div className={activeIndex === 1 ? "btnCrash activecrach" : "btnCrash"} onClick={() => handleButtonClick(1)}>
                                                        Inspections: Crash details
                                                </div>
                                        </div>
                                        <div className="inboxcontain " style={{ marginTop: "40px" }}>
                                                Detailed crash report as of:<strong> 12/19/2023</strong>
                                        </div>
                                        <div className="wraperofcrash">
                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Crash information
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Carrier name:</span>
                                                                                <span className="tabledesc2">Light Towing Llc</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>Carrier address:</span>
                                                                                <span className="tabledesc2">22600 Woodfield Road
                                                                                        Gaithersburg, MD 20882</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">U.S. DOT#:</span>
                                                                                <span className="tabledesc2">2284379</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">State Census#:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Crash information
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of Fatalities:</span>
                                                                                <span className="tabledesc2">0</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Towaway:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of injuries:</span>
                                                                                <span className="tabledesc2">1</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"># of vehicles in crash:</span>
                                                                                <span className="tabledesc2">3</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="wraperofcrash">
                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Location
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Crash date & Time:</span>
                                                                                <span className="tabledesc2">11/25/2022 1241</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>City:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Crash state:</span>
                                                                                <span className="tabledesc2">MD</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Location:</span>
                                                                                <span className="tabledesc2">Capital Beltway</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Country:</span>
                                                                                <span className="tabledesc2">Prince Georges</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2"></span>
                                                                                <span className="tabledesc2"></span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="operationalclassification">
                                                        <div className="card-header">
                                                                Reporting data
                                                        </div>
                                                        <div className="coverofcrash">
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Report state:</span>
                                                                                <span className="tabledesc2">MD</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2" style={{ width: "154px" }}>Reporting agency:</span>
                                                                                <span className="tabledesc2">66</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Federally recordable:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                                <div className="induvidual">
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Report #:</span>
                                                                                <span className="tabledesc2">MD22A0974383</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">Officer Badge #:</span>
                                                                                <span className="tabledesc2">-</span>
                                                                        </div>
                                                                        <div className="coverofclass">
                                                                                <span className="tabletitle2">State recordable:</span>
                                                                                <span className="tabledesc2">Yes</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="operationalclassification">
                                                <div className="card-header">
                                                        Crash environment
                                                </div>
                                                <div className="coverofcrash">
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Roadway trafficway:</span>
                                                                        <span className="tabledesc2">One-way trafficway, Not divided</span>
                                                                </div>

                                                        </div>

                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Road access control:</span>
                                                                        <span className="tabledesc2">Full control</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Road surface condition:</span>
                                                                        <span className="tabledesc2">Wet</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Weather condition:</span>
                                                                        <span className="tabledesc2">No adverse conditions</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Light condition:</span>
                                                                        <span className="tabledesc2">Daylight</span>
                                                                </div>

                                                        </div>
                                                </div>
                                        </div>
                                        <div className="operationalclassification">
                                                <div className="card-header">
                                                        Driver information
                                                </div>
                                                <div className="coverofcrash mainworker">
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Age:</span>
                                                                        <span className="tabledesc2">27</span>
                                                                </div>

                                                        </div>

                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">License state:</span>
                                                                        <span className="tabledesc2">MD</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Citation issued:</span>
                                                                        <span className="tabledesc2">No</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Valid license:</span>
                                                                        <span className="tabledesc2">Yes</span>
                                                                </div>

                                                        </div>

                                                </div>
                                        </div>
                                        <div className="operationalclassification">
                                                <div className="card-header">
                                                        Vehicle information
                                                </div>
                                                <div className="coverofcrash mainworker">
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">VIN:</span>
                                                                        <span className="tabledesc2">3C7WRSCL0HG669901</span>
                                                                </div>

                                                        </div>

                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">HM Placards:</span>
                                                                        <span className="tabledesc2">no</span>
                                                                </div>

                                                        </div>
                                                        <div className="induvidual">
                                                                <div className="coverofclass">
                                                                        <span className="tabletitle2">Vehicle:</span>
                                                                        <span className="tabledesc2">Single-Unit Truck (2-Axle, 6 Tire)</span>
                                                                </div>

                                                        </div>

                                                </div>
                                        </div>
                                </div>


                        </div>
                </>
        )
}
export default InspectionCrash