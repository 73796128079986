import { create } from 'zustand';
import axios from 'axios';

const initialCheckboxes = {
  mc: { checked: false, active: false },
  ff: { checked: false, active: false },
  mx: { checked: false, active: false },
  hhg: { checked: false, active: false },
  passenger: { checked: false, active: false },
  property: { checked: false, active: false },
  enterprise: { checked: false, active: false },
  common: 0,
  contract: 0,
  broker: 0,
};

const fedralAuthoritytore = create((set) => ({
  fedralAuthority: [],
  checkedBoxes: initialCheckboxes,
  isLoading: false,
  error: null,

  fetchfedralAuthority: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await axios.get('https://jsonplaceholder.typicode.com/fedralAuthority');
      const fedralAuthority = response.data;
      set({ fedralAuthority, isLoading: false });
    } catch (error) {
      console.error('Error fetching fedralAuthority:', error);
      set({ isLoading: false, error: 'Failed to fetch fedralAuthority' });
    }
  },

  // Toggle checkbox state
  setCheckedBoxes: (field) => {
    set((state) => ({
      checkedBoxes: {
        ...state?.checkedBoxes,
        [field]: {
          ...state?.checkedBoxes[field],
          checked: !state?.checkedBoxes[field]?.checked,
          active: false,
        },
      },
    }));
  },

  // Remove field state
  removeFieldState2: (field) => {
    set((state) => ({
      checkedBoxes: {
        ...state?.checkedBoxes,
        [field]: {
          checked: false,
          active: false,
          inActive: false,
        },
      },
    }));
  },

  // Toggle active state
  toggleActive: (field) => {
    set((state) => ({
      checkedBoxes: {
        ...state?.checkedBoxes,
        [field]: {
          ...state?.checkedBoxes[field],
          active: !state?.checkedBoxes[field]?.active,
          checked: false,
        },
      },
    }));
  },

  toggleValue: (category, value) => {
    set((state) => ({
      checkedBoxes: {
        ...state.checkedBoxes,
        [category]: state.checkedBoxes[category] === value ? '0' : value,
      },
    }));
  },
  

  // Handle change function to toggle checked state and reset active
  handleChange: (field) => {
    set((state) => ({
      checkedBoxes: {
        ...state?.checkedBoxes,
        [field]: {
          ...state?.checkedBoxes[field],
          checked: !state?.checkedBoxes[field]?.checked,
          active: false,
        },
      },
    }));
  },
}));

export default fedralAuthoritytore;
