import React from 'react'
import "./Client.css";
import { Modal } from "antd";
import { Radio } from 'antd';
const PortfolioModal = ({setShowModal,showModal,portfolioTitle,setPortfolioTitle,selectedValue,handleChange,handleSavePortfolio}) => {
  return (
    <>
        <Modal
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
                closeIcon={null}
            >
                <div className="headerofmodal">
                    <span className="headertext">New portfolio</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setShowModal(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <span className="boderheader">Title portfolio:</span>
                    <input
                        type="text"
                        placeholder="Ex. Sergio's customer base"
                        className="usdotinput"
                        value={portfolioTitle}
                        onChange={(e) => setPortfolioTitle(e.target.value)}
                    />
                    <p className="textofclient">
                        Organize your clients into subcategories for a more detailed view.
                        Would you like to create sub-portfolios now?
                    </p>
                    <Radio.Group onChange={handleChange} value={selectedValue}>
                        <Radio value={'Yes'} className="custom-radio">Yes</Radio>
                        <Radio value={'No'} className="custom-radio">No</Radio>
                    </Radio.Group>
                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => { setShowModal(false) }}>Cancel</span>
                        <div className="submitbutton" onClick={handleSavePortfolio}>
                            Save portfolio
                        </div>
                    </div>
                </div>
            </Modal>
    
    </>
  )
}

export default PortfolioModal