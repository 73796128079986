const Revocation = () => {
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Revocation
                </div>
                <div className="compinfo">
                    <div className="coverofcompinfo  ">
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    US DOT:
                                </span>
                                <span className="descofinfo">
                                    22884379
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    1st Served date:
                                </span>
                                <span className="descofinfo">
                                    02/18/2014
                                </span>
                            </div>

                        </div>
                        <div className="infosection">

                            <div className="target">
                                <span className="titleofinfo">
                                    Legal name:
                                </span>
                                <span className="descofinfo">
                                    Light towing LLC
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Effective date:
                                </span>
                                <span className="descofinfo">
                                    03/24/2014
                                </span>
                            </div>

                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Docket number:
                                </span>
                                <span className="descofinfo">
                                    MC963639075
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Reason:
                                </span>
                                <span className="descofinfo">
                                    Involuntary revocation
                                </span>
                            </div>

                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Auth type:
                                </span>
                                <span className="descofinfo">
                                    COMMON
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Rejected date:
                                </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default Revocation;