import React from 'react'
// import AppRoutes from './AppRoutes'
import Navbar from '../Navbar/Navbar'
export const Main = () => {
    return (
        <>
            <Navbar />
            {/* <div className="routers">
                <AppRoutes />
            </div> */}
        </>
    )
}
