import React, { useState } from 'react';
import { Table, Space, Button, Menu, Dropdown, Checkbox } from 'antd';
import { StarOutlined,EllipsisOutlined  } from '@ant-design/icons';
import { Link } from 'react-router-dom';


// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const Allmail =({likedCount,setLikedCount})=>{
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleLike = (key) => {
        const updatedData = data.map((item) => {
            if (item.key === key) {
                item.liked = !item.liked;
            }
            return item;
        });

        const count = updatedData.filter((item) => item.liked).length;
        setLikedCount(count);
        setData(updatedData);
    };

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            // Logic for 'Like All'
            const updatedData = data.map((item) => ({ ...item, liked: true }));
            setData(updatedData);
            setLikedCount(updatedData.length);
        } else if (e.key === '2') {
            // Logic for 'Delete'
            const newData = data.filter((item) => !selectedRowKeys.includes(item.key));
            setData(newData);
            setSelectedRowKeys([]);
            setLikedCount(0);
        }
    };

      

    const columns = [
     
        {
            title: (
                <div >
                  <EllipsisOutlined style={{ fontSize: 20 }} />
                </div>
              ),
            dataIndex: 'like',
            key: 'like',
            render: (text, record) => (
              <Button
                style={{ padding: 0, border: 'none', background: 'none' }}
                onClick={() => handleLike(record.key)}
              >
<StarOutlined 
    style={{ 
        fontSize: 20, 
        color: record.liked ? '#0C94E4' : 'inherit', 
    }} 
/>

              </Button>
            ),
          },
          
      {
        title: 'Sender',
        dataIndex: 'Sender',
        key: 'Sender',
        render: (text, record) => (
            <Link className='tabletext' to="/home/preview">{text}</Link>
          ),
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'Email',
        render: (text, record) => (
            <Link className='tabletext' to="/home/preview">{text}</Link>
          ),
      },
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
        render: (text, record) => (
            <Link className='tabletext' to="/home/preview">{text}</Link>
          ),
      },
    ];
    const [data, setData] = useState([
      {
        key: 1,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
     
      },
      {
        key: 2,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 3,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 4,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 5,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 6,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 7,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 8,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
    
      {
        key: 9,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 10,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 11,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 12,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 13,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
      {
        key: 14,
        Sender: 'Company Bolanos L...',
        Email: "Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te...",
        Date: '12/02/2023',
      },
    ]);
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">Like All</Menu.Item>
            <Menu.Item key="2">Delete</Menu.Item>
        </Menu>
    );
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      };
      
    return(
        <>
            <Dropdown overlay={menu}>
                <Button>Actions</Button>
            </Dropdown>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </>
    )
}
export default Allmail