// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navitem_acc{
margin-top: 32px;
cursor: pointer;
color: #374147;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.acc_nav_parent{
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    border-right: 2px solid #DFE1E2;
    width: 200px;
    height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/Navbar/Account.css"],"names":[],"mappings":"AAAA;AACA,gBAAgB;AAChB,eAAe;AACf,cAAc;AACd,mBAAmB;AACnB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,+BAA+B;IAC/B,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".navitem_acc{\r\nmargin-top: 32px;\r\ncursor: pointer;\r\ncolor: #374147;\r\nfont-family: Roboto;\r\nfont-size: 14px;\r\nfont-style: normal;\r\nfont-weight: 500;\r\nline-height: normal;\r\n}\r\n.acc_nav_parent{\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-left: 32px;\r\n    border-right: 2px solid #DFE1E2;\r\n    width: 200px;\r\n    height: 500px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
