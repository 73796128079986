import React, { useEffect, useState } from 'react';

import './Map.css'
import { SvgComponent } from './SvgComponent';
import { message, Tag, Alert, notification } from "antd";
import { states } from "../../Constants/MapStates"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../Constants/BaseUrl';
export const Map = ({ selectedPaths, setSelectedPaths, setTooltip, tooltip, path, setPath, companyName, handleInsuranceRemove, selections, removeInsuranceDates }) => {
 
  const [insuracne, setInsurance] = useState([])
  const [date, setDate] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const handlePathClick = (event) => {
    const clickedPathId = event.target.id;
    const isSelected = path.includes(clickedPathId);
    if (userInfo.freeTrial === true) {
      if (isSelected) {
        setPath(path.filter(pathId => pathId !== clickedPathId));
        setSelectedPaths(path?.filter(pathId => pathId !== clickedPathId));
      } else {
        setPath([...path, clickedPathId]);
        setSelectedPaths([...path, clickedPathId]);
      }
    } else {
      if (userInfo.purchasedcategory === 'Starter' || userInfo.purchasedcategory === 'Business') {
        openNotification();
      } else {
        if (isSelected) {
          setPath(path.filter(pathId => pathId !== clickedPathId));
          setSelectedPaths(path.filter(pathId => pathId !== clickedPathId));
        } else {
          setPath([...path, clickedPathId]);
          setSelectedPaths([...path, clickedPathId]);
        }
      }
    }

  };

  useEffect(() => {
    setInsurance(companyName)
    setDate(selections)
  }, [companyName, selections])
  const handleRemoveInsurance = (insurance) => {
    handleInsuranceRemove(insurance);
  };
  const removeInsuranceDates1 = (date) => {
    removeInsuranceDates(date);
  };


  const handletext = (event) => {
    const clickedPathId = event;
    const isSelected = path.includes(clickedPathId);
    if (userInfo.freeTrial === true) {
      if (isSelected) {
        setPath(path.filter(pathId => pathId !== clickedPathId));
        setSelectedPaths(path.filter(pathId => pathId !== clickedPathId));
      } else {
        setPath([...path, clickedPathId]);
        setSelectedPaths([...path, clickedPathId]);
      }
    } else {
      if (userInfo.purchasedcategory === 'Starter' || userInfo.purchasedcategory === 'Business') {
        openNotification();
      } else {
        if (isSelected) {
          setPath(path.filter(pathId => pathId !== clickedPathId));
          setSelectedPaths(path.filter(pathId => pathId !== clickedPathId));
        } else {
          setPath([...path, clickedPathId]);
          setSelectedPaths([...path, clickedPathId]);
        }
      }
    }

  };
  const handleidclicked = (event) => {
    const clickedPathId = event;
    const isSelected = path.includes(clickedPathId);

    if (isSelected) {
      setPath(path.filter(pathId => pathId !== clickedPathId));
      setSelectedPaths(path.filter(pathId => pathId !== clickedPathId));
    } else {
      setPath([...path, clickedPathId]);
      setSelectedPaths([...path, clickedPathId])
    }
  };
  const handleRemove = (state) => {
    const isSelected = path.includes(state);
    if (isSelected) {
      setPath(path.filter(pathId => pathId !== state));
      setSelectedPaths(path.filter(pathId => pathId !== state));
    }
  }
  const handleMouseEnter = (event, pathId, name) => {
    setTooltip({
      display: true,
      name,
      x: event.clientX,
      y: event.clientY,
    });

    // Find the elements by ID
    const pathElement = document.getElementById(pathId);
    const labelElement = document.getElementById(`${pathId}_label`);
    // Check if elements exist before modifying their classList
    if (pathElement) pathElement.classList.add('map-path-hover');
    if (labelElement) labelElement.classList.add('map-path-hover');
    // alert(pathElement.classList)

  };

  const handleMouseLeave = (pathId) => {
    setTooltip({ display: false, name: '', x: 0, y: 0 });

    // Find the elements by ID
    const pathElement = document.getElementById(pathId);
    const labelElement = document.getElementById(`${pathId}_label`);

    // Check if elements exist before modifying their classList
    if (pathElement) pathElement.classList.remove('map-path-hover');
    if (labelElement) labelElement.classList.remove('map-path-hover');
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    const fetchUserData = async () => {
      try {
        if (!userId) {
          console.error('User ID not found in localStorage');
          return;
        }

        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        const userData = userResponse.data.data;

        setUserInfo(userData)
        if (userData.freeTrial === false) {
          if (userData.purchasedcategory === 'Starter' || userData.purchasedcategory === 'Business') {

            const stateInfoResponse = await axios.get(`${SERVER_URL}/userdata/${userId}`);
            const stateInfoData = stateInfoResponse.data.user.states;


            // Check if states is an array
            if (Array.isArray(stateInfoData)) {
              setPath(stateInfoData)
              if (stateInfoData.length === 0) {
                //   setVisible(true);
              }
            } else {
              console.log('states is not an array or is undefined');
            }
          }
        }

      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle additional error logic if needed
      }
    };

    fetchUserData();
  }, []);
  const openNotification = () => {
    notification.error({
      message: 'Upgrade Required',
      description: (
        <span>
          You are not allowed to choose any state according to your plan. <br></br>
          <a className='upgradeplan' onClick={handleUpgradeClick}>  Please Upgrade Your Plan.</a>
        </span>
      ),
      duration: 0,
      placement: 'top',
    });
  };
  const navigate = useNavigate()
  const handleUpgradeClick = () => {
    navigate('/home/finance');
  }
  return (
    <>

      <div>
        <div className='boxcontainer'>
          <SvgComponent handletext={handletext} selectedPaths={path} handlePathClick={handlePathClick} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} tooltip={tooltip} />

        </div>


      </div>

    </>)
}
