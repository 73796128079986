import React from 'react'
import "./Account.css"
import { Link } from 'react-router-dom'
function AccountNav() {
    return (
        <div className='acc_nav_parent'>
            <Link to='/home/myinfo'> <div className='navitem_acc'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.98438 15.5781C5.45462 14.6519 6.17216 13.874 7.05745 13.3306C7.94275 12.7872 8.96123 12.4995 10 12.4995C11.0388 12.4995 12.0572 12.7872 12.9425 13.3306C13.8278 13.874 14.5454 14.6519 15.0156 15.5781" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>   My Info
            </div></Link>
            <Link to='/home/agencyinfo'>  <div className='navitem_acc'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M1.25 16.875H18.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.25 16.875V3.125C11.25 2.95924 11.1842 2.80027 11.0669 2.68306C10.9497 2.56585 10.7908 2.5 10.625 2.5H3.125C2.95924 2.5 2.80027 2.56585 2.68306 2.68306C2.56585 2.80027 2.5 2.95924 2.5 3.125V16.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17.5 16.875V8.125C17.5 7.95924 17.4342 7.80027 17.3169 7.68306C17.1997 7.56585 17.0408 7.5 16.875 7.5H11.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 5.625H7.5" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.25 10.625H8.75" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 13.75H7.5" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.75 13.75H15" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.75 10.625H15" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>  Agency Info
            </div></Link>
            <Link to='/home/finances'>   <div className='navitem_acc'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 5.625V6.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 13.125V14.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 13.125H10.9375C11.3519 13.125 11.7493 12.9604 12.0424 12.6674C12.3354 12.3743 12.5 11.9769 12.5 11.5625C12.5 11.1481 12.3354 10.7507 12.0424 10.4576C11.7493 10.1646 11.3519 10 10.9375 10H9.0625C8.6481 10 8.25067 9.83538 7.95765 9.54235C7.66462 9.24933 7.5 8.8519 7.5 8.4375C7.5 8.0231 7.66462 7.62567 7.95765 7.33265C8.25067 7.03962 8.6481 6.875 9.0625 6.875H11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>    Finances
            </div></Link>
            <Link to='/home/collaborators'> <div className='navitem_acc'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z" stroke="#374147" stroke-width="1.5" stroke-miterlimit="10" />
                    <path d="M12.1406 4.52344C12.4997 4.42604 12.8701 4.37613 13.2422 4.375C14.3196 4.375 15.3529 4.80301 16.1148 5.56488C16.8767 6.32675 17.3047 7.36006 17.3047 8.4375C17.3047 9.51494 16.8767 10.5483 16.1148 11.3101C15.3529 12.072 14.3196 12.5 13.2422 12.5" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.25 15.4217C1.88433 14.519 2.72659 13.7822 3.70563 13.2736C4.68467 12.765 5.77173 12.4995 6.875 12.4995C7.97827 12.4995 9.06533 12.765 10.0444 13.2736C11.0234 13.7822 11.8657 14.519 12.5 15.4217" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.2422 12.5C14.3455 12.4993 15.4328 12.7645 16.412 13.2731C17.3911 13.7817 18.2333 14.5188 18.8672 15.4219" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>      Collaborators
            </div></Link>

        </div>
    )
}

export default AccountNav