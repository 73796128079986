import React from 'react'
import "./Client.css";
import { Modal } from "antd";
const NewSubPortfolio = ({setShowSubModal,showSubModal,subPortfolioTitle,setSubPortfolioTitle,handleSaveSubPortfolio}) => {
    return (
        <>
            <Modal
                open={showSubModal}
                onCancel={() => setShowSubModal(false)}
                footer={null}
                closeIcon={null}
            >
                <div className="headerofmodal">
                    <span className="headertext">New Subportfolio</span>
                    <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowSubModal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M16 2L2 16"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 16L2 2"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <span className="boderheader">Subportfolio Title:</span>
                    <input
                        type="text"
                        placeholder="Ex. Reports ABC"
                        className="usdotinput"
                        value={subPortfolioTitle}
                        onChange={(e) => setSubPortfolioTitle(e.target.value)}
                    />

                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => setShowSubModal(false)}>
                            Cancel
                        </span>
                        <div className="submitbutton" onClick={handleSaveSubPortfolio}>
                            Save subportfolio
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewSubPortfolio

