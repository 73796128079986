import { useState, useEffect } from "react";
import "./Leads.css"
import { Select, Tag } from "antd";
// import CityofLead from "./CityofLead";
import BusinessLength from "./BusinessLength";
import Insurance from "./Insurance";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FedaralAuthority from "./FedaralAuthority";
import Operation from "./Operations";
import Commodities from "./Commodities";
import Equipment from "./Equipment";
import Safety from "./Safety";
import Multiple from "./Multiple";
import { Map } from "../../Pages/Components/Map"
import axios from 'axios';
import { BASE_URL } from "../../Constants/BaseUrl";
import { Hourglass } from 'react-loader-spinner';
import InsuranceCompanyPage from "./InsuranceCompanyPage";
import { CloseCircleOutlined } from '@ant-design/icons';
import SalesSearchModal from "./SalesSearchModal";
import { fabClasses } from "@mui/material";
import TagList from "../Components/TagList";
import commodityStore from "../../Redux/Stores/Leads/commodities"
import operationstore from "../../Redux/Stores/Leads/operations"
import fedralAuthoritytore from "../../Redux/Stores/Leads/fedralAuthority"
const { Option } = Select;
const LeadHome = () => {
    const [activeButton, setActiveButton] = useState("State");
    const [isBusinessOpen, setIsBusinessOpen] = useState(false)
    const [isInsuranceOpen, setIsInsuranceOpen] = useState(false)
    const [isoperationopen, setIsoperationopen] = useState(false)
    const [isauthority, setIsauthority] = useState(false)
    const [companies, setCompanies] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [checkedItem, setItemChecked] = useState({
        email: false,
        phone: false,
        companyRep: false,
    });
    const [equipmentOpen, setEquipmentOpen] = useState(false);
    const [selections, setSelections] = useState("");
    const [insurUsdot, setInsurUsdot] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [saftyOpen, setSaftyOpen] = useState(false);
    const [isallopen, setIsallopen] = useState(false);
    const [commoditiesopen, setCommoditiesopen] = useState(false);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [cities, setCities] = useState([]);
    const [zips, setZips] = useState([]);
    const [allUsdots, setAllUsdots] = useState(null);
    const [businessUsdot, setBusinessUsdot] = useState([]);
    const [insuranceUsdot, setInsuranceUsdot] = useState([]);
    const [federalUsdot, setFederalUsdot] = useState([]);
    const [operationUsdot, setOperationUsdot] = useState([]);
    const [commoditiesUsdot, setCommoditiesUsdot] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log('States has been updated:', selectedPaths);
        console.log('cities has been updated:', cities);
        console.log('zips has been updated:', zips);
        console.log('All usdots has been updated:', allUsdots);
        console.log('companyName has been updated', companyName);
        console.log('selections  has been updated', selections);
    }, [selectedPaths, cities, zips, allUsdots,companyName,selections]);


    useEffect(() => {
        if (!isInsuranceOpen) {
            console.log("useEffect for the companyName has been triggered");
            setCurrentPage(
                <StatePage
                    handleSubmit={handleSubmit}
                    setIsallopen={setIsallopen}
                    isallopen={isallopen}
                    selectedPaths={selectedPaths}
                    setSelectedPaths={setSelectedPaths}
                    companyName={companyName}
                    selections={selections}
                    removeDates={removeDates}
                    setCompanyName={setCompanyName}
                    handleRemove={handleRemove} // Passing handleRemove as a prop
                />
            );
        }
    }, [isInsuranceOpen, companyName, selectedPaths, selections]);
   
    useEffect(() => {
        setLoading(false)
        if (reportData !== null) {
            setIsModalVisible(true);
            setIsallopen(false)
        }
    }, [reportData])
    const handleRemove = (company) => {
        const isSelected = companyName.includes(company);
        if (isSelected) {
            setCompanyName(companyName.filter(c => c !== company));
        }
    };

   const removeDates = () => {
        setSelections("")
        setInsurUsdot({})
        setSelectedDate(null)
        setSelectedDate1(null)
    }
    function convertToCSV(data) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    }
    function downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const showModal = (value) => {
        let result = [];
        let joins = '';
//         let joins = `	
//     left join telnum_ref d
// 	on a.TelNum_ID  = d.telnum_id 
// 	left join telnum_ref e
// 	on a.CellPhone_ID  = e.telnum_id 
// 	left join telnum_ref s
// 	on a.mailing_telnum_id  = s.telnum_id 
// 	left join fax_ref f
// 	on a.Fax_ID  = f.fax_id 
// 	left join city_ref h
// 	on h.city_id  = a.Physical_City_ID 
// 	left join city_ref i
// 	on a.Mailing_City_ID  = i.city_id 
// 	left join zip_ref j
// 	on j.zip_id  = a.Physical_Zip_ID 
// 	left join streetaddress_ref k
// 	on a.Mailing_Street_ID  = k.streetaddress_id 
// 	left join states_lkp l
// 	on a.Mailing_State_ID  = l.state_id 
// 	left join email_ref n
// 	on n.email_id  = a.Email_Address_ID 
// 	left join person o
// 	on a.COMPANY_OFFICER_1_ID  = o.person_id 
// 	left join person p 
// 	on a.COMPANY_OFFICER_2_ID  = p.person_id 
// `
        for (let key in value) {
          value[key].forEach(item => {
            if (item.checked) {
                if (!item.hasOwnProperty("source")){
                }
                else if (item.source == ""){
                }
                else if (!item.hasOwnProperty("table")){
                    result.push(item.source + ' as "' + item.name + '"');
                }
                else if (item.table == ""){
                    result.push(item.source + ' as "' + item.name + '"');
                }
                else if (item.hasOwnProperty("join_table")){
                    result.push(item.join_value + ' as "' + item.name + '"');
                    joins += ` left join ` + item.join_table + ` ` + item.join_value[0] + ` on a.` +item.source + ` = ` + item.join_column
                }
                else {
                    result.push(item.source + ' as "' + item.name + '"');
                }
            }
          });
        }
        
        let resultString = result.join(', ');
        
        console.log(resultString);        
        queryReport(resultString, joins)
    };
    const getData = async (q) => {
        try {
            const response = await axios.post(`${BASE_URL}/getData`, { query: q });
            if (response.data.hasOwnProperty('result')) {
                const list = response.data['result']
                return list
            } else {
                return null
            }
        } catch (error) {
            return null
        }
    }
    const queryReport = (attirbutes, joins) => {
        const states = selectedPaths.length == 0 ? "''" : selectedPaths.map(item => `'${item}'`).join(',');
        console.log("All usdots", insuranceUsdot)
        const dates = insuranceUsdot.hasOwnProperty('dates') ? insuranceUsdot.dates.hasOwnProperty('to') ? ` and cast(coalesce(expiration_date, '01/01/1900') as date) between cast('`+insuranceUsdot.dates.from+`' as date) and cast('`+insuranceUsdot.dates.to+`' as date)` : `` : ``
        const comp = insuranceUsdot.hasOwnProperty('companies') ? insuranceUsdot.companies == '' ? `` : insuranceUsdot.include ? ` and insurance_company_name in (`+insuranceUsdot.companies+`)` : `and insurance_company_name not in (`+insuranceUsdot.companies+`)` : ``
        const business_query = businessUsdot.hasOwnProperty('query') ? businessUsdot.query : ``
        const federal_query = federalUsdot.hasOwnProperty('query') ? business_query != '' ? federalUsdot.query : 'join authhist_allwithhistory r on b.dot_number = r.usdot_number ' + federalUsdot.query : ``
        const operation_query = operationUsdot.hasOwnProperty('query') ? operationUsdot.query : ``
        const commodities_query = commoditiesUsdot.hasOwnProperty('query') ? commoditiesUsdot.query : ``
        const query = `
        select distinct * from
        (select `+attirbutes+`
        from 
	fis_uat.dbo.census_master a
	left join fis_uat.dbo.dot_ref b
	on a.dot_number_id = b.dot_id
	left join fis_uat.dbo.states_lkp c
	on a.Physical_State_ID  = c.state_id `+joins+` 
        `+ business_query + federal_query +` 
        left join (
        select 
        case 
            when cancel_effective_date <> '' or cancel_effective_date is null then cast(cancel_effective_date as date)
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) < year(getdate()) and CONVERT(CHAR(5), effective_date, 101) > CONVERT(CHAR(5), getdate(), 101) )  then cast(format(cast(effective_date as date),'MM/dd/2024') as date)
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) < year(getdate()) and  CONVERT(CHAR(5), effective_date, 101) < CONVERT(CHAR(5), getdate(), 101) ) and  format(cast(effective_date as date),'MM/dd/2025') = '02/29/2025'   then cast(format(cast('03/01/2025' as date),'MM/dd/2025') as date) 
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) < year(getdate()) and  CONVERT(CHAR(5), effective_date, 101) < CONVERT(CHAR(5), getdate(), 101) ) and  format(cast(effective_date as date),'MM/dd/2025') <> '02/29/2025'   then cast(format(cast(effective_date as date),'MM/dd/2025') as date)
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) = year(getdate())  ) and format(cast(effective_date as date),'MM/dd/2025') = '02/29/2025' then  cast(format(cast('03/01/2025' as date),'MM/dd/2025') as date)
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) = year(getdate())  ) and format(cast(effective_date as date),'MM/dd/2025') <> '02/29/2025' then  cast(format(cast(effective_date as date),'MM/dd/2025') as date)
            when (cancel_effective_date = '' or cancel_effective_date is null)  and ( year(effective_date) > year(getdate())  ) then cast(effective_date as date)
            else null 
            end as expiration_date,*
        from actpendinsur_allwithhistory
        ) q
        on b.DOT_NUMBER = right(q.usdot_number, 7)
        where b.dot_number<>0 and c.state_code in (`+states+`)` + dates + comp + operation_query + commodities_query + `) q`
        console.log(query)
        setLoading(true)
        getData(query).then((data) => {
            setReportData(data)
        }).catch((error) => {
            setLoading(false)
            setIsallopen(false)
            console.error('Error:', error);
        });
    }


    const downloadReport = () => {
        console.log("Hello", reportData)
        if (reportData !== undefined) {
            if (reportData.length > 0) {
                const data = convertToCSV(reportData)
                downloadCSV(data, 'report')
            }
        }
    }
    const handleSubmit = (length) => {
        if (length === 0) {
            return;
        }
        setIsallopen(true)
    }

    const getCities = async () => {
        try {
            const states = selectedPaths.length == 0 ? "''" : selectedPaths.map(item => `'${item}'`).join(',');
            const response = await axios.post(`${BASE_URL}/getAllCities`, { PHY_ST: states });
            if (response.data.hasOwnProperty('result')) {
                const cities = response.data['result']
                return cities
            }
        } catch (error) {
            return []
        }
    }
    const getZips = async () => {
        try {
            const states = selectedPaths.length == 0 ? "''" : selectedPaths.map(item => `'${item}'`).join(',');
            const citiesList = cities.length == 0 ? "''" : cities.map(item => `'${item}'`).join(',');
            const response = await axios.post(`${BASE_URL}/getAllZips`, { PHY_ST: states, PHY_CITY: citiesList });
            if (response.data.hasOwnProperty('result')) {
                const cities = response.data['result']
                return cities
            }
        } catch (error) {
            return []
        }
    }
    const [currentPage, setCurrentPage] = useState(<StatePage handleSubmit={handleSubmit} setIsallopen={setIsallopen} isallopen={isallopen} selectedPaths={selectedPaths} setSelectedPaths={setSelectedPaths} companyName={companyName}
        setCompanyName={setCompanyName} companies={companies} setCompanies={setCompanies} selections={selections} removeDates={removeDates} />);
    const handleChange = (event) => {
        const { name, checked } = event.target;
        setItemChecked((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleClick = (buttonName) => {
        setActiveButton(buttonName);
        // Based on the button clicked, set the current page to be rendered
        switch (buttonName) {
            case 'State':
                setCurrentPage(<StatePage handleSubmit={handleSubmit} setIsallopen={setIsallopen} isallopen={isallopen} selectedPaths={selectedPaths} setSelectedPaths={setSelectedPaths} companyName={companyName}
                    setCompanyName={setCompanyName} companies={companies} setCompanies={setCompanies} handleRemove={handleRemove} selections={selections} removeDates={removeDates} />);
                break;
            case 'City':
                getCities().then((data) => {
                    console.log(data)
                    setCurrentPage(<CityLeader cityName={cities} setCityName={setCities} cities={data} />);
                }).catch((error) => {
                    console.error('Error:', error);
                });
                break;
            case 'Zip code':
                getZips().then((data) => {
                    console.log(data)
                    setCurrentPage(<ZipCodePage zipCodes={zips} setZipCodes={setZips} zips={data} />);
                }).catch((error) => {
                    console.error('Error:', error);
                });
                break;
            // case 'Insurance Company':
            //     setCurrentPage(<InsuranceCompanyPage />);
            //     break;
            default:
                setCurrentPage(null); // Handle default case or do nothing
        }
    };
    const loaderOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    };




    const [insuracne, setInsurance] = useState([])
    const [date, setDate] = useState([])



    const { checkboxes1, removeFieldState1 } = operationstore()
    const { checkboxes, removeFieldState } = commodityStore()
    const { checkedBoxes, removeFieldState2 } = fedralAuthoritytore()
                   
    const filteredKeys = Object?.keys(checkboxes)?.filter(
        (key) => checkboxes[key]?.checked || checkboxes[key]?.active)

    const filteredKeys1 = Object?.keys(checkboxes1)?.filter(
        (key) => checkboxes1[key]?.checked || checkboxes1[key]?.active
    );
    const filteredKeys2 = Object.keys(checkedBoxes)?.filter(
        (key) =>
          checkedBoxes[key]?.checked || 
          checkedBoxes[key]?.active || 
          (key === 'common' && checkedBoxes['common'] === '1')||
          (key === 'contract' && checkedBoxes['contract'] === '1')||
          (key === 'broker' && checkedBoxes['broker'] === '1')||
          (key === 'common' && checkedBoxes['common'] === '2')||
          (key === 'contract' && checkedBoxes['contract'] === '2')||
          (key === 'broker' && checkedBoxes['broker'] === '2')
      );
      

    useEffect(() => {
        setInsurance(companyName)
        setDate(selections)
    }, [companyName, selections])


    const handleRemoveInsurance = (insurance) => {
        handleInsuranceRemove(insurance);
    };



    const removeInsuranceDates = (date) => {
        removeDates(date)
    }

    const removeInsuranceDates1 = (date) => {
        removeInsuranceDates(date);

    };


    const handleInsuranceRemove = (company) => {

        handleRemove(company); // Using the passed handleRemove function

    };
    return (
        <>
            <div className="coverofleads">
                <div className="coverofalltopbutton">
                    <div className="Every_Button" onClick={() => { setIsBusinessOpen(true) }}>
                        Business length
                    </div>
                    <div className="Every_Button" onClick={() => { setIsInsuranceOpen(true) }}>
                        Insurance
                    </div>
                    <div className="Every_Button" onClick={() => { setIsauthority(true) }}>
                        Federal authority
                    </div>
                    <div className="Every_Button" onClick={() => { setIsoperationopen(true) }}>
                        Operations
                    </div>
                    <div className="Every_Button" onClick={() => { setCommoditiesopen(true) }}>
                        Commodities
                    </div>
                    {/* <div className="Every_Button" onClick={() => { setEquipmentOpen(true) }}>
                        Equipment
                    </div>
                    <div className="Every_Button" onClick={() => { setSaftyOpen(true) }}>
                        Safety
                    </div> */}
                </div>
                <div className="Leads_Routing">
                    <div className="coverofnavigatore">
                        <div className="Navigationbuttonoflead">
                            <div
                                className={`buttonforlead ${activeButton === 'State' ? 'activelead' : ''}`}
                                onClick={() => handleClick('State')}
                                style={{ borderRadius: "16px 0px 0px 16px", width: "154px" }}
                            >
                                State
                            </div>
                            <div
                                className={`buttonforlead ${activeButton === 'City' ? 'activelead' : ''}`}
                                onClick={() => handleClick('City')}
                                style={{ width: "144px" }}
                            >
                                City
                            </div>
                            <div
                                className={`buttonforlead ${activeButton === 'Zip code' ? 'activelead' : ''}`}
                                onClick={() => handleClick('Zip code')}
                                style={{ borderRadius: "0px 16px 16px 0px", width: "176px" }}
                            >
                                Zip code
                            </div>
                            {/* <div
                                className={`buttonforlead ${activeButton === 'Insurance Company' ? 'activelead' : ''}`}
                                onClick={() => handleClick('Insurance Company')}
                                style={{ borderRadius: "0px 16px 16px 0px", width: "155px" }}
                            >
                                Insurance Company
                            </div> */}

                        </div>
                        <div className="coverforallcheck">
                            <div className="coverofcheckboxes">
                                Limit results to companies with the following data:
                            </div>
                            <div className="emailcheckboses">
                                <div className="innerofcheck">
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedItem?.email} size="large" onChange={handleChange} />}
                                        label="Email"
                                        name="email"
                                    />

                                </div>
                                <div className="innerofcheck">
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedItem?.phone} size="large" onChange={handleChange} />}
                                        label="Phone"
                                        name="phone"
                                    />
                                </div>
                                <div className="innerofcheck">
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedItem?.companyRep} size="large" onChange={handleChange} />}
                                        label="Company Rep"
                                        name="companyRep"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    {currentPage}
                    <BusinessLength isBusinessOpen={isBusinessOpen} setIsBusinessOpen={setIsBusinessOpen} setUsdots={setBusinessUsdot} />

                    <Insurance isInsuranceOpen={isInsuranceOpen} setIsInsuranceOpen={setIsInsuranceOpen} setUsdots={setInsuranceUsdot} setLoading={setLoading} companies={companies} setCompanies={setCompanies} companyName={companyName} setCompanyName={setCompanyName}
                        handleRemove={handleRemove}
                        selections={selections}
                        setSelections={setSelections}
                        insurUsdot={insurUsdot}
                        setInsurUsdot={setInsurUsdot}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedDate1={selectedDate1}
                        setSelectedDate1={setSelectedDate1}
                        removeDates={removeDates}
                    />
                    <FedaralAuthority isauthority={isauthority} setIsauthority={setIsauthority} setUsdots={setFederalUsdot} />
                    <Operation isoperationopen={isoperationopen} setIsoperationopen={setIsoperationopen} setUsdots={setOperationUsdot} />
                    <Commodities commoditiesopen={commoditiesopen} setCommoditiesopen={setCommoditiesopen} setUsdots={setCommoditiesUsdot} />
                    <Equipment equipmentOpen={equipmentOpen} setEquipmentOpen={setEquipmentOpen} />
                    <Safety saftyOpen={saftyOpen} setSaftyOpen={setSaftyOpen} />
                    <Multiple isallopen={isallopen} setIsallopen={setIsallopen} showModal={showModal} loading={loading} />
                </div>
                <TagList
                    path={selectedPaths}
                    insuracne={insuracne}
                    date={date}
                    filteredKeys={filteredKeys}
                    filteredKeys1={filteredKeys1}
                    filteredKeys2={filteredKeys2}
                    checkboxes={checkboxes}
                    checkboxes1={checkboxes1}
                    checkedBoxes={checkedBoxes}
                    handleRemove={handleRemove}
                    handleRemoveInsurance={handleRemoveInsurance}
                    removeInsuranceDates1={removeInsuranceDates1}
                    removeFieldState={removeFieldState}
                    removeFieldState1={removeFieldState1}
                    removeFieldState2={removeFieldState2}
                />
            </div>
            <SalesSearchModal reportData={reportData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} downloadReport={downloadReport} />
            {/* Render the current page based on active button */}
            {loading && (
                <div style={loaderOverlayStyle}>
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </div>
            )
            }

        </>
    )
}


// Define your different page components here
function StatePage({ handleSubmit, setIsallopen, isallopen, selectedPaths, setSelectedPaths, companyName,
    setCompanyName, handleRemove, selections, removeDates }) {
    const [tooltip, setTooltip] = useState({ display: false, name: '', x: 0, y: 0 });
    const [path, setPath] = useState(selectedPaths);
    console.log(companyName, 'companyName')
    const clearState = () => {
        setSelectedPaths([])
        setPath([])
    }
 
    const handleInsuranceRemove = (company) => {
        handleRemove(company); // Using the passed handleRemove function
    };

    const removeInsuranceDates = (date) => {
        removeDates(date)
    }
    const handleMouseEnter = (event, pathId, name) => {
        if (path.length == 0) {
            setTooltip({
                display: true,
                name,
                x: event.clientX,
                y: event.clientY,
            });
        }
    };
    const handleMouseLeave = (pathId) => {
        setTooltip({ display: false, name: '', x: 0, y: 0 });
    };
    return <>
        <div className="textformap" style={{ display: 'flex', justifyContent: 'center' }}>
            Choose the states you would like to include in your research
        </div>
        <div className='boxcontainer'>
            <Map selectedPaths={selectedPaths} setSelectedPaths={setSelectedPaths} tooltip={tooltip} setTooltip={setTooltip} clearState={clearState} path={path} setPath={setPath} companyName={companyName} handleInsuranceRemove={handleInsuranceRemove} selections={selections} removeInsuranceDates={removeInsuranceDates} />
        </div>
        <div className="bottom_buttonmap">
            <span className="clear_Button" onClick={clearState}>
                Clear
            </span>
            <div className="buttonofsubmit" onClick={() => handleSubmit(path.length)}
                onMouseEnter={(event) => handleMouseEnter(event, 'Submit', 'No Location Selected')}
                onMouseLeave={() => handleMouseLeave('Submit')}
            >
                Submit
            </div>
        </div>
        {tooltip.display && (
            <div
                className="tooltiped"
                style={{ top: tooltip.y + 10, left: tooltip.x + 10 }}
            >
                {tooltip.name}
            </div>

        )
        }
    </>
        ;
}
function CityLeader({ cityName, setCityName, cities }) {
    const [selectedCities, setSelectedCities] = useState(cityName)
    const handleChange = (city) => {
        const isSelected = selectedCities.includes(city);

        if (!isSelected) {
            setSelectedCities([...selectedCities, city])
            setCityName([...selectedCities, city]);
        }
    };
    const handleRemove = (city) => {
        const isSelected = selectedCities.includes(city);
        if (isSelected) {
            setSelectedCities(selectedCities.filter(pathId => pathId !== city));
            setCityName(selectedCities.filter(pathId => pathId !== city));
        }
    }

    return <>
        <div className="coverofleadcity">
            <span className="headingofpage">
                City
            </span>
            <Select onChange={handleChange} defaultValue={cities.length == 0 ? '' : cities[0].PHY_CITY} className="custom-select" dropdownClassName="custom-dropdown"
                dropdownStyle={{ marginTop: '20px' }} // Add more space between input and dropdown menu
                suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M16.6667 6.66602L10.4167 12.916L4.16675 6.66602" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
                showSearch
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {cities.map((option, index) => (
                    <Option value={option.PHY_CITY}>{option.PHY_CITY}</Option>
                ))}
            </Select>

        </div>
        <div className="bottom_button">
            <span className="clear_Button">
                Clear
            </span>
            <div className="buttonofsubmit">
                Submit
            </div>
        </div>
        {selectedCities.map((city, index) => (
            <Tag
                key={city}
                className="selectedCityTag"
                closable
                onClose={() => handleRemove(city)}
                style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
            >
                {city}
            </Tag>
        ))}
    </>
        ;
}



function ZipCodePage({ zipCodes, setZipCodes, zips }) {
    const [selectedZips, setSelectedZips] = useState(zipCodes)

    const handleChange = (zip) => {
        const isSelected = selectedZips.includes(zip);

        if (!isSelected) {
            setSelectedZips([...selectedZips, zip]);
            setZipCodes([...selectedZips, zip]);
        }
    };

    const handleRemove = (zip) => {
        const isSelected = selectedZips.includes(zip);
        if (isSelected) {
            setSelectedZips(selectedZips.filter(pathId => pathId !== zip));
            setZipCodes(selectedZips.filter(pathId => pathId !== zip));
        }
    }
    return <>
        <div className="coverofleadcity">
            <span className="headingofpage">
                Zip code:
            </span>
            <Select onChange={handleChange} defaultValue={zips.length == 0 ? '' : zips[0].PHY_ZIP} className="custom-select" dropdownClassName="custom-dropdown"
                dropdownStyle={{ marginTop: '20px' }} // Add more space between input and dropdown menu
                suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M16.6667 6.66602L10.4167 12.916L4.16675 6.66602" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
                showSearch
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {zips.map((option, index) => (
                    <Option value={option.PHY_ZIP}>{option.PHY_ZIP}</Option>
                ))}
            </Select>

        </div>
        <div className="bottom_button">
            <span className="clear_Button">
                Clear
            </span>
            <div className="buttonofsubmit">
                Submit
            </div>
        </div>
        {selectedZips.map((zip, index) => (
            <Tag
                key={zip}
                closable
                onClose={() => handleRemove(zip)}
                style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
            >
                {zip}
            </Tag>
        ))}
    </>

}



export default LeadHome;
