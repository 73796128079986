import './login.css';
import logimage from './loginimage.png';
import Quotes from './Quotes.png';
import brandlogo from './Brand.svg';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from '../Constants/BaseUrl';
import React, { useState } from 'react';
import { Modal } from 'antd';
import correct from './true.png';
import cross from './crosss.png';
function Login() {
  const [loginuser, setLoginUser] = useState(true);
  const [forget, setForget] = useState(false);
  const [email, setEmail] = useState(''); // State for email input
  const [password, setPassword] = useState(''); // State for password input
  const [successmodal, setSuccessmodal] = useState(false);
  const [errormodal, setErrorModal] = useState(false);
  const [found, setFound] = useState(false);
  const navigate = useNavigate(); // Initialize useHistory for navigation
  const [forgetemail, setForgetemail]=useState("")
  const handleLogin = async () => {
    try {
      const response = await axios.post(`${SERVER_URL}/users/login`, {
       email: email.toLowerCase(),
        password,
      });

      // Check response status and handle success
      if (response.status === 200) {
        const { data } = response.data; // Extract data from response
        localStorage.setItem('userId', data._id); 
        setSuccessmodal(true); // Show success modal
        // Navigate to '/home/myinfo'
        navigate('/home/myinfo');
      } else {
        setErrorModal(true); // Show error modal
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorModal(true); // Show error modal on error
    }
  };
  const[typepas, setTypepass]=useState('password')

  const handleforget = () => {
    setLoginUser(false);
    setForget(true);
  };


  const handlefoegetpass = async()=>{
    try {
      const response = await axios.post(`${SERVER_URL}/send-password`, {
       email: forgetemail.toLowerCase(),
      });
      // Check response status and handle success
      if (response.data.status === true) {
        setFound(true); // Show success modal
      } else {
        setErrorModal(true); // Show error modal
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorModal(true); // Show error modal on error
    }
  }
  return (
    <div className='d-flex'>
      <div className='formside'>
        <div className='loginform'>
          <img className='brandimage' src={brandlogo} alt='brandlogo' />
          {loginuser ? (
          <div className='inputfields'>
            <div className='welcome'>Welcome back</div>
            <div className='headtext'> Sign in to your account.</div>
            <div className='inputf'>
              <span className='labelinput'>Email</span><br />
              <input
               placeholder='Ex. mail@mymail.com'
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               />
            </div>
            <div className='inputf'>
              <span className='labelinput'>Password</span><br />
              <div className='inputpasscover'>
              <input placeholder='********'
               value={password}
               className='inputpass'
               type={typepas}
               onChange={(e) => setPassword(e.target.value)}
              />
              <div className='iconpas'>
              {
  typepas === "password" ? (
    <svg
    onClick={() => setTypepass("text")} 

    xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_5066_68164)">
      <path d="M1.66406 1.66699L18.3307 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.59137 5.60249C3.05139 7.32972 1.66406 10.0003 1.66406 10.0003C1.66406 10.0003 4.69436 15.8337 9.99739 15.8337C11.706 15.8337 13.1786 15.2282 14.39 14.4073M9.16406 4.21551C9.43523 4.18377 9.71306 4.16699 9.99739 4.16699C15.3004 4.16699 18.3307 10.0003 18.3307 10.0003C18.3307 10.0003 17.7542 11.1101 16.6641 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5066_68164">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  ) : (

    <svg
    onClick={() => setTypepass("password")} 

    xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_5066_68164)">
      {/* <path d="M1.66406 1.66699L18.3307 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
      <path d="M5.59137 5.60249C3.05139 7.32972 1.66406 10.0003 1.66406 10.0003C1.66406 10.0003 4.69436 15.8337 9.99739 15.8337C11.706 15.8337 13.1786 15.2282 14.39 14.4073M9.16406 4.21551C9.43523 4.18377 9.71306 4.16699 9.99739 4.16699C15.3004 4.16699 18.3307 10.0003 18.3307 10.0003C18.3307 10.0003 17.7542 11.1101 16.6641 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5066_68164">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

              </div>
              </div>
              <div className='textforforget' onClick={handleforget}>
                Forgot your password? <span className='forgot'>Forgot my password</span>
              </div>
            </div>
            <div className='loginBtn' onClick={handleLogin}> <span>Login</span></div>
            <div className='bottomtext'>
              <div className='forbot'>
                Don't have an account? <span className='forgot'>Create one here.</span>
              </div>
            </div>
          </div>):(null)}
          {forget ? (
             <div className='inputfields'>
             <div className='headtext'>Forgot my password</div>
             <div className='inputf'>
               <span className='labelinput'>Email</span><br />
               <input
                placeholder='Ex. mail@mymail.com'
                value={forgetemail}
                onChange={(e) => setForgetemail(e.target.value)}
                />
             </div>
          
             <div className='loginBtn' onClick={handlefoegetpass}>Verify</div>
             <div className='bottomtext'>
               <div className='forbot'>
                 Don't have an account? <span className='forgot'>Create one here.</span>
               </div>
             </div>
           </div>
          ):(null)}
        </div>
      </div>
      <div className='loginimageside' style={{ width: '620px' }}>
        <div className='forlogimg'>
          <img src={logimage} alt='log' />
        </div>
        <div className='textdown'>
          <img src={Quotes} alt='quotes' />
          <div className='textlar'>
            I was able to grow my agency by an extra $1,000,000 per year organically with Freight Insurance Data
          </div>
          <div className='textun'>
            -Thomas Gonzalez (BRT Ins Group)
          </div>
        </div>
      </div>
      <Modal open={successmodal} footer={null} closeIcon={null} width={379}>
        <div className="successdiv">
          <div className="imgco">
            <img src={correct} alt="correct" />
          </div>
          <div className="loginhead">
            Congratulations! You have successfully logged in
          </div>
          <button className="loginbtn">Go To Dashboard</button>
        </div>
      </Modal>
      <Modal open={found} footer={null} closeIcon={null} width={379}>
        <div className="successdiv">
          <div className="imgco">
            <img src={correct} alt="correct" />
          </div>
          <div className="loginhead">
            Account Found! Your password has been send through your email
          </div>
          <button className="loginbtn" onClick={()=>{setFound(false)}}>Go To Dashboard</button>
        </div>
      </Modal>

      <Modal open={errormodal} footer={null} closeIcon={null} width={379}>
        <div className="successdiv">
          <div className="imgco">
            <img src={cross} alt="correct" />
          </div>
          <div className="loginhead">Sorry! Email or Password is Incorrect</div>
          <button className="loginbtn" onClick={() => setErrorModal(false)}>
            Try Again
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Login;
