import { Link } from "react-router-dom";
import "./carier.css"
const Tableofcarier = () => {
    const dataoftable = [
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "888-888-888", state: "OH", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "777-777-777", state: "PA", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "666-666-666", state: "CA", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "555-555-555", state: "LA", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "444-888-888", state: "OH", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "333-888-888", state: "CH", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "888-222-888", state: "KI", Comments: "(2)" },
        { Companyname: "Progressive insurance", NAIC: "2284379", Email: "commercialauto@progressive.com", Phone: "888-888-888", state: "NY", Comments: "(2)" },

    ]
    return (
        <>
            <div className="carriercontain">
                <Link to="/carierdata">
                    <div className="button_of_search" >
                        New search
                    </div>  </Link>
                <div className="usdottitled ">
                    Company name: #Progressive insurance
                </div>
                <div className="tablecover mt-4">
                    <table className="tableofcarier">
                        <tr className="tableheader">
                            <th>
                                Company name
                            </th>
                            <th>
                                NAIC
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Phone
                            </th>
                            <th>
                                State
                            </th> <th>
                                Comments
                            </th>
                        </tr>
                        {
                            dataoftable.map((data) => {
                                return (
                                    <>
                                        <tr className="descoftable">
                                            <Link to="/commentarea"><td><u>{data.Companyname}</u></td></Link>
                                            <td>{data.NAIC}</td>
                                            <td>{data.Email}</td>
                                            <td>{data.Phone}</td>
                                            <td>{data.state}</td>
                                            <td><u>{data.Comments}</u></td>
                                        </tr>
                                    </>
                                )

                            })
                        }

                    </table>
                </div>

            </div>
        </>
    )
}
export default Tableofcarier;