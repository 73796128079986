import { useState, useEffect } from "react";
import "./Usdot.css"
import Chameleon from "./Chameleon";
import InspectionCrash from "./CrashInspection/InspectionCrash";
import InsprctionDetail from "./InsprctionDetail";
import { Modal } from 'antd';
import { Select } from 'antd';
import DetailModelBody from "./Components/InspectionDetailPage"
import { ComparisionModel } from "./Components/ComparisionModel"
import { useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import axios from 'axios';
import { BASE_URL } from "../Constants/BaseUrl";
import { displayData, toPascalCase } from "../Utility/Services"

const { Option } = Select;
const Usdot = () => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [viewdetail, setViewdetail] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { data, inspections, similar_dots, crash, crashReport } = location.state;
    console.log(crash, "crash from the backend page===>")
    console.log(similar_dots, "similar_dots+++=")
    const [compareInspections, setCompareInspections] = useState([]);
    const [violations, setViolations] = useState(null);
    const [company, setCompany] = useState(null);
    const [report, setReport] = useState(null);
    const [vehicleDetails, setVehicleDetails] = useState(null);
    const getInspection = async (option, inspection_id) => {
        try {
            const response = await axios.post(`${BASE_URL}/getReportDetailsByInspectionID`, { inspection_id: inspection_id });
            if (response.data.hasOwnProperty('result')) {
                const report = response.data['result']['report'];
                const vehicles = response.data['result']['vehicle'];
                const violations = response.data['result']['violation'].hasOwnProperty('error') ? [] : response.data['result']['violation'];
                setCompany(option)
                setViolations(violations)
                setVehicleDetails(vehicles)
                setReport(report[0])
                setViewdetail(true);
            }
        } catch (error) {
            console.error('Error submitting ID:', error);
        }
    }
    const openDetail = (option, inspection_id) => {
        getInspection(option, inspection_id)
    };
    const closeDetail = () => {
        setViewdetail(false);
    }
    const showModal = () => {
        setIsModalOpened(true);
    };

    const handleOk = () => {
        setIsModalOpened(false);
    };

    const handleCancel = () => {
        setIsModalOpened(false);
    };

    const [selectedClassifications, setSelectedClassifications] = useState([]);
    const [selectedOprations, setSelectedOprations] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [cargoCarried, setCargoCarried] = useState("MOTOR VEHICLES");
    const [carrierOperation, setCarrierOperation] = useState("INTERSTATE");
    const [companyRepresentative, setCompanyRepresentative] = useState("ALESSANDRO DALUZ");
    const [companyCompRepresentative, setCompanyCompRepresentative] = useState("");
    const [dbaName, setDbaName] = useState("");
    const [dotNumber, setDotNumber] = useState(2284379);
    const [dunsNumber, setDunsNumber] = useState("");
    const [email, setEmail] = useState("Ltfreight60@gmail.com");
    const [entityType, setEntityType] = useState("CARRIER");
    const [legalName, setLegalName] = useState("LIGHT TOWING LLC");
    const [mcs150Date, setMcs150Date] = useState(20231108);
    const [operationClassification, setOperationClassification] = useState("Auth. for hire");
    const [phone, setPhone] = useState(2408321330);
    const [phoneCom, setComPhone] = useState(2408321330);
    const [physicalAddress, setPhysicalAddress] = useState("22600 WOODFIELD ROAD31MD20882GAITHERSBURG");
    const [physicalComAddress, setPhysicalComAddress] = useState("");
    const [drivers, setDrivers] = useState(11);
    const [mailingAddress, setMailingAddress] = useState("22600 WOODFIELD RDGAITHERSBURGMD20882");
    const [mcMxFfNumber, setMcMxFfNumber] = useState("MC780278");
    const [mcs150Mileage, setMcs150Mileage] = useState("15000002022");
    const [powerUnits, setPowerUnits] = useState(12);
    const [compCrash, setCompCrash] = useState([]);
    console.log(compCrash, "compCrash====>")
    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async (usDot) => {
        setDefaultValue(usDot)
        setCompanyCompRepresentative(toPascalCase(displayData(data?.COMPANY_REPRESENTATIVE)))
        setPhysicalComAddress(displayData((toPascalCase(displayData(data?.PHYSICAL_ADDRESS)))));
        setComPhone(displayData(data?.PHONE));
        try {
            const response = await axios.post(`${BASE_URL}/getChameleonByUSDOT`, { dot_number: usDot });
            console.log(response.data)
            if (response.data.hasOwnProperty('result')) {
                // setSubmit(true)
                const data = response.data['result']['data'][0];
                const crash = response.data['result']['crash'].hasOwnProperty('error') ? [] : response.data['result']['crash']
                const inspections = response.data['result']['inspection'].hasOwnProperty('error') ? [] : response.data['result']['inspection'];
                const crashReport = response.data['result']['crashReport'].hasOwnProperty('error') ? [] : response.data['result']['crashReport'];
                setCompareInspections(inspections)
                setCompCrash(crash)
                setCargoCarried(displayData(data?.CARGO_CARRIED));
                setCarrierOperation(displayData(data?.CARRIER_OPERATION));
                setCompanyRepresentative(toPascalCase(displayData(data?.COMPANY_REPRESENTATIVE)));
                setDbaName(displayData(data?.DBA_NAME));
                setDotNumber(displayData(data?.DOT_NUMBER));
                setDunsNumber(displayData(data?.DUNS_NUMBER));
                setPhone(displayData(data?.PHONE));
                setEmail(toPascalCase(displayData(data?.EMAIL)));
                setEntityType(displayData(data?.ENTITY_TYPE));
                setLegalName(displayData(toPascalCase(data?.LEGAL_NAME)));
                setMcs150Date(displayData(data?.MCS_150_DATE));
                setOperationClassification(displayData(data?.OPERATION_CLASSIFICATION));
                setPhysicalAddress(displayData((toPascalCase(displayData(data?.PHYSICAL_ADDRESS)))));
                setDrivers(displayData(data?.drivers));
                setMailingAddress(displayData(toPascalCase(data?.mailing_address)));
                setMcMxFfNumber(displayData(data?.mc_mx_ff_number));
                setMcs150Mileage(displayData(data?.mcs_150_mileage));
                setPowerUnits(displayData(data?.power_units));
            } else {
                // setSubmit(false)
                setErrorText(response?.data['error'])
            }
        } catch (error) {
            console.error('Error submitting ID:', error);
            // setSubmit(false)
            setErrorText('Error in fetching data.')
        }
    };
    useEffect(() => {
        if (similar_dots.length > 0) {
            handleSubmit(similar_dots[0]?.dot_numbers_relavent)
        } else {
            handleSubmit(data.DOT_NUMBER)
        }
    }, []);


    const handleChange = (event) => {
        handleSubmit(event)
    };

    const Row = ({ index, style }) => (
        <option value={similar_dots[index].dot_numbers_relavent} style={style}>
            {similar_dots[index].dot_numbers_relavent}
        </option>
    );

    const [Vehicles, setVehicles] = useState([
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
    ]);

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };
    const handleClick = (index) => {
        const selectedIndex = selectedClassifications.indexOf(index);
        if (selectedIndex === -1) {
            // If the classification is not already selected, add it to the array
            setSelectedClassifications([...selectedClassifications, index]);
        } else {
            // If the classification is already selected, remove it from the array
            const updatedSelectedClassifications = [...selectedClassifications];
            updatedSelectedClassifications.splice(selectedIndex, 1);
            setSelectedClassifications(updatedSelectedClassifications);
        }
    };
    const handleoptions = (index) => {
        const selectedIndex = selectedOprations.indexOf(index);
        if (selectedIndex === -1) {
            // If the classification is not already selected, add it to the array
            setSelectedOprations([...selectedOprations, index]);
        } else {
            // If the classification is already selected, remove it from the array
            const updatedSelectedClassifications = [...selectedOprations];
            updatedSelectedClassifications.splice(selectedIndex, 1);
            setSelectedOprations(updatedSelectedClassifications);
        }
    };
    const handlecargo = (index) => {
        const selectedIndex = selectedCargo.indexOf(index);
        if (selectedIndex === -1) {
            // If the classification is not already selected, add it to the array
            setSelectedCargo([...selectedCargo, index]);
        } else {
            // If the classification is already selected, remove it from the array
            const updatedSelectedClassifications = [...selectedCargo];
            updatedSelectedClassifications.splice(selectedIndex, 1);
            setSelectedCargo(updatedSelectedClassifications);
        }
    };
    const [submit, setSubmit] = useState(true)
    const classifications = [
        { name: "Auth. for hire" },
        { name: "Priv. Pass.(Non-business)" },
        { name: "State Gov’t" },
        { name: "Exempt for hire" },
        { name: "Migrant" },
        { name: "Private (Property)" },
        { name: "Fed. Gov’t" },
        { name: "Indian Nation" },
        { name: "Priv. Pass. (Business)" },
        { name: "U.S. Mail" },
    ]
    const operations = [
        { name: "Interstate" },
        { name: "Intrastate only (HM)" },
        { name: "Intrastate only (Non-HM)" },

    ]
    const Cargo = [
        { name: "General freight" },
        { name: "Household goods" },
        { name: "Motor vehicles" },
        { name: "Drive/ Tow away" },
        { name: "Logs, Poles, Beams, Lumber" },
        { name: "Mobile homes" },
        { name: "Machinery, Large objects" },
        { name: "Fresh produce" },
        { name: "Liquids/ Gases" },
        { name: "Intermodal cont." },
        { name: "Passengers" },
        { name: "Oilfield equipment" },
        { name: "Livestock" },
        { name: "Grain, Feed, Hay" },
        { name: "Coat/ Coke" },
        { name: "Meat" },
        { name: "Gargabe/ Refuse" },
        { name: "US Mail" },
        { name: "Chemicals" },
        { name: "Commodities dry bulk" },
        { name: "Beverages" },
        { name: "Paper products" },
        { name: "Utilities" },
        { name: "Agricultural/ Farm supplies" },
        { name: "Construction" },
        { name: "Water well" },
    ]
    const [isClicked, setIsClicked] = useState(false);

    const handleClicked = () => {
        setIsClicked(!isClicked);
    };
    return (
        <>
            {
                (
                    <>
                        <div className="cover_usdot">
                            <div className="topofusdot">
                                <div className="button_of_search" onClick={() => { navigate("/sales/chameleon") }}>
                                    New search
                                </div>


                            </div>
                            <div className="usdottitled">
                                USDOT Number: {data.DOT_NUMBER}
                            </div>
                            <div className="red_information">
                                <span className="contentarea">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                    </svg>
                                    <span className="mx-2">
                                        This insurance provider shares identical information with <strong>{similar_dots.length}</strong>  other insurers. <strong onClick={showModal}><u>Click here</u></strong>  to view the data for the remaining insurers.

                                    </span>

                                    { data['service_date'] !== '' ? (<span className="date_content">
                                        <strong>
                                            Out of Service Date:</strong> {data['service_date']}
                                    </span>) : ''}
                                </span>
                            </div>


                            <div className="cardcomponent">
                                <div className="card-header">
                                    ID/ Operations
                                </div>
                                <div className="row_wrapper">
                                    <div className="row_content">
                                        <div className="contentofindual">
                                            <div className="data_title">Entity type:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data.ENTITY_TYPE))}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">Operating status:</div>
                                            <div className="data_desc  ">Authorized for property</div>
                                        </div>
                                        <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Legal name:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.LEGAL_NAME))}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">DBA Name:</div>
                                            <div className="data_desc  ">{displayData(data?.DBA_NAME)}</div>
                                        </div>

                                    </div>
                                    <div className="row_content">
                                        <div className="contentofindual" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Company representative:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.COMPANY_REPRESENTATIVE))}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">Phone:</div>
                                            <div className="data_desc  align-item-center">
                                                <span className="mx-1">{displayData(data?.PHONE)}</span>
                                            </div>
                                        </div>

                                        <div className="contentofindual">
                                            <div className="data_title" style={{ wordWrap: "break-word", maxWidth: "300px" }}>Email:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.EMAIL))}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title" s
                                            >Physical address:</div>
                                            <div className="data_desc  " style={{ maxWidth: "146px" }}>{toPascalCase(displayData(data?.PHYSICAL_ADDRESS))}</div>
                                        </div>

                                    </div>
                                    <div className="row_content">
                                        <div className="contentofindual">
                                            <div className="data_title">USDOT number:</div>
                                            <div className="data_desc  ">{displayData(data?.DOT_NUMBER)}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">MC/MX/FF number(s):</div>
                                            <div className="data_desc  ">{displayData(data?.mc_mx_ff_number)} </div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">DUNS number:</div>
                                            <div className="data_desc  ">{displayData(data?.DUNS_NUMBER)}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">Mailing address:</div>
                                            <div className="data_desc  " style={{ maxWidth: "146px" }}>{toPascalCase(displayData(data?.mailing_address))}</div>
                                        </div>

                                    </div>
                                    <div className="row_content">
                                        <div className="contentofindual">
                                            <div className="data_title">Drivers</div>
                                            <div className="data_desc  ">{displayData(data?.drivers)}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">Power units:</div>
                                            <div className="data_desc  ">{displayData(data?.power_units)}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">MCS-150 mileage (Year):</div>
                                            <div className="data_desc  ">{displayData(data?.mcs_150_mileage)}</div>
                                        </div>
                                        <div className="contentofindual">
                                            <div className="data_title">MCS-150 form date:</div>
                                            <div className="data_desc  ">{displayData(data?.MCS_150_DATE)}</div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Operation Classification
                                </div>
                                <div className="classificationwrapper">
                                    {classifications.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={option.name.toLowerCase() == data.OPERATION_CLASSIFICATION.toLowerCase() ? 'classification activating' : 'classification'}
                                            // onClick={() => handleClick(index)}
                                            >

                                                {option.name.toLowerCase() == data.OPERATION_CLASSIFICATION.toLowerCase() && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Carrier Operation
                                </div>
                                <div className="classificationwrapper">
                                    {operations.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={option.name.toLowerCase() == data.CARRIER_OPERATION.toLowerCase() ? 'classification activating' : 'classification'}
                                            // onClick={() => handleoptions(index)}
                                            >

                                                {option.name.toLowerCase() == data.CARRIER_OPERATION.toLowerCase() && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Cargo Carried
                                </div>
                                <div className="classificationwrapper">
                                    {Cargo.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={option.name.toLowerCase() == data?.CARGO_CARRIED.toLowerCase() ? 'classification activating' : 'classification'}
                                            // onClick={() => handlecargo(index)}
                                            >

                                                {option.name.toLowerCase() == data?.CARGO_CARRIED.toLowerCase() && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>



                        </div>
                        <br></br>
                        <br></br>
                    </>
                )
            }
            <div className="coverofcomparision">
                <ComparisionModel
                    data={data}
                    similar_dots={similar_dots}
                    crash={crash}
                    crashReport={crashReport}
                    legalName={legalName}
                    defaultValue={defaultValue}
                    companyRepresentative={companyRepresentative}
                    companyCompRepresentative={companyCompRepresentative}
                    mcMxFfNumber={mcMxFfNumber}
                    physicalAddress={physicalAddress}
                    physicalComAddress={physicalComAddress}
                    phone={phone}
                    mailingAddress={mailingAddress}
                    entityType={entityType}
                    isModalOpened={isModalOpened}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    handleChange={handleChange}
                    mcs150Mileage={mcs150Mileage}
                    inspections={inspections}
                    compareInspections={compareInspections}
                    dotNumber={dotNumber}
                    classifications={classifications}
                    Option={Option}
                    phoneCom={phoneCom}
                    email={email}
                    powerUnits={powerUnits}
                    dunsNumber={dunsNumber}
                    dbaName={dbaName}
                    operationClassification={operationClassification}
                    operations={operations}
                    carrierOperation={carrierOperation}
                    mcs150Date={mcs150Date}
                    Cargo={Cargo}
                    cargoCarried={cargoCarried}
                    openDetail={openDetail}
                    compCrash={compCrash}


                />
            </div>
            <Modal
                open={viewdetail}
                onCancel={() => { setViewdetail(false) }}
                closeIcon={null}
                footer={null}
                width={1104}
                style={{ padding: "0px" }}
            >
                {viewdetail && <DetailModelBody company={company} report={report} vehicles={vehicleDetails} violations={violations} closeDetails={closeDetail} />}
            </Modal>
        </>


    )
}
export default Usdot;