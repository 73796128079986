import "./Client.css";
import { Modal } from "antd";
const DeleteClientModel=({handleDeleteClient,deltemodal,setDeltemodal,deleteindex})=> {

    return (
        <>
        <Modal
                open={deltemodal}
                onCancel={() => setDeltemodal(false)}
                footer={null}
                closeIcon={null}
            >
                <div className="headerofmodal">
                    <span className="headertext">Delete client</span>
                    <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => setDeltemodal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M16 2L2 16"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 16L2 2"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <div className="coverofsubtitle subtitles">

                        <div className="boxofpeople">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M25 17H31" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.5 20C17.6421 20 21 16.6421 21 12.5C21 8.35786 17.6421 5 13.5 5C9.35786 5 6 8.35786 6 12.5C6 16.6421 9.35786 20 13.5 20Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                <path d="M2.77539 25C4.08946 23.4338 5.7306 22.1744 7.58347 21.3104C9.43633 20.4463 11.456 19.9985 13.5004 19.9985C15.5448 19.9985 17.5644 20.4463 19.4173 21.3104C21.2702 22.1744 22.9113 23.4338 24.2254 25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <span className="contentofbus mt-2">Do you want to delete this client?</span>
                        <span className="contentof">Deleting this client will require you to add them again.</span>
                        <div className="deleteclientbtn" onClick={() => { handleDeleteClient(deleteindex) }}>
                            Yes, delete client
                        </div>
                        <span className="cancelbutton" onClick={() => { setDeltemodal(false) }}>Cancel</span>

                    </div>
                </div>
            </Modal>

        </>
    );
}

export default DeleteClientModel;
