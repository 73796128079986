import { Link } from "react-router-dom";
import sitelogo from "../Images/image 4.png"
import { Drawer } from "antd";
const HomeNav = ({ setNavbarLocation, open, onClose }) => {
  return (
    <>
      <div className="main_Nav">
        <img src={sitelogo} className="site_logo" alt="main_logo" />
        <div className="Back_top_Home">


        </div>



        <div className="Navlinks">
          <div className="navitem ">

            {/* SVG for inactive state */}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <span className="nav_content">Home</span>
          </div>

          <div className="navitem">


            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M12.1406 4.52344C12.4997 4.42604 12.8701 4.37613 13.2422 4.375C14.3196 4.375 15.3529 4.80301 16.1148 5.56488C16.8767 6.32675 17.3047 7.36006 17.3047 8.4375C17.3047 9.51494 16.8767 10.5483 16.1148 11.3101C15.3529 12.072 14.3196 12.5 13.2422 12.5" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.25 15.4222C1.88433 14.5195 2.72659 13.7827 3.70563 13.2741C4.68467 12.7655 5.77173 12.5 6.875 12.5C7.97827 12.5 9.06533 12.7655 10.0444 13.2741C11.0234 13.7827 11.8657 14.5195 12.5 15.4222" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.2422 12.5C14.3455 12.4993 15.4328 12.7645 16.412 13.2731C17.3911 13.7817 18.2333 14.5188 18.8672 15.4219" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <span className="nav_content">
              Clients
            </span>

          </div>
          <Link to="/sales">
            <div className="navitem " onClick={() => { setNavbarLocation("sales") }}>



              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M17.5 3.9616V14.7897C17.5001 14.8865 17.4777 14.982 17.4346 15.0687C17.3915 15.1554 17.3288 15.2309 17.2516 15.2892C17.1743 15.3475 17.0845 15.3871 16.9894 15.4048C16.8942 15.4225 16.7962 15.4179 16.7031 15.3913L2.95313 11.3835C2.82246 11.3461 2.70754 11.2672 2.62578 11.1586C2.54401 11.0501 2.49986 10.9178 2.5 10.7819V7.96941C2.49986 7.83351 2.54401 7.70126 2.62578 7.59271C2.70754 7.48416 2.82246 7.40522 2.95313 7.36785L16.7031 3.36003C16.7962 3.33341 16.8942 3.32878 16.9894 3.34649C17.0845 3.3642 17.1743 3.40379 17.2516 3.46211C17.3288 3.52044 17.3915 3.59592 17.4346 3.6826C17.4777 3.76928 17.5001 3.86479 17.5 3.9616Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.625 12.1641V15C5.625 15.1658 5.69085 15.3247 5.80806 15.4419C5.92527 15.5592 6.08424 15.625 6.25 15.625H8.75C8.91576 15.625 9.07473 15.5592 9.19194 15.4419C9.30915 15.3247 9.375 15.1658 9.375 15V5.49219" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <span className="nav_content">
                Sales
              </span>
            </div>
          </Link>



          <div className="navitem ">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M15.3048 4.69559C13.9751 3.36196 12.1933 2.5761 10.3119 2.49356C8.43047 2.41101 6.58663 3.03779 5.14531 4.24985C3.70399 5.46191 2.77022 7.1709 2.52882 9.03858C2.28742 10.9063 2.75599 12.7965 3.84178 14.3352C4.92757 15.8739 6.55145 16.9489 8.39201 17.3474C10.2326 17.7459 12.1557 17.4389 13.7807 16.4872C15.4057 15.5354 16.6142 14.0083 17.167 12.208C17.7198 10.4078 17.5766 8.4656 16.7657 6.7659" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.6484 7.35114C12.029 6.73353 11.2129 6.35237 10.3418 6.27389C9.47062 6.19541 8.59951 6.42455 7.87974 6.92153C7.15997 7.4185 6.63702 8.15189 6.40174 8.99432C6.16645 9.83675 6.23368 10.735 6.59177 11.533C6.94985 12.331 7.57615 12.9784 8.36189 13.3627C9.14762 13.7469 10.0431 13.8439 10.8929 13.6366C11.7426 13.4293 12.4929 12.9308 13.0134 12.2279C13.5339 11.525 13.7918 10.6619 13.7421 9.78864" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 10L17.5 2.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span className="nav_content">
              Training
            </span>
          </div>


          <div className="navitem">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1L1 11.8H9.1L8.2 19L17.2 8.2H9.1L10 1V1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span className="nav_content">
              Reports
            </span>
          </div>

        </div>
      </div>
      <Drawer
        closeIcon={null}
        placement="left"
        width={358}
        onClose={onClose}
        open={open}
        style={{ borderRadius: "0px" }}
      >
        <div className="canvasnav">
          <div className="logoimaeg">
            <img src={sitelogo} className="site_logo" alt="main_logo" />
            <svg onClick={onClose} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M31.25 8.75L8.75 31.25" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M31.25 31.25L8.75 8.75" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className="Back_top_Home">


          </div>



          <div className="Navlinks">
            <div className="navitem ">

              {/* SVG for inactive state */}
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <span className="nav_content">Home</span>
            </div>

            <div className="navitem">


              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M12.1406 4.52344C12.4997 4.42604 12.8701 4.37613 13.2422 4.375C14.3196 4.375 15.3529 4.80301 16.1148 5.56488C16.8767 6.32675 17.3047 7.36006 17.3047 8.4375C17.3047 9.51494 16.8767 10.5483 16.1148 11.3101C15.3529 12.072 14.3196 12.5 13.2422 12.5" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.25 15.4222C1.88433 14.5195 2.72659 13.7827 3.70563 13.2741C4.68467 12.7655 5.77173 12.5 6.875 12.5C7.97827 12.5 9.06533 12.7655 10.0444 13.2741C11.0234 13.7827 11.8657 14.5195 12.5 15.4222" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.2422 12.5C14.3455 12.4993 15.4328 12.7645 16.412 13.2731C17.3911 13.7817 18.2333 14.5188 18.8672 15.4219" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <span className="nav_content">
                Clients
              </span>

            </div>
            <Link to="/sales">
              <div className="navitem " onClick={() => { setNavbarLocation("sales") }}>



                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17.5 3.9616V14.7897C17.5001 14.8865 17.4777 14.982 17.4346 15.0687C17.3915 15.1554 17.3288 15.2309 17.2516 15.2892C17.1743 15.3475 17.0845 15.3871 16.9894 15.4048C16.8942 15.4225 16.7962 15.4179 16.7031 15.3913L2.95313 11.3835C2.82246 11.3461 2.70754 11.2672 2.62578 11.1586C2.54401 11.0501 2.49986 10.9178 2.5 10.7819V7.96941C2.49986 7.83351 2.54401 7.70126 2.62578 7.59271C2.70754 7.48416 2.82246 7.40522 2.95313 7.36785L16.7031 3.36003C16.7962 3.33341 16.8942 3.32878 16.9894 3.34649C17.0845 3.3642 17.1743 3.40379 17.2516 3.46211C17.3288 3.52044 17.3915 3.59592 17.4346 3.6826C17.4777 3.76928 17.5001 3.86479 17.5 3.9616Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.625 12.1641V15C5.625 15.1658 5.69085 15.3247 5.80806 15.4419C5.92527 15.5592 6.08424 15.625 6.25 15.625H8.75C8.91576 15.625 9.07473 15.5592 9.19194 15.4419C9.30915 15.3247 9.375 15.1658 9.375 15V5.49219" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <span className="nav_content">
                  Sales
                </span>
              </div>
            </Link>



            <div className="navitem ">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.3048 4.69559C13.9751 3.36196 12.1933 2.5761 10.3119 2.49356C8.43047 2.41101 6.58663 3.03779 5.14531 4.24985C3.70399 5.46191 2.77022 7.1709 2.52882 9.03858C2.28742 10.9063 2.75599 12.7965 3.84178 14.3352C4.92757 15.8739 6.55145 16.9489 8.39201 17.3474C10.2326 17.7459 12.1557 17.4389 13.7807 16.4872C15.4057 15.5354 16.6142 14.0083 17.167 12.208C17.7198 10.4078 17.5766 8.4656 16.7657 6.7659" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.6484 7.35114C12.029 6.73353 11.2129 6.35237 10.3418 6.27389C9.47062 6.19541 8.59951 6.42455 7.87974 6.92153C7.15997 7.4185 6.63702 8.15189 6.40174 8.99432C6.16645 9.83675 6.23368 10.735 6.59177 11.533C6.94985 12.331 7.57615 12.9784 8.36189 13.3627C9.14762 13.7469 10.0431 13.8439 10.8929 13.6366C11.7426 13.4293 12.4929 12.9308 13.0134 12.2279C13.5339 11.525 13.7918 10.6619 13.7421 9.78864" stroke="#80888E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 10L17.5 2.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span className="nav_content">
                Training
              </span>
            </div>


            <div className="navitem">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1L1 11.8H9.1L8.2 19L17.2 8.2H9.1L10 1V1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span className="nav_content">
                Reports
              </span>
            </div>

          </div>
        </div>

      </Drawer>
    </>
  )
}
export default HomeNav;