// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wraperofcrash .operationalclassification{
    margin-top: 20px;
    width: 49%;
}
.wraperofcrash{
    display: flex;
    justify-content: space-between;
}
.wraperofcrash .card-header{
    width: 100%;
}
.coverofcrash{
    display: flex;
}
.induvidual{
    width: 50%;
    row-gap: 30px;
    display: grid;
    padding: 20px;
}
.coverofclass{
    display: grid;
}
.mainworker .induvidual{
    width: auto;  
}
.mainworker{
    column-gap: 90px;
}
  table {
    border-collapse: separate;
    border-spacing: 10px;
    width: 100%;
  }

  th, td {
    padding: 10px;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/CrashInspection/Crash.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;EACE;IACE,yBAAyB;IACzB,oBAAoB;IACpB,WAAW;EACb;;EAEA;IACE,aAAa;EACf","sourcesContent":[".wraperofcrash .operationalclassification{\r\n    margin-top: 20px;\r\n    width: 49%;\r\n}\r\n.wraperofcrash{\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n.wraperofcrash .card-header{\r\n    width: 100%;\r\n}\r\n.coverofcrash{\r\n    display: flex;\r\n}\r\n.induvidual{\r\n    width: 50%;\r\n    row-gap: 30px;\r\n    display: grid;\r\n    padding: 20px;\r\n}\r\n.coverofclass{\r\n    display: grid;\r\n}\r\n.mainworker .induvidual{\r\n    width: auto;  \r\n}\r\n.mainworker{\r\n    column-gap: 90px;\r\n}\r\n  table {\r\n    border-collapse: separate;\r\n    border-spacing: 10px;\r\n    width: 100%;\r\n  }\r\n\r\n  th, td {\r\n    padding: 10px;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
