import { useState } from "react";
import "../Usdot.css"
import "../Drive.css"
import Deepdive from "./Deepdive";
import Quotes from "../Quote";
import Combodies from "../Combodies";
import AddDriver from "../AddDriver";
import Lossruns from "./Lossruns";
import Nav from "../CampanyDetail.jsx/Nav";
const DeepDriveDetail = ({Vehicles,setVehicles,setShowclient}) => {
    const [submit, setSubmit] = useState(true)

    return (
        <>
          {
                submit ? (
                    <>
                        <div className="cover_usdot">
                            <div className="topofusdot">
                                <div className="button_of_search" onClick={() => { setSubmit(false) }}>
                                    New search
                                </div>
                                <div className="logo_for_dot">
                                    <div className="download">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M6.71875 8.59375L10 11.875L13.2812 8.59375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 3.125V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="print">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M5 6.25V3.125H15V6.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15 11.875H5V17.1875H15V11.875Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5 13.75H2.1875V7.5C2.1875 6.8125 2.79687 6.25 3.53906 6.25H16.4609C17.2031 6.25 17.8125 6.8125 17.8125 7.5V13.75H15" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#374147" />
                                        </svg>
                                    </div>


                                </div>
                            </div>
                            <div className="usdottitled">
                                USDOT Number: #2284379
                            </div>

                            <Quotes />
                            <div className="cardcomponent">
                                <div className="card-header">
                                    Company information
                                </div>
                                <div className="compinfo">
                                    <div className="coverofcompinfo">
                                        <div className="infosection">
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    USDOT Number:
                                                </span>
                                                <span className="descofinfo">
                                                    3986854
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    DBA name:
                                                </span>
                                                <span className="descofinfo">
                                                    -
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Address:
                                                </span>
                                                <span className="descofinfo">
                                                    12733 Starfood RD STE 300
                                                    Stafford, TX 27477
                                                </span>
                                            </div>
                                        </div>
                                        <div className="infosection">
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Prefix:
                                                </span>
                                                <span className="descofinfo">
                                                    MC <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6 13.4999Z" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 13.5H6" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 4L12 7.5" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    City:
                                                </span>
                                                <span className="descofinfo">
                                                    Starfford
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Fillial:
                                                </span>
                                                <span className="descofinfo">
                                                    4622 Shipmans Cove BlVD
                                                    Fresno, TX 77545-2173
                                                </span>
                                            </div>
                                        </div>
                                        <div className="infosection">
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Docket number:
                                                </span>
                                                <span className="descofinfo">
                                                    1494472
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    State:
                                                </span>
                                                <span className="descofinfo">
                                                    Tx
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    <br></br>
                                                </span>
                                                <span className="descofinfo">
                                                    <br></br><br></br>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="infosection">
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Legal name:
                                                </span>
                                                <span className="descofinfo">
                                                    Golden eagle USA INC
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Phone
                                                </span>
                                                <span className="descofinfo">
                                                    (626) 731-8878
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    <br></br>
                                                </span>
                                                <span className="descofinfo">
                                                    <br></br><br></br>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="infosection">

                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Partners:
                                                </span>
                                                <span className="descofinfo">
                                                    Yi Yue <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6 13.4999Z" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 13.5H6" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 4L12 7.5" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                <span className="descofinfo">
                                                    Yubin Zhang <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6 13.4999Z" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 13.5H6" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 4L12 7.5" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    Cellphone:
                                                </span>
                                                <span className="descofinfo">
                                                    (626) 731-8878 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6 13.4999Z" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 13.5H6" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 4L12 7.5" stroke="#0C94E4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="target">
                                                <span className="titleofinfo">
                                                    <br></br>
                                                </span>
                                                <span className="descofinfo">
                                                    <br></br><br></br>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cardcomponent">
                                <div className="card-header">
                                    Too much information
                                </div>
                                <div className="coverofmuch">
                                    <div className="infosection">
                                        <div className="target">
                                            <span className="titleofinfo">
                                                1 Years in Business:
                                            </span>
                                            <span className="descofinfo2">
                                                Date added: Nov 16,2022 | MCS 150: Feb 22,2023
                                            </span>
                                            <span className="descofinfo2">
                                                Common: Active | Contract: None | Broker: None
                                            </span>
                                            <span className="descofinfo2">
                                                Hazmat: N | OOS: N
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">

                                            </span>

                                        </div>
                                    </div>
                                    <div className="infosection">
                                        <div className="target">
                                            <span className="titleofinfo">
                                                Vehicles:
                                            </span>
                                            <span className="descofinfo2">
                                                Truck: 1 | Owned: 1 | Leased: 0
                                            </span>
                                            <span className="descofinfo2">
                                                Buses: 0 | Owned: 0 | Leased: 0                                            </span>
                                            <span className="descofinfo2">
                                                Driver: 1 | CDL Drivers: 1                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">

                                            </span>

                                        </div>

                                    </div>

                                    <div className="infosection">
                                        <div className="target ">
                                            <span className="titleofinfo ">
                                                Current insurance company:                                            </span>
                                            <span className="descofinfo2 mt-2">
                                                WESCO Insurance company                                            </span>
                                            <span className="descofinfo2">
                                                BIPD/ Primary | Federal                                            </span>
                                            <span className="descofinfo2">
                                                Policy: WMC1989924 | BIPD req: 002023 | BDC3: X                                            </span>
                                            <span className="descofinfo2">
                                                Effective: Feb 14, 2023 | Expiration: Feb 14, 2023                                            </span>
                                            <span className="descofinfo2">
                                                Cancellation: N/A                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cardcomponent">
                                <div className="card-header">
                                    Vehicle shedule
                                </div>
                                <div className="coverofvehicle">
                                    {
                                        Vehicles.map((vehicle, index) => {
                                            return (
                                                <>
                                                    <div className="coverofperson mt-3">

                                                        <div className="target">
                                                            <span className="titleofinfo">
                                                                Make:
                                                            </span>
                                                            <span className="descofinfo">
                                                                {vehicle.Make}
                                                            </span>
                                                        </div>

                                                        <div className="target">
                                                            <span className="titleofinfo">
                                                                Model:
                                                            </span>
                                                            <span className="descofinfo">
                                                                {vehicle.Model}
                                                            </span>
                                                        </div>
                                                        <div className="target">
                                                            <span className="titleofinfo">
                                                                VIN:
                                                            </span>
                                                            <span className="descofinfo">
                                                                {vehicle.VIN}
                                                            </span>
                                                        </div>
                                                        <div className="target">
                                                            <span className="titleofinfo">
                                                                Truck type:
                                                            </span>
                                                            <span className="descofinfo">
                                                                {vehicle.Trucktype}
                                                            </span>
                                                        </div>
                                                        <div className="target">
                                                            <span className="titleofinfo">
                                                                Stated value:
                                                            </span>
                                                            <span className="descofinfo">
                                                                {vehicle.Statedvalue}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    <div className="addvehicle mt-3" onClick={() => { setShowclient(true) }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span>Add sub-portfolios</span>
                                    </div>
                                </div>
                            </div>
                            <Combodies />
                            <Lossruns />
                            <div className="cardcomponent">
                                <div className="card-header">
                                    Add 4 Q IFTA's
                                </div>
                                <div className="addvehicle addvehi" onClick={() => { setShowclient(true) }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span>Add sub-portfolios</span>
                                </div>
                            </div>
                            <AddDriver />
                            <Nav />
                        </div>
                        <br></br>
                        <br></br>
                    </>
                ) : (
                    < Deepdive />
                )
            }   
           

        </>


    )
}
export default DeepDriveDetail;