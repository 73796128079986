import { Avatar } from "antd";
import { Link } from "react-router-dom";
import "./Mailer.css"
import { useState } from "react";
const Preview = () => {
    const [likedCount, setLikedCount] = useState(0);

    return (


        <div className="">

            <div className="coverofall">
                <div className="mailnav">
                    <div className="writemailbtn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M8.03907 16.7676H4.34766C4.18449 16.7676 4.028 16.7028 3.91262 16.5874C3.79724 16.4721 3.73243 16.3156 3.73243 16.1524V12.7148C3.73215 12.6349 3.74762 12.5557 3.77798 12.4818C3.80833 12.4079 3.85296 12.3408 3.90931 12.2841L13.1378 3.05561C13.1951 2.99748 13.2633 2.95132 13.3386 2.91981C13.4138 2.8883 13.4946 2.87207 13.5762 2.87207C13.6578 2.87207 13.7385 2.8883 13.8138 2.91981C13.889 2.95132 13.9573 2.99748 14.0145 3.05561L17.4445 6.48554C17.5026 6.54279 17.5488 6.61103 17.5803 6.68628C17.6118 6.76154 17.628 6.84231 17.628 6.9239C17.628 7.00548 17.6118 7.08626 17.5803 7.16151C17.5488 7.23677 17.5026 7.30501 17.4445 7.36225L8.03907 16.7676Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.2676 16.7676H8.03906" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.1152 5.07812L15.4219 9.38477" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        To write

                    </div>
                    <div className="normalmailbtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V5.625L4.375 3.125H15.625L16.875 5.625V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875Z" stroke="#0C94E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.125 5.625H16.875" stroke="#0C94E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>  <span className="textofmail">
                            Inbox
                        </span>
                        <div className="numberofmail">
                            2
                        </div>
                    </div>
                    <div className="normalmailbtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.3434 14.8985L14.2809 17.3985C14.7887 17.7188 15.4137 17.2422 15.2653 16.6563L14.1246 12.1719C14.0938 12.0476 14.0987 11.9171 14.1388 11.7955C14.1789 11.6738 14.2525 11.566 14.3512 11.4844L17.8824 8.53908C18.3434 8.15627 18.109 7.38283 17.5074 7.34377L12.8981 7.04689C12.7723 7.03958 12.6514 6.99578 12.5501 6.92086C12.4488 6.84594 12.3716 6.74314 12.3278 6.62502L10.609 2.29689C10.5635 2.17182 10.4806 2.06377 10.3716 1.98742C10.2626 1.91107 10.1327 1.87012 9.99963 1.87012C9.86654 1.87012 9.73667 1.91107 9.62765 1.98742C9.51863 2.06377 9.43575 2.17182 9.39025 2.29689L7.6715 6.62502C7.6277 6.74314 7.55044 6.84594 7.44915 6.92086C7.34787 6.99578 7.22696 7.03958 7.10119 7.04689L2.49182 7.34377C1.89026 7.38283 1.65588 8.15627 2.11682 8.53908L5.64807 11.4844C5.74677 11.566 5.8204 11.6738 5.86048 11.7955C5.90057 11.9171 5.90547 12.0476 5.87463 12.1719L4.81994 16.3281C4.64026 17.0313 5.39026 17.6016 5.99182 17.2188L9.65588 14.8985C9.75863 14.8331 9.87787 14.7984 9.99963 14.7984C10.1214 14.7984 10.2406 14.8331 10.3434 14.8985V14.8985Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> <span className="textofmail">
                            With star
                        </span>
                        <div className="numberofmail">
                            {likedCount}
                        </div>
                    </div>
                    <div className="normalmailbtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.1489 9.45293L3.95359 2.06231C3.84245 2.0009 3.71523 1.97481 3.58888 1.98751C3.46254 2.0002 3.34306 2.05108 3.24634 2.13337C3.14963 2.21566 3.08028 2.32545 3.04752 2.44814C3.01476 2.57082 3.02015 2.70057 3.06297 2.82012L5.54734 9.78887C5.60208 9.92417 5.60208 10.0754 5.54734 10.2107L3.06297 17.1795C3.02015 17.299 3.01476 17.4288 3.04752 17.5515C3.08028 17.6742 3.14963 17.784 3.24634 17.8662C3.34306 17.9485 3.46254 17.9994 3.58888 18.0121C3.71523 18.0248 3.84245 17.9987 3.95359 17.9373L17.1489 10.5467C17.2466 10.4926 17.328 10.4134 17.3847 10.3172C17.4414 10.2211 17.4713 10.1115 17.4713 9.99981C17.4713 9.88816 17.4414 9.77856 17.3847 9.68238C17.328 9.58621 17.2466 9.50698 17.1489 9.45293V9.45293Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.625 10H10.625" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>  <span className="textofmail">
                            Send
                        </span>
                        <div className="numberofmail">
                            2
                        </div>
                    </div>
                    <div className="normalmailbtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.5 3.125H15.625C15.7908 3.125 15.9497 3.19085 16.0669 3.30806C16.1842 3.42527 16.25 3.58424 16.25 3.75V16.875C16.25 17.0408 16.1842 17.1997 16.0669 17.3169C15.9497 17.4342 15.7908 17.5 15.625 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.75C3.75 3.58424 3.81585 3.42527 3.93306 3.30806C4.05027 3.19085 4.20924 3.125 4.375 3.125H7.5" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.875 5.625V5C6.875 4.1712 7.20424 3.37634 7.79029 2.79029C8.37634 2.20424 9.1712 1.875 10 1.875C10.8288 1.875 11.6237 2.20424 12.2097 2.79029C12.7958 3.37634 13.125 4.1712 13.125 5V5.625H6.875Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>   <span className="textofmail">
                            Draft
                        </span>
                        <div className="numberofmail">
                            2
                        </div>
                    </div>
                </div>
                <div className="forall">
                    <div className="headercontain">
                        <Link to="/home/mail">
                            <span className="backtopost">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M6.25 8.125L3.125 5L6.25 1.875" stroke="#666D72" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> Back to List
                            </span>
                        </Link>
                        <div className="iconsofcarier">
                            <div className="svgcontaindownload">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M6.25 11.875L2.5 8.125L6.25 4.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="svgcontaindownload2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10.3434 14.8985L14.2809 17.3985C14.7887 17.7188 15.4137 17.2422 15.2653 16.6563L14.1246 12.1719C14.0938 12.0476 14.0987 11.9171 14.1388 11.7955C14.1789 11.6738 14.2525 11.566 14.3512 11.4844L17.8824 8.53908C18.3434 8.15627 18.109 7.38283 17.5074 7.34377L12.8981 7.04689C12.7723 7.03958 12.6514 6.99578 12.5501 6.92086C12.4488 6.84594 12.3716 6.74314 12.3278 6.62502L10.609 2.29689C10.5635 2.17182 10.4806 2.06377 10.3716 1.98742C10.2626 1.91107 10.1327 1.87012 9.99963 1.87012C9.86654 1.87012 9.73667 1.91107 9.62765 1.98742C9.51863 2.06377 9.43575 2.17182 9.39025 2.29689L7.6715 6.62502C7.6277 6.74314 7.55044 6.84594 7.44915 6.92086C7.34787 6.99578 7.22696 7.03958 7.10119 7.04689L2.49182 7.34377C1.89026 7.38283 1.65588 8.15627 2.11682 8.53908L5.64807 11.4844C5.74677 11.566 5.8204 11.6738 5.86048 11.7955C5.90057 11.9171 5.90547 12.0476 5.87463 12.1719L4.81994 16.3281C4.64026 17.0313 5.39026 17.6016 5.99182 17.2188L9.65588 14.8985C9.75863 14.8331 9.87787 14.7984 9.99963 14.7984C10.1214 14.7984 10.2406 14.8331 10.3434 14.8985V14.8985Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="svgcontaindownload2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M5 6.25V3.125H15V6.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15 11.875H5V17.1875H15V11.875Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5 13.75H2.1875V7.5C2.1875 6.8125 2.79687 6.25 3.53906 6.25H16.4609C17.2031 6.25 17.8125 6.8125 17.8125 7.5V13.75H15" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#374147" />
                                </svg>
                            </div>
                            <div className="svgcontaindownload3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M16.875 4.375H3.125" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.125 8.125V13.125" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.875 8.125V13.125" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="commentareacontain">

                        <div className="previewpageofmail">
                            <div className="titleofmail">
                                Title
                            </div>
                            <div className="avatarof">
                                <Avatar /><div className="nameoftheperson">
                                    <div className="nameofmailer">
                                        company.abc <span className="mailarea">(noreplay@nfe.io)</span>
                                    </div>
                                    <div className="descofsender">
                                        for me
                                    </div>
                                </div>
                            </div>
                            <div className="mainmail">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                                sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt
                                ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam,
                                quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
                                commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit
                                esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
                                nulla pariatur?</div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}
export default Preview;